import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ThemeService } from '@wedecide/shared/services/theme.service';

@Component({
  selector: 'wd-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent {
  public iconPath = '/assets/icons/png/languages/de.png';

  constructor(private themeService: ThemeService, private location: Location) {}

  get logo() {
    return this.themeService.svgLogo;
  }

  backClicked() {
    this.location.back();
  }
}
