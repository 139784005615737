import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipeModule } from '@wedecide/shared/pipes/pipe.module';

import { UserAvatarAndNameComponent } from './user-avatar-and-name.component';

@NgModule({
  declarations: [UserAvatarAndNameComponent],
  imports: [CommonModule, FlexLayoutModule, MatTooltipModule, PipeModule],
  exports: [UserAvatarAndNameComponent],
})
export class UserAvatarAndNameModule {}
