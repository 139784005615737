import { Pipe, PipeTransform } from '@angular/core';
/*
 * Convert newlines in the given value to <br /> tags
 * Usage:
 *  <p [innerHtml]="value | nl2br"></p>
 * Example:
 *   {{ "hello\nworld" | nl2br }}
 *   formats to: hello<br />world
 */
@Pipe({ name: 'nl2br' })
export class Nl2BrPipe implements PipeTransform {
  transform(value: string): string {
    const escaped = this.escapeHtml(value);
    return escaped.replace(/\n/g, '<br />');
  }

  private escapeHtml(unsafe: string) {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&lt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  }
}
