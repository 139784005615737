import { Notification } from '../models/notification.model';

import { formatDateTime } from './../datetime.formatting';
import { NOTIFICATION_CONFIG } from './notification.config';

const interpolate = function (str: string, ctx: Record<string, unknown>) {
  /**
   * Replace all occurences of {x} by the value given in ctx object.
   * Usage: console.log(interpolate("I'm {age} years old!", { age: 29 }));
   * via https://stackoverflow.com/a/1408373
   *
   * @param {string} str The string to be interpolated.
   * @param {Record<string, unknown>} ctx A context object containing fields that can be used for string interpolation.
   * @returns {string} The interpolated string.
   */

  return str.replace(/{([^{}]*)}/g, function (a: any, b: any) {
    const r = typeof ctx[b] === 'string' ? escapeHtml(ctx[b] as string) : ctx[b];
    return typeof r === 'string' || typeof r === 'number' ? r : a;
  });
};

function escapeHtml(unsafe: string) {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}

export class NotificationRenderer {
  /**
   * This class handles the interpolation of templates for our notifications.
   */

  /**
   * 'Welcome' part of the email.
   */
  static ohai = 'Dear {recipientName},';

  /**
   * 'Welcome' part of the email as HTML.
   */
  static ohaiHTML = `<div class='hi'>
    <p>Dear {recipientName},</p>
  </div>`;

  /**
   * 'Goodbye' part of the email plus signature.
   */
  static kbye = `Your Apollo-Team

---
smart decisions – with apollo.ai
(c) Apollo.ai GmbH | Poschacherstraße 23, 4020 Linz, Austria
If you would like to adapt the email frequency, change email preferences: {settingsLink}`;

  /**
   * 'Goodbye' part of the email plus signature as HTML.
   */
  static kbyeHTML = `<div class='bye'>
  <p>Your Apollo-Team!</p>

  <hr style='width: 10em; margin-left: 0;'/>
  <img src='{logoLink}' alt='Apollo Logo'>

  <small class='footer'>
    <p>
      smart decisions – with <a href='{wedecideLink}'>apollo.ai</a><br>
      &copy; Apollo.ai GmbH | Poschacherstraße 23, 4020 Linz, Austria<br>
      <a href='{imprintLink}'>Imprint</a> | <a href='{privacyLink}'>Privacy Policy</a>
    </p>
    <p>If you would like to adapt the email frequency, <a href='{settingsLink}'>change email preferences</a>.</p>
  </small>
</div>`;

  static staticContext = {
    wedecideLink: 'https://apollo.ai',
    logoLink: 'https://app.apollo.ai/assets/images/Apollo_180x48.png',
    imprintLink: 'https://app.apollo.ai/imprint',
    // termsLink: 'https://app.apollo.ai/terms',
    privacyLink: 'https://app.apollo.ai/privacy-policy',
  };

  /**
   * @returns {string} A context object containing fields that can be used for string interpolation.
   */
  static buildContext(notification: Notification, recipientName: string, domain: string) {
    let path = notification.urlPath;
    if (!path) {
      // default: link either to a meeting or decision
      // does the link depend on the decision status?
      const route = notification.isMeetingRoute() ? 'meetings' : 'manage';
      path = `/${route}/${notification.itemId}`;
    }
    return {
      // ...notification,
      ...NotificationRenderer.staticContext,
      recipientName,
      id: notification.itemId,
      title: notification.title,
      triggeredByName: notification.triggeredBy?.name,
      message: notification?.message || '',
      dueDate: notification?.dueDate ? formatDateTime(notification?.dueDate) : '',
      // TODO update links
      settingsLink: `${domain}/settings/notifications`,
      link: domain + path,
      board: notification.board ? `${notification.board.name} |` : '',
      mentionedUsers: notification.mentionedUsers?.join(', ') || '',
    };
  }

  static getInAppMessage(
    template: string,
    notification: Notification,
    recipientName: string,
    domain = 'https://app.apollo.ai',
  ) {
    /**
     * @returns {string} The notification message to display in our frontend.
     */
    const context = NotificationRenderer.buildContext(notification, recipientName, domain);
    return interpolate(template, context);
  }
}
