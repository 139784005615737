import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@wedecide/shared/base.component';
import { AuthService } from '@wedecide/shared/services/auth.service';
import { ErrorService } from '@wedecide/shared/services/error.service';
import { ThemeService } from '@wedecide/shared/services/theme.service';

@Component({
  selector: 'wd-auth-action',
  templateUrl: './auth-action.component.html',
  styleUrls: ['./auth-action.component.scss'],
})
export class AuthActionComponent extends BaseComponent implements OnInit, OnDestroy {
  private code: string;
  private _codeExpired = true;
  private _isLoading = true;
  private _loadingLabel: string;
  private _isPasswordReset = false;
  private _newCodeRequested = false;
  private _userId: string;
  private _email: string;
  initPassword = false;
  resetPasswordForm: FormGroup;

  resetPasswordValidation = {
    password: [
      {
        type: 'required',
        message: $localize`:auth-action|Reset password validation; password required@@auth-action_reset-password_password-required:Password is required`,
      },
      {
        type: 'minlength',
        message: $localize`:auth-action|Reset password validation; password minlength@@auth-action_reset-password_password-minlegth:Password must be at least 6 characters long`,
      },
    ],
    confirmpassword: [
      {
        type: 'required',
        message: $localize`:auth-action|Reset password validation; confirm password@@auth-action_reset-password_password-confirm:You have to confirm the password`,
      },
      {
        type: 'minlength',
        message: $localize`:auth-action|Reset password validation; password minlength@@auth-action_reset-password_password-minlegth:Password must be at least 6 characters long`,
      },
    ],
  };

  get codeExpired() {
    return this._codeExpired;
  }

  get email() {
    return this._email;
  }

  get isLoading() {
    return this._isLoading;
  }

  get loadingText() {
    return (
      this._loadingLabel ||
      $localize`:auth-action|Laoding label; default text@@auth-action_loading-label_default-text:loading`
    );
  }

  get isPasswordReset() {
    return this._isPasswordReset;
  }

  get newCodeRequested() {
    return this._newCodeRequested;
  }

  get displayPasswordsNotMatchError() {
    return (
      this.resetPasswordForm?.errors &&
      this.resetPasswordForm.get('password').touched &&
      this.resetPasswordForm.get('password').dirty &&
      this.resetPasswordForm.get('password').valid &&
      this.resetPasswordForm.get('confirmpassword').touched &&
      this.resetPasswordForm.get('confirmpassword').dirty &&
      this.resetPasswordForm.get('confirmpassword').valid
    );
  }

  get logo() {
    return this.themeService.svgLogo;
  }

  constructor(
    private themeService: ThemeService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private errorService: ErrorService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setLoading();
    this.route.queryParams.subscribe((params) => {
      this.code = params['oobCode'];
      this._userId = params['identity'] ? decodeURI(params['identity']) : undefined;
      this.initPassword = params['view'] && ['reset'].includes(params['view']) ? false : true;
      if (this.code) {
        this.authService.verifyPasswordResetCode(this.code).then((email) => {
          if (email) {
            this._email = email;
            this._codeExpired = false;
            this.resetPasswordForm = this.formBuilder.group(
              {
                password: new FormControl(
                  '',
                  Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(30)]),
                ),
                confirmpassword: new FormControl(
                  '',
                  Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(30)]),
                ),
              },
              {
                validators: this.passwordMatch.bind(this),
              },
            );
          } else {
            this._codeExpired = true;
          }
          this.resetLoading();
        });
      }
    });
  }

  passwordMatch(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirmpassword');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  confirmPasswordReset() {
    this.setLoading(
      $localize`:auth-action|Loading label; updating password@@auth-action_loading-label_updating-password:updating password`,
    );
    if (!this.resetPasswordForm.valid) {
      this.resetLoading();
      return;
    }
    this.authService
      .confirmPasswordReset(this.code, this.resetPasswordForm.value.password)
      .then((result) => {
        this._isPasswordReset = result;
        this.snackbar.open(
          $localize`:auth-action|confirm password reset snackbar message@@auth-action_snackbar_confirm-password-reset:Successfully updated your password`,
        );
        this.router.navigate(['/login']);
      })
      .catch((err) => {
        this.errorService.showServerErrorSnackbar(
          err,
          $localize`:auth-action|Error snackbar; password reset@@auth-action_error_password-reset:Could not set new password`,
        );
      })
      .finally(() => {
        this.resetLoading();
      });
  }

  async resendPasswordResetCode() {
    this.setLoading(
      $localize`:auth-action|Loading label; reset password@@auth-action_loading-label_reset-password:sending new password reset link`,
    );
    this.authService
      .sendPasswordResetLink({ userId: this._userId })
      .then(() => {
        this._newCodeRequested = true;
      })
      .catch((err) => {
        this.errorService.showServerErrorSnackbar(
          err,
          $localize`:auth-action|Error snackbar; password reset mail@@auth-action_error_password-reset-mail:Could not send password reset mail, please try again or contact your admin`,
        );
      })
      .finally(() => {
        this.resetLoading();
      });
  }

  private setLoading(label?: string) {
    this._isLoading = true;
    this._loadingLabel = label;
  }

  private resetLoading() {
    this._isLoading = false;
    this._loadingLabel = null;
  }
}
