import { GroupRole } from '../../../models/src/interfaces/user.model';
import { NotificationType } from '../interfaces/notification.model';

import { NotificationConfiguration, NOTIFICATION_TEMPLATES } from './notification.templates';

export enum NotificationGroup {
  // don't change order
  MEETING = 'meetings',
  DECISION = 'decision',
  CIRCULAR_RESOLUTION = 'circular_resolution',
  ACTION = 'action',
  CONNECTED_USERS = 'connected_users',
}

export const NotificationRole = {
  ...GroupRole,
  BASICUSER: 'basicuser',
};

interface NotificationConfig {
  description: string;
  icon: string;
  templates: NotificationConfiguration;
  group: NotificationGroup;
  roles: (string | GroupRole)[];
}

export const NOTIFICATION_CONFIG: Record<NotificationType, NotificationConfig> = {
  [NotificationType.SHARE_MEETING_AGENDA]: {
    description: `Meeting agenda is shared with you`,
    icon: 'share',
    templates: NOTIFICATION_TEMPLATES[NotificationType.SHARE_MEETING_AGENDA],
    group: NotificationGroup.MEETING,
    roles: [NotificationRole.DECIDER],
  },

  [NotificationType.SHARE_MEETING_MINUTES]: {
    description: 'Meeting minutes is shared with you',
    icon: 'share',
    templates: NOTIFICATION_TEMPLATES[NotificationType.SHARE_MEETING_MINUTES],
    group: NotificationGroup.MEETING,
    roles: [NotificationRole.DECIDER],
  },

  [NotificationType.PUBLISH_MEETING]: {
    description: `Meeting agenda is published`,
    icon: 'meeting_room',
    templates: NOTIFICATION_TEMPLATES[NotificationType.PUBLISH_MEETING],
    group: NotificationGroup.MEETING,
    roles: [NotificationRole.DECIDER],
  },

  [NotificationType.PUBLISH_MEETING_MINUTES]: {
    description: `Meeting minutes are published`,
    icon: 'meeting_room',
    templates: NOTIFICATION_TEMPLATES[NotificationType.PUBLISH_MEETING_MINUTES],
    group: NotificationGroup.MEETING,
    roles: [NotificationRole.DECIDER],
  },

  [NotificationType.PUBLISH_MEETING_FINAL]: {
    description: `Final meeting minutes are published`,
    icon: 'check',
    templates: NOTIFICATION_TEMPLATES[NotificationType.PUBLISH_MEETING_FINAL],
    group: NotificationGroup.MEETING,
    roles: [NotificationRole.DECIDER, NotificationRole.BASICUSER],
  },

  [NotificationType.GUEST_FINALIZE_MEETING]: {
    description: `Meeting minutes are shared with you`,
    icon: 'meeting_room',
    templates: NOTIFICATION_TEMPLATES[NotificationType.GUEST_FINALIZE_MEETING],
    group: NotificationGroup.MEETING,
    roles: [NotificationRole.DECIDER, NotificationRole.BASICUSER],
  },

  [NotificationType.MEETING_MINUTES_REVIEW_COMPLETE]: {
    description: `Review is completed`,
    icon: 'rate_review',
    templates: NOTIFICATION_TEMPLATES[NotificationType.MEETING_MINUTES_REVIEW_COMPLETE],
    group: NotificationGroup.MEETING,
    roles: [NotificationRole.ASSISTANT],
  },

  [NotificationType.MEETING_MINUTES_ACCEPT_REVIEW]: {
    description: 'Decider accepted the meeting minutes',
    icon: 'rate_review',
    templates: NOTIFICATION_TEMPLATES[NotificationType.MEETING_MINUTES_ACCEPT_REVIEW],
    group: NotificationGroup.MEETING,
    roles: [NotificationRole.ASSISTANT],
  },

  [NotificationType.MEETING_MINUTES_SUBMIT_REVIEW]: {
    description: 'Decider has made review comment(s)',
    icon: 'rate_review',
    templates: NOTIFICATION_TEMPLATES[NotificationType.MEETING_MINUTES_SUBMIT_REVIEW],
    group: NotificationGroup.MEETING,
    roles: [NotificationRole.ASSISTANT],
  },

  [NotificationType.MEETING_AGENDA_FEEDBACK]: {
    description: `You received new feedback on agenda`,
    icon: 'announcement',
    templates: NOTIFICATION_TEMPLATES[NotificationType.MEETING_AGENDA_FEEDBACK],
    group: NotificationGroup.MEETING,
    roles: [NotificationRole.DECIDER, NotificationRole.ASSISTANT],
  },

  [NotificationType.MEETING_REQUEST_DOCUMENTS]: {
    description: `Documents for agenda items are requested`,
    icon: 'cloud_upload',
    templates: NOTIFICATION_TEMPLATES[NotificationType.MEETING_REQUEST_DOCUMENTS],
    group: NotificationGroup.MEETING,
    roles: [NotificationRole.DECIDER, NotificationRole.ASSISTANT, NotificationRole.BASICUSER, NotificationRole.GROUP_ADMIN],
  },

  [NotificationType.MEETING_UPLOADED_REQUESTED_DOCUMENTS]: {
    description: `User has uploaded document(s)`,
    icon: 'cloud_upload',
    templates: NOTIFICATION_TEMPLATES[NotificationType.MEETING_UPLOADED_REQUESTED_DOCUMENTS],
    group: NotificationGroup.MEETING,
    roles: [NotificationRole.ASSISTANT],
  },

  [NotificationType.GUEST_PUBLISH_AGENDA]: {
    description: `You are invited as guest to a meeting`,
    icon: 'meeting_room',
    templates: NOTIFICATION_TEMPLATES[NotificationType.GUEST_PUBLISH_AGENDA],
    group: NotificationGroup.MEETING,
    roles: [NotificationRole.DECIDER, NotificationRole.BASICUSER],
  },

  // circular resolution
  [NotificationType.CR_SHARE_CIRCULAR_RESOLUTION]: {
    description: `A circular resolution is shared with you`,
    icon: 'share',
    templates: NOTIFICATION_TEMPLATES[NotificationType.CR_SHARE_CIRCULAR_RESOLUTION],
    group: NotificationGroup.CIRCULAR_RESOLUTION,
    roles: [NotificationRole.DECIDER],
  },

  [NotificationType.CR_FINALIZE_CIRCULAR_RESOLUTION]: {
    description: `Circular resolution is final`,
    icon: 'check',
    templates: NOTIFICATION_TEMPLATES[NotificationType.CR_FINALIZE_CIRCULAR_RESOLUTION],
    group: NotificationGroup.CIRCULAR_RESOLUTION,
    roles: [NotificationRole.DECIDER],
  },

  [NotificationType.CR_CANCEL_CIRCULAR_RESOLUTION]: {
    description: `Circular resolution is canceled`,
    icon: 'clear',
    templates: NOTIFICATION_TEMPLATES[NotificationType.CR_CANCEL_CIRCULAR_RESOLUTION],
    group: NotificationGroup.CIRCULAR_RESOLUTION,
    roles: [NotificationRole.DECIDER],
  },

  [NotificationType.CR_APPROVAL_RATE_REACHED]: {
    description: `Circular resolution reached approval rate`,
    icon: 'check',
    templates: NOTIFICATION_TEMPLATES[NotificationType.CR_APPROVAL_RATE_REACHED],
    group: NotificationGroup.CIRCULAR_RESOLUTION,
    roles: [NotificationRole.DECIDER, NotificationRole.ASSISTANT],
  },

  [NotificationType.CR_VOTING_FINISHED]: {
    description: `Voting for the circular resolution is finished`,
    icon: 'info',
    templates: NOTIFICATION_TEMPLATES[NotificationType.CR_VOTING_FINISHED],
    group: NotificationGroup.CIRCULAR_RESOLUTION,
    roles: [NotificationRole.ASSISTANT],
  },

  // decisions
  [NotificationType.RACI_REPORT_COMMENT]: {
    description: `Comment on a report has been made`,
    icon: 'military_tech',
    templates: NOTIFICATION_TEMPLATES[NotificationType.RACI_REPORT_COMMENT],
    group: NotificationGroup.DECISION,
    roles: [NotificationRole.DECIDER, NotificationRole.ASSISTANT, NotificationRole.BASICUSER],
  },

  [NotificationType.RACI_RESPONSIBLE_FINALIZE]: {
    description: `You are responsible for a new decision`,
    icon: 'military_tech',
    templates: NOTIFICATION_TEMPLATES[NotificationType.RACI_RESPONSIBLE_FINALIZE],
    group: NotificationGroup.DECISION,
    roles: [NotificationRole.DECIDER, NotificationRole.ASSISTANT, NotificationRole.BASICUSER],
  },

  [NotificationType.RACI_REPORT_SHARED]: {
    description: `Responsible person has made a report `,
    icon: 'military_tech',
    templates: NOTIFICATION_TEMPLATES[NotificationType.RACI_REPORT_SHARED],
    group: NotificationGroup.DECISION,
    roles: [NotificationRole.DECIDER, NotificationRole.ASSISTANT, NotificationRole.BASICUSER],
  },
  // actions
  [NotificationType.RACI_DAY_BEFORE_REMINDER]: {
    description: `Decision report is due tomorrow`,
    icon: 'military_tech',
    templates: NOTIFICATION_TEMPLATES[NotificationType.RACI_DAY_BEFORE_REMINDER],
    group: NotificationGroup.ACTION,
    roles: [NotificationRole.DECIDER, NotificationRole.BASICUSER],
  },

  [NotificationType.RACI_REPORT_OVERDUE_REMINDER]: {
    description: `Decision report is overdue`,
    icon: 'military_tech',
    templates: NOTIFICATION_TEMPLATES[NotificationType.RACI_REPORT_OVERDUE_REMINDER],
    group: NotificationGroup.ACTION,
    roles: [NotificationRole.DECIDER, NotificationRole.BASICUSER],
  },

  [NotificationType.RACI_WEEK_BEFORE_REMINDER]: {
    description: `Decision report is due in 1 week`,
    icon: 'military_tech',
    templates: NOTIFICATION_TEMPLATES[NotificationType.RACI_WEEK_BEFORE_REMINDER],
    group: NotificationGroup.ACTION,
    roles: [NotificationRole.DECIDER, NotificationRole.BASICUSER],
  },

  [NotificationType.RACI_DECISION_IMPLEMENTED]: {
    description: 'Decision implemented',
    icon: 'military_tech',
    templates: NOTIFICATION_TEMPLATES[NotificationType.RACI_DECISION_IMPLEMENTED],
    group: NotificationGroup.DECISION,
    roles: [NotificationRole.DECIDER, NotificationRole.ASSISTANT, NotificationRole.BASICUSER],
  },

  [NotificationType.MEETING_MINUTES_REVIEW_REQUEST]: {
    description: `Review of meeting minutes is needed`,
    icon: 'rate_review',
    templates: NOTIFICATION_TEMPLATES[NotificationType.MEETING_MINUTES_REVIEW_REQUEST],
    group: NotificationGroup.ACTION,
    roles: [NotificationRole.DECIDER],
  },

  [NotificationType.MEETING_MINUTES_SIGNING_REQUEST]: {
    description: `Sign meeting minutes`,
    icon: 'drive_file_rename_outline',
    templates: NOTIFICATION_TEMPLATES[NotificationType.MEETING_MINUTES_SIGNING_REQUEST],
    group: NotificationGroup.ACTION,
    roles: [NotificationRole.DECIDER],
  },

  [NotificationType.MEETING_MINUTES_SIGNING_PENDING]: {
    description: `Sign meeting minutes reminder`,
    icon: 'drive_file_rename_outline',
    templates: NOTIFICATION_TEMPLATES[NotificationType.MEETING_MINUTES_SIGNING_PENDING],
    group: NotificationGroup.ACTION,
    roles: [NotificationRole.DECIDER],
  },

  [NotificationType.MEETING_MINUTES_SIGNING_COMPLETE]: {
    description: `Sign meeting minutes complete`,
    icon: 'drive_file_rename_outline',
    templates: NOTIFICATION_TEMPLATES[NotificationType.MEETING_MINUTES_SIGNING_COMPLETE],
    group: NotificationGroup.MEETING,
    roles: [NotificationRole.ASSISTANT],
  },

  [NotificationType.CR_SHARE_CIRCULAR_RESOLUTION]: {
    description: `Share circular resolution`,
    icon: 'share',
    templates: NOTIFICATION_TEMPLATES[NotificationType.CR_SHARE_CIRCULAR_RESOLUTION],
    group: NotificationGroup.ACTION,
    roles: [NotificationRole.DECIDER],
  },

  [NotificationType.CR_PUBLISH_CIRCULAR_RESOLUTION]: {
    description: `Voting on a circular resolution is needed`,
    icon: 'rate_review',
    templates: NOTIFICATION_TEMPLATES[NotificationType.CR_PUBLISH_CIRCULAR_RESOLUTION],
    group: NotificationGroup.ACTION,
    roles: [NotificationRole.DECIDER],
  },

  [NotificationType.CR_VOTING_DUE_SOON]: {
    description: `Circular resolution voting will be due soon`,
    icon: 'info',
    templates: NOTIFICATION_TEMPLATES[NotificationType.CR_VOTING_DUE_SOON],
    group: NotificationGroup.ACTION,
    roles: [NotificationRole.DECIDER],
  },

  [NotificationType.MEETING_MINUTES_REVIEW_DUE_SOON]: {
    description: `Review is pending until tomorrow`,
    icon: 'info',
    templates: NOTIFICATION_TEMPLATES[NotificationType.MEETING_MINUTES_REVIEW_DUE_SOON],
    group: NotificationGroup.ACTION,
    roles: [NotificationRole.DECIDER],
  },

  [NotificationType.TODO_DUE_SOON]: {
    description: `Todo is pending until tomorrow`,
    icon: 'info',
    templates: NOTIFICATION_TEMPLATES[NotificationType.TODO_DUE_SOON],
    group: NotificationGroup.ACTION,
    roles: [NotificationRole.DECIDER],
  },

  [NotificationType.CONNECT_USER]: {
    description: 'Accounts are connected',
    icon: 'group_add',
    templates: NOTIFICATION_TEMPLATES[NotificationType.CONNECT_USER],
    group: NotificationGroup.CONNECTED_USERS,
    roles: [NotificationRole.DECIDER, NotificationRole.ASSISTANT, NotificationRole.BASICUSER],
  },

  [NotificationType.ADMIN_CONNECTED_USERS]: {
    description: 'Admin connected accounts',
    icon: 'group_add',
    templates: NOTIFICATION_TEMPLATES[NotificationType.ADMIN_CONNECTED_USERS],
    group: NotificationGroup.CONNECTED_USERS,
    roles: [NotificationRole.DECIDER, NotificationRole.BASICUSER],
  },
};
