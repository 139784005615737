import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StartReviewDialogComponent } from 'src/app/meeting-manager/overlay/start-review-dialog/start-review-dialog.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'wd-app-error-overlay',
  templateUrl: './app-error-overlay.component.html',
  styleUrls: ['./app-error-overlay.component.scss'],
})
export class AppErrorOverlayComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<StartReviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { error: Error },
  ) {}

  ngOnInit(): void {}

  get shortenedErrorMessage() {
    return (this.data.error.message || '').slice(0, 300);
  }

  get shortenedStack() {
    return (this.data.error.stack || '').slice(0, 300);
  }

  get isProduction() {
    return environment.production;
  }

  reload() {
    window.location.reload();
  }

  continue() {
    this.dialogRef.close();
  }
}
