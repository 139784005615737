import { Component, OnInit } from '@angular/core';

import { ConnectionService } from '../../services/connection.service';

@Component({
  selector: 'wd-network-state',
  templateUrl: './network-state.component.html',
  styleUrls: ['./network-state.component.scss'],
})
export class NetworkStateComponent implements OnInit {
  isOnLine = true;

  constructor(private connectionService: ConnectionService) {}

  ngOnInit(): void {
    this.connectionService.online$.subscribe((online) => {
      this.isOnLine = online;
    });
  }
}
