import { Component } from '@angular/core';

@Component({
  selector: 'wd-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./../error-handling-base.component.scss', './not-found.component.scss'],
})
export class NotFoundComponent {
  constructor() {}
}
