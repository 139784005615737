<mat-nav-list class="nav-list">
  <div class="sidenav-header" fxLayout="row" fxLayoutAlign="start">
    <div fxLayoutAlign="center" class="profile-picture" [ngStyle]="{'background-image': 'url(' + userProfileImage + ')'}"></div>
    <div fxLayout="column" fxFlex fxLayoutAlign="start">
      <div class="profile-name">{{ user?.displayName }}</div>
      <div class="active-organization">apollo.ai GmbH</div>
    </div>
    <div>
      <button mat-icon-button (click)="close()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <a mat-list-item routerLink="/dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" (click)="close()">
    <div fxLayout="row" fxFill>
      <div fxFlex="40px" fxLayoutAlign="start center">
        <mat-icon matListIcon>home</mat-icon>
      </div>
      <div class="nav-label" fxFill fxLayoutAlign="start center" i18n="general|dashboard@@general_dashboard">Dashboard</div>
    </div>
  </a>

  <a *ngIf="isNotBasicUser" mat-list-item routerLink="/meetings" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" (click)="close()">
    <div fxLayout="row" fxFill>
      <div fxFlex="40px" fxLayoutAlign="start center">
        <mat-icon matListIcon>meeting_room</mat-icon>
      </div>
      <div class="nav-label" fxFill fxLayoutAlign="start center" i18n="general|meetings@@general_meetings">Meetings</div>
    </div>
  </a>

  <a *ngIf="isNotBasicUser" mat-list-item routerLink="/cr" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" (click)="close()">
    <div fxLayout="row" fxFill>
      <div fxFlex="40px" fxLayoutAlign="start center">
        <mat-icon matListIcon>question_answer</mat-icon>
      </div>
      <div class="nav-label" fxFill fxLayoutAlign="start center" i18n="general|circular resolutions@@general_circular-resolutions">Circular Resolutions</div>
    </div>
  </a>

  <a mat-list-item routerLink="/decisions" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" (click)="close()">
    <div fxLayout="row" fxFill>
      <div fxFlex="40px" fxLayoutAlign="start center">
        <mat-icon matListIcon>approval</mat-icon>
      </div>
      <div class="nav-label" fxFill fxLayoutAlign="start center" i18n="general|Decisions@@general_decisions">Decisions</div>
    </div>
  </a>

  <a mat-list-item routerLink="/todos" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" (click)="close()">
    <div fxLayout="row" fxFill>
      <div fxFlex="40px" fxLayoutAlign="start center">
        <mat-icon matListIcon>check_box</mat-icon>
      </div>
      <div class="nav-label" fxFill fxLayoutAlign="start center" i18n="general|todos@@general_todos">Todos</div>
    </div>
  </a>

  <a *ngIf="isNotBasicUser" mat-list-item routerLink="/boards" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" (click)="close()">
    <div fxLayout="row" fxFill>
      <div fxFlex="40px" fxLayoutAlign="start center">
        <mat-icon matListIcon>people</mat-icon>
      </div>
      <div class="nav-label" fxFill fxLayoutAlign="start center" i18n="general|boards@@general_boards">Boards</div>
    </div>
  </a>

  <a mat-list-item routerLink="/search" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" (click)="close()">
    <div fxLayout="row" fxFill>
      <div fxFlex="40px" fxLayoutAlign="start center">
        <mat-icon matListIcon>search</mat-icon>
      </div>
      <div class="nav-label" fxFill fxLayoutAlign="start center" i18n="general|search@@general_search">Search</div>
    </div>
  </a>

  <a mat-list-item href="https://help.apollo.ai" target="_blank" rel="noopener noreferrer">
    <div fxLayout="row" fxFill>
      <div fxFlex="40px" fxLayoutAlign="start center">
        <mat-icon matListIcon>help_outlined</mat-icon>
      </div>
      <div class="nav-label" fxFill fxLayoutAlign="start center" i18n="general|support centre@@general_support-centre">Support Centre</div>
    </div>
  </a>

  <mat-divider></mat-divider>
  <a mat-list-item routerLink="/settings" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="close()">
    <div fxLayout="row" fxFill>
      <div fxFlex="40px" fxLayoutAlign="start center">
        <mat-icon matListIcon>settings</mat-icon>
      </div>
      <div class="nav-label" fxFill fxLayoutAlign="start center" i18n="general|settings@@general_settings">Settings</div>
    </div>
  </a>
  <a mat-list-item (click)="logout()">
    <div fxLayout="row" fxFill>
      <div fxFlex="40px" fxLayoutAlign="start center">
        <mat-icon matListIcon>power_settings_new</mat-icon>
      </div>
      <div class="nav-label" fxFill fxLayoutAlign="start center" i18n="general|logout@@general_logout">Logout</div>
    </div>
  </a>
</mat-nav-list>
