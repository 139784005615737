<div class="relative" fxLayout="column" fxLayoutAlign="space-between center" fxFill>
  <div class="error-content" fxFill>
    <router-outlet></router-outlet>
  </div>
  <div fxFill>
    <div class="error-footer" fxLayout="row">
      <div fxFlex="66" fxFlexOffset="15" fxFlexOffset.lt-sm="0" fxFlex.lt-sm="100">
        <div class="light-text" i18n="error_handling|Info; contact support@@error_handling_info-contact-support">If you have any problems or further questions feel free to contact <a href="mailto:support@apollo.ai">our support</a>.</div>
        <div class="m-t-20">
          <img [src]="logo" height="30" />
        </div>
      </div>
    </div>
  </div>
</div>

