import { UnitModel } from './unit.model';
import { GroupModelEssentials } from './group.model';
import { UserReference } from './user.model';
export interface RACIDataType<DateType = Date> {
  responsible?: RACIResponsible;
  reportOnCompletion?: UserReference[];
  reportAfterTimeFrame?: ReportTimeframeModel;
  lastReportDate?: DateType;
  nextReportDueDate?: DateType;
  reportsActive?: boolean;
  decisionImplemented?: boolean;
}

export type RACIResponsible = {
  user: UserReference;
  shareMinutesAndDocuments: boolean;
  notes?: string;
};

export const ReportTimeframeType = {
  recurring: 'recurring',
  onetime: 'onetime',
} as const;

export type ReportTimeframeTypes = keyof typeof ReportTimeframeType;

export interface ReportTimeframeModel {
  readonly type: ReportTimeframeTypes;
  users: UserReference[];
}

export interface ReportOnceModel<DateType = Date> extends ReportTimeframeModel {
  date: DateType;
}

export interface ReportRecurringModel extends ReportTimeframeModel {
  unit: 'weeks' | 'months';
  interval: number;
}

export interface WithType {
  type: 'group' | 'user';
  unit?: UnitModel;
}

export type UserOrGroupReference = (GroupModelEssentials & WithType) | (UserReference & WithType);