import { Injectable, OnDestroy } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LayoutService implements OnDestroy {
  private _destroy$ = new Subject<boolean>();
  private _activeMediaQuery: string;
  mediaQueryChanged$: Subject<string> = new Subject<string>();

  get lt_sm() {
    return this.media.isActive('lt-sm');
  }

  get lt_md() {
    return this.media.isActive('lt-md');
  }

  get lt_lg() {
    return this.media.isActive('lt-lg');
  }

  isActive(value: string | string[]) {
    return this.media.isActive(value);
  }

  constructor(private media: MediaObserver) {
    this.media
      .asObservable()
      .pipe(takeUntil(this._destroy$))
      .subscribe((change) => {
        const activeMediaQuery = [];
        change.forEach((item) => {
          activeMediaQuery.push(item.mqAlias);
        });
        if (activeMediaQuery.join(',') !== this._activeMediaQuery) {
          this._activeMediaQuery = activeMediaQuery.join(',');
          this.mediaQueryChanged$.next(this._activeMediaQuery);
        }
      });
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }
}
