<div fxLayout="row">
    <div class="p-20" fxFlex="66" fxFlexOffset="15" fxFlexOffset.lt-sm="0" fxFlex.lt-sm="100">
      <div class="error-container">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="error-code m-b-20">
          <mat-icon class="wd-red">desktop_access_disabled</mat-icon>
          <div class="wd-red">403</div>
        </div>
         <h1 i18n="error_handling|Error; permisson denied@@error_handling_error_permission_denied">Permission denied</h1>
        <div class="error_description">
          <div  i18n="error_handling|Message; permission denied error message@error_handling_message_permission-denied">
            Sorry, but your account does not have the required permissions to access this content. </div><br>
          <small class="light-text" i18n="error_handling|Message; permission denied subtext@@error_handling_message_permission_denied_subtext">
            If you think this is incorrect, please contact your board manager or an administrative person responsible for Apollo.
          </small>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
  </div>
