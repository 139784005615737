import { Pipe, PipeTransform } from '@angular/core';
import { UserModelEssentials } from '@wedecide/models';
import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'nameWithUnit',
})
export class NameWithUnitPipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(user: UserModelEssentials, noUnit = false, noYouMarker = false): string {
    if (this.authService.user.uid == user.id && !noYouMarker) {
      return user.name + ' ' + $localize`:general|own user marker@@general_own-user-marker:(you)`;
    } else if (user.unit?.abbreviation && !noUnit) {
      return user?.name + ` (${user.unit.abbreviation})`;
    } else {
      return user.name || '';
    }
  }
}
