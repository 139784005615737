import { Component, OnInit } from '@angular/core';
import { TrackingService } from '@wedecide/shared/services/tracking.service';

@Component({
  selector: 'wd-privacy-settings',
  templateUrl: './privacy-settings.component.html',
  styleUrls: ['../../settings/settings.component.scss', './privacy-settings.component.scss'],
})
export class PrivacySettings implements OnInit {
  get allCookiesAccepted() {
    return this.trackingService.allCookiesAccepted;
  }

  get performanceCookiesAccepted() {
    return this.trackingService.performanceCookiesAccepted;
  }

  get marketingCookiesAccepted() {
    return this.trackingService.marketingCookiesAccepted;
  }

  constructor(private trackingService: TrackingService) {}

  ngOnInit(): void {}

  updateAllCookiesAccepted() {
    const newValue = !this.allCookiesAccepted || false;
    this.trackingService.setAnalyticsCollectionEnabled(newValue);
    this.trackingService.setHubspotTracking(newValue);
  }

  updatePerformanceCookiesAccepted() {
    const newValue = !this.performanceCookiesAccepted || false;
    this.trackingService.setAnalyticsCollectionEnabled(newValue);
  }
  updateMarketingCookiesAccepted() {
    const newValue = !this.marketingCookiesAccepted || false;
    this.trackingService.setHubspotTracking(newValue);
  }
}
