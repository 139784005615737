import { ReportOnce, RACIData } from './../models/raci.model';
import { ReportTimeframeType, RACIDataType, UserOrGroupReference } from './../interfaces/raci.model';
import {
  AlgoliaCircularResolutionModel,
  AlgoliaDecisionType,
  AlgoliaMeetingDecisionModel,
} from './../interfaces/algoliaDecision.model';
import { FirestoreCircularResolutionModel } from './../interfaces/circular-resolution.model';
import { omit, pick, uniq } from 'lodash';

import {
  AlgoliaAgendaItemModel,
  AlgoliaTodoModel,
  TodoType,
  AgendaTodoItem,
  FirestoreAgendaItemModel,
} from '../interfaces/agendaItem.model';
import { AlgoliaACL, FirestoreTimestamp } from '../interfaces/base.model';
import { FirestoreMeetingModel, MeetingMinutesState, MeetingAgendaState } from '../interfaces/meeting.model';
import { UserModelEssentials, UserReference } from '../interfaces/user.model';
import { AgendaItem } from '../models/agendaItem.model';
import { Meeting } from '../models/meeting.model';

import {
  FirestoreMeetingDecisionModel,
} from './../interfaces/meetingDecision.model';

/**
 * getTime() returns milliseconds but algolia expects seconds
 * @param date
 */
export function convertToUnixTime(date?: Date | FirestoreTimestamp): number {
  // console.log('convertToUnixTime: ', date);
  if (date instanceof Date) {
    return date ? ~~(date.getTime() / 1000) : null;
  } else if (date?.toDate()) {
    return date ? ~~(date?.toDate()?.getTime() / 1000) : null;
  }
  return null;
}

/**
 * new Date() expects milliseconds but algolia operates with unix timestamps (seconds)
 * @param unixTime
 */
export function convertFromUnixTime(unixTime?: number) {
  return unixTime ? new Date(unixTime * 1000) : undefined;
}

export function convertAgendaItemTodoToAlgolia(todos: AgendaTodoItem<Date | FirestoreTimestamp>[]) {
  return todos.map((todo) => {
    const res: any = pick(todo, ['id', 'done', 'description', 'assignedTo', 'decisions']);

    if (todo?.dueDate) {
      res.dueDate = convertToUnixTime(todo.dueDate);
    }

    return res;
  });
}

export function convertAgendaItemTodoFromAlgolia(todos: AgendaTodoItem<number>[]) {
  return todos.map((todo) => {
    const res: any = pick(todo, [
      'id',
      'done',
      'description',
      'assignedTo',
      'decisions',
      'dueDate',
      'hasDueDate',
      'meetingId',
      'meetingTitle',
      'agendaItem',
      'group',
    ]);
    if (todo?.dueDate) {
      res.dueDate = convertFromUnixTime(todo.dueDate);
    }

    return res;
  });
}

function generateACLForAlgoliaAgendaItem(meetingData: FirestoreMeetingModel, agendaItemData: FirestoreAgendaItemModel) {
  let read = meetingData.acl.read;
  const write = meetingData.acl.write;
  const guests = (agendaItemData?.guests || []).filter((guest) => {
    if (typeof guest !== 'string' && (guest as UserModelEssentials)?.id) {
      return guest;
    }
    return false;
  });

  if (guests) {
    read = uniq([...read, ...guests.map((g) => (g as UserModelEssentials)?.id)]);
  }

  if (agendaItemData?.sharedWith && meetingData.minutesState === MeetingMinutesState.FINAL) {
    read = uniq([...read, ...(agendaItemData.sharedWith.map((u) => u.id) || [])]);
  }

  const groupRead = [meetingData.group.id];

  if (agendaItemData.accessFromDecision) {
    agendaItemData.accessFromDecision.forEach((access) => {
      if (access.type == 'user' && !read.includes(access.id)) {
        read.push(access.id);
      } else if (access.type == 'group' && !groupRead.includes(access.id)) {
        groupRead.push(access.id);
      }
    });
  }

  return {
    read,
    write,
    groupRead,
  };
}

export function agendaItemToAlgoliaData(
  data: FirestoreAgendaItemModel,
  meetingData: FirestoreMeetingModel,
  agendaStart: Date = null,
  agendaEnd: Date = null,
  displayedIndexString: string = null,
): AlgoliaAgendaItemModel {
  const res: AlgoliaAgendaItemModel = {
    ...omit(data, ['attachments', 'minutes', 'requestedDocumentsFrom']),
    meetingId: meetingData.id,
    meetingTitle: meetingData.title,
    group: meetingData.group,
    meetingIsDraft: meetingData.agendaState === MeetingAgendaState.IS_DRAFT || false,
    meetingIsFinal: meetingData?.minutesState === MeetingMinutesState.FINAL || false,
    acl: generateACLForAlgoliaAgendaItem(meetingData, data), // meetingData.acl,
    createdAt: convertToUnixTime(data?.createdAt),
    updatedAt: convertToUnixTime(data?.updatedAt),
    meetingStartDate: convertToUnixTime(meetingData?.startDate),
    duration: data.duration / 1000 / 60, // convert from ms to minutes
    todos: [] as AgendaTodoItem<number>[],
    displayedIndex: displayedIndexString ? displayedIndexString : '',
    minutes: '',
    agendaState: meetingData.agendaState,
    minutesState: meetingData?.minutesState,
    start: agendaStart ? convertToUnixTime(agendaStart) : null,
    end: agendaEnd ? convertToUnixTime(agendaEnd) : null,
    attachments: data?.attachments
      ? data.attachments.map((attachment) => {
          return {
            ...attachment,
            createdAt: convertToUnixTime(attachment?.createdAt),
          };
        })
      : [],
    organizationId: data.organizationId || meetingData.organization,
  };

  if (data?.requestedDocumentsFrom) {
    res.requestedDocumentsFrom = data.requestedDocumentsFrom;
  }

  // minutes and todos are only synced if minutes are available
  if (![MeetingMinutesState.NOT_STARTED, MeetingMinutesState.IS_DRAFT].includes(meetingData?.minutesState)) {
    res.todos = data?.todos ? convertAgendaItemTodoToAlgolia(data.todos) : [];
    // limit size for algolia
    res.minutes = data?.minutes ? data.minutes.slice(0, 8000) : '';
  }

  return res;
}

export function todoToAlgoliaData(
  data: AgendaTodoItem<FirestoreTimestamp | Date>,
  meetingData: FirestoreMeetingModel,
  agendaItem: FirestoreAgendaItemModel,
): AlgoliaTodoModel {
  const acl =
    meetingData?.acl?.read && agendaItem
      ? generateACLForAlgoliaAgendaItem(meetingData, agendaItem)
      : ({ read: [], write: [], groupRead: [] } as AlgoliaACL);
  return {
    id: data.id,
    type: meetingData ? TodoType.MEETING : TodoType.GLOBAL,
    done: data.done,
    description: data.description,
    assignedTo: data.assignedTo,
    dueDate: data?.dueDate ? convertToUnixTime(data.dueDate) : null,
    hasDueDate: !!data.dueDate,
    meetingId: meetingData.id,
    meetingTitle: meetingData.title,
    agendaItem: {
      id: agendaItem.id,
      title: agendaItem.title,
    },
    acl: acl, // TODOS have same acl as agendaitem
    group: meetingData.group,
    organizationId: meetingData.organization,
    deleted: data.deleted,
  };
}

export function agendaItemFromAlgoliaData(data: any) {
  return {
    data: data,
    agendaItem: new AgendaItem({
      ...data,
      createdAt: convertFromUnixTime(data.createdAt),
      updatedAt: convertFromUnixTime(data.updatedAt),
      meetingStartDate: convertFromUnixTime(data.startDate),
      duration: data.duration * 1000 * 60, // convert from minutes back to ms
      todos: data?.todos ? convertAgendaItemTodoFromAlgolia(data.todos) : [],
    }),
  };
}

function getUserIds(userOrGroup: UserOrGroupReference[]) {
  return userOrGroup ? userOrGroup.filter((uog) => uog.type == 'user').map((u) => u.id) : [];
}

function getGroupIds(userOrGroup: UserOrGroupReference[]) {
  return userOrGroup ? userOrGroup.filter((uog) => uog.type == 'group').map((g) => g.id) : [];
}

function getIds(userReferences: UserReference[]) {
  return userReferences ? userReferences.map((g) => g?.id) : [];
}

function generateACLForAlgoliaCircularResolution(crData: FirestoreCircularResolutionModel) {
  const acl = crData.acl as AlgoliaACL;
  acl.groupRead = [crData.group.id];
  if (crData?.viewers && Object.keys(crData?.viewers || []).length < 0) {
    acl.read = uniq([...acl.read, ...Object.keys(crData?.viewers)]);
  }
  acl.read = uniq([...acl.read, ...Object.keys(crData?.participants)]);
  return acl;
}

function generateACLForAlgoliaMeetingDecision(
  meetingData: FirestoreMeetingModel,
  agendaItemData: FirestoreAgendaItemModel,
  raci: RACIDataType = new RACIData(),
): AlgoliaACL {
  const acl = (agendaItemData.acl ? agendaItemData.acl : meetingData.acl) as AlgoliaACL;
  acl.groupRead = [meetingData.group.id];

  if (agendaItemData?.guests) {
    const guests = (agendaItemData?.guests || []).filter((guest) => {
      if (typeof guest !== 'string' && (guest as UserModelEssentials)?.id) {
        return guest;
      }
      return false;
    });

    if (guests) {
      acl.read = uniq([...acl.read, ...getIds(guests as UserModelEssentials[])]);
    }
  }
  // only add raci acl when meeting is final
  if (meetingData?.minutesState === MeetingMinutesState.FINAL) {
    if (raci?.responsible) {
      acl.read = uniq([...acl.read, raci?.responsible?.user?.id]);
    }
    if (raci?.reportOnCompletion) {
      acl.read = uniq([...acl.read, ...getIds(raci.reportOnCompletion)]);
    }
    if (raci?.reportAfterTimeFrame?.users) {
      acl.read = uniq([...acl.read, ...getIds(raci.reportAfterTimeFrame.users)]);
    }
  }

  return acl;
}

export function decisionRACIToAlgoliaData(data: FirestoreMeetingDecisionModel | FirestoreCircularResolutionModel) {
  const raci = data.raci;
  if (raci?.reportAfterTimeFrame?.type == ReportTimeframeType.onetime) {
    const reportOnce = raci.reportAfterTimeFrame as ReportOnce;
    reportOnce.date = convertToUnixTime(reportOnce.date) as any;
  }

  if (raci?.lastReportDate) {
    raci.lastReportDate = convertToUnixTime(raci.lastReportDate) as any;
  }

  if (raci?.nextReportDueDate) {
    raci.nextReportDueDate = convertToUnixTime(raci.nextReportDueDate) as any;
  }

  return raci;
}

export function circularResolutionToAlgoliaData(
  data: FirestoreCircularResolutionModel,
): AlgoliaCircularResolutionModel {
  const participants = {};
  Object.values(data.participants).map((participant) => {
    participants[participant.id] = {
      ...omit(participant, []),
      votedAt: participant?.votedAt ? convertToUnixTime(participant?.votedAt) : null,
    };
  });
  const raci = decisionRACIToAlgoliaData(data);
  return {
    ...pick(data, ['id', 'title']),
    type: AlgoliaDecisionType.CIRCULAR_RESOLUTION,
    group: data.group,
    acl: generateACLForAlgoliaCircularResolution(data),
    decidedAt: convertToUnixTime(data.decidedAt),
    proposal: data.proposal,
    organization: data.organization,
    state: data.state,
    createdBy: data.createdBy,
    approvalRate: data.approvalRate,
    participants,
    viewers: data.viewers,
    createdAt: convertToUnixTime(data.createdAt),
    updatedAt: convertToUnixTime(data.updatedAt),
    raci,
  };
}

export function meetingDecisionToAlgoliaData(
  data: FirestoreMeetingDecisionModel,
  meetingData: FirestoreMeetingModel,
  agendaItemData: FirestoreAgendaItemModel,
): AlgoliaMeetingDecisionModel {
  const raci = decisionRACIToAlgoliaData(data);

  return {
    ...pick(data, ['id', 'meetingId', 'agendaItemId', 'title', 'decisionText', 'contextText', 'decisionVoting']),
    type: AlgoliaDecisionType.MEETING_DECISION,
    group: meetingData.group,
    acl: generateACLForAlgoliaMeetingDecision(meetingData, agendaItemData, data?.raci),
    agendaItemTitle: agendaItemData.title,
    meetingTitle: meetingData.title,
    meetingIsFinal: meetingData?.minutesState === MeetingMinutesState.FINAL,
    createdAt: convertToUnixTime(data.createdAt),
    updatedAt: convertToUnixTime(data.updatedAt),
    meetingStartDate: convertToUnixTime(meetingData.startDate),
    raci,
    decidedAt: convertToUnixTime(data?.decidedAt || meetingData.startDate),
    owner: data.owner,
    members: data.members,
    organization: data.organization,
    raciReportToIds: [
      ...(data?.raci?.reportAfterTimeFrame?.users || []),
      ...(data?.raci?.reportOnCompletion || []),
    ].map((uog) => uog.id),
  };
}

export function meetingDecisionFromAlgoliaData(data: any) {
  const raci = data.raci;
  if (raci?.reportAfterTimeFrame?.date) {
    raci.reportAfterTimeFrame.date = convertFromUnixTime(raci.reportAfterTimeFrame.date);
  }
  if (raci?.lastReportDate) {
    raci.lastReportDate = convertFromUnixTime(raci.lastReportDate);
  }
  if (raci?.nextReportDueDate) {
    raci.nextReportDueDate = convertFromUnixTime(raci.nextReportDueDate);
  }
  return {
    ...data,
    createdAt: convertFromUnixTime(data.createdAt),
    updatedAt: convertFromUnixTime(data.updatedAt),
    meetingStartDate: convertFromUnixTime(data.meetingStartDate),
    decidedAt: convertFromUnixTime(data.decidedAt),
    raci: raci ? RACIData.fromFirestoreData(raci) : null,
    reportData: data?.reportData
      ? {
          numberOfReports: data.reportData?.numberOfReports || null,
          lastReport: data?.reportData?.lastReport
            ? {
                ...data.reportData?.lastReport,
                createdAt: convertFromUnixTime(data.reportData.lastReport.createdAt),
                updatedAt: convertFromUnixTime(data.reportData.lastReport.updatedAt),
              }
            : null,
        }
      : null,
  };
}

export function circularResolutionFromAlgoliaData(data: any) {
  const participants = {};
  Object.values(data?.participants || []).map((participant: any) => {
    participants[participant.id] = {
      ...omit(participant, ['votedAt']),
      votedAt: participant?.votedAt ? convertFromUnixTime(participant.votedAt) : null,
    };
  });
  return {
    ...data,
    createdAt: convertFromUnixTime(data.createdAt),
    updatedAt: convertFromUnixTime(data.updatedAt),
    decidedAt: convertFromUnixTime(data.decidedAt),
    participants,
  };
}
