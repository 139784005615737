import { pickBy, isUndefined, merge, omit } from 'lodash';
import {
  ActivityLogEntryModel,
  ActivityLogEntryType,
  FirestoreActivityLogEntryModel,
} from '../interfaces/activityLogEntry.model';
import { UserReference } from '../interfaces/user.model';
import { Base } from './base.model';

export class ActivityLogEntry
  extends Base<ActivityLogEntryModel, FirestoreActivityLogEntryModel>
  implements ActivityLogEntryModel
{
  id: string;
  type: ActivityLogEntryType;
  date: Date;
  triggeredBy: UserReference;
  urlPath?: string;
  agendaItem?: string;

  constructor(data: Partial<ActivityLogEntryModel>) {
    super();
    merge(this, data);
  }

  validate() {
    return true;
  }

  static fromFirestoreData(data: Partial<FirestoreActivityLogEntryModel>) {
    return new ActivityLogEntry({
      ...omit(data, ['createdAt', 'updatedAt']),
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
    });
  }

  toFirestore() {
    return pickBy(
      {
        ...this,
        ...this.encodePrivateProperties(),
      },
      (v) => !isUndefined(v),
    ) as FirestoreActivityLogEntryModel;
  }

  private encodePrivateProperties() {
    return Object.assign(
      {},
      ...Object.keys(this)
        .filter((p) => p.startsWith('_'))
        .map((p) => ({
          // unset private property from export
          [p]: undefined,

          [p.substr(1)]: this[p],
        })),
    );
  }
}
