<mat-card class="settings-page">
  <div class="container">
    <div fxLayout="row" fxLayoutAlign="space-between stretch">
      <h1 i18n="privacy_settings|Headline; privacy settings@@privacy_settings_headline_privacy-settings">Privacy Settings</h1>
    </div>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="start end" fxLayout.lt-sm="column" fxLayoutGap="10px">
        <div fxFlex>
          <div><b i18n="privacy_settings|All cookies@@privacy_settings_all-cookies">Accept All Cookies:</b></div>
          <ng-container i18n="privacy_settings|Description; all cookies@@privacy_settings_description_all-cookies">
            We use technologies, such as cookies, to measure the performance content and derive insights about the audiences who saw content. Toggle the switch to consent or opt-out to the use of this technology and the processing of your personal data for these purposes. If you want to know more about which cookies we set and what they do, you can go to the "Cookies" section of our</ng-container>
          &nbsp;<a href="/privacy-policy" i18n="general|Link; privacy policy@@general_link_privacy-policy">privacy policy</a>.
        </div>
        <div fxFlex="80px" class="text-right">
          <div><small class="light-text">
            <ng-container *ngIf="allCookiesAccepted" i18n="general|Enabled@@general_enabled">Enabled</ng-container>
            <ng-container *ngIf="!allCookiesAccepted" i18n="general|Disabled@@general_disabled">Disabled</ng-container>
          </small></div>
          <mat-slide-toggle [checked]="allCookiesAccepted" (change)="updateAllCookiesAccepted()"></mat-slide-toggle>
        </div>
      </div>
    </div>
    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start end" fxLayoutGap="10px">
        <div fxFlex>
          <div class="cookie-title"><b i18n="privacy_settings|Technically required cookies@@privacy_settings_technically-required-cookies">Technically Required Cookies:</b></div>
          <ng-container i18n="privacy_settings|Description; technically required cookies@@privacy_settings_description_technically-required-cookies">
            Those cookies are technically required for the application in order to run. They are just values stored in your browser like if you acceppted the Privacy Policy or values to be able to stay logged in when you close your browser.
          </ng-container>
        </div>
        <div fxFlex="80px" class="text-right">
          <div><small class="light-text" i18n="general|Enabled@@general_enabled">Enabled</small></div>
          <mat-slide-toggle [checked]="true" disabled></mat-slide-toggle>
        </div>
      </div>
    </div>

    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start end" fxLayoutGap="10px">
        <div fxFlex>
          <div class="cookie-title"><b i18n="privacy_settings|Performance cookies@@privacy_settings_performance-cookies">Performace Cookies:</b></div>
          <ng-container i18n="privacy_settings|Description; performance cookies@@privacy_settings_description_performance-cookies">
            Those cookies collect usage data about the application. Like when your application crashes or which pages are visited most frequently. This information is evaluated anonymously to improve the application itself.
          </ng-container>
        </div>
        <div fxFlex="80px" class="text-right">
          <div><small class="light-text">
            <ng-container *ngIf="performanceCookiesAccepted" i18n="general|Enabled@@general_enabled">Enabled</ng-container>
            <ng-container *ngIf="!performanceCookiesAccepted" i18n="general|Disabled@@general_disabled">Disabled</ng-container>
          </small></div>
          <mat-slide-toggle [checked]="performanceCookiesAccepted" (change)="updatePerformanceCookiesAccepted()"></mat-slide-toggle>
        </div>
      </div>
    </div>

    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start end" fxLayoutGap="10px">
        <div fxFlex>
          <div class="cookie-title"><b i18n="privacy_settings|Marketing cookies and technologies@@privacy_settings_marketing-cookies-and-technologies">Marketing Cookies & Technologies:</b></div>
          <ng-container i18n="privacy_settings|Description; marketing cookies and technologies@@privacy_settings_description_marketing-cookies-and-technologies">
            Those cookies collect data about your behaviour in order to provide the best user experience especially for you as a single person. With the knowledge collected, we can focus on what you are mostly interested in and create recommendations based on your preferences. We can connect this information with information we collected from outside the scope of this application.
          </ng-container>
        </div>
        <div fxFlex="80px" class="text-right">
          <div><small class="light-text">
            <ng-container *ngIf="marketingCookiesAccepted" i18n="general|Enabled@@general_enabled">Enabled</ng-container>
            <ng-container *ngIf="!marketingCookiesAccepted" i18n="general|Disabled@@general_disabled">Disabled</ng-container>
          </small></div>

          <mat-slide-toggle [checked]="marketingCookiesAccepted" (change)="updateMarketingCookiesAccepted()"></mat-slide-toggle>
        </div>
      </div>
    </div>


    <div class="m-t-15">
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="10px">
          <div fxFlex>
            <div><b i18n="general|Headline; privacy policy@@general_headline_privacy-policy">Privacy Policy:</b></div>
            <ng-container i18n="privacy_settings|Decription; privacy policy@@privacy_settings_description_privacy-policy">
              We and our partners value your privacy. You can read more about how we comply to the EU General Data Protection Regulation in our
            </ng-container>
            <a href="/privacy-policy" i18n="general|Link; privacy policyn@@general_link_privacy-policy">privacy policy</a>.
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
