<mat-drawer-container class="example-container" [hasBackdrop]="true" class="mat-typography app-frame mat-app-background">
  <mat-drawer #drawer [mode]="'over'">
    <wd-sidenav *ngIf="user" [userProfileImage]="userProfileImage" [hasManageAccess]="hasManageAccess" [user]="user"  [isAdmin]="isAdmin" (logoutEvent)="logout()" (toggleSidenav)="drawer.toggle()"></wd-sidenav>
  </mat-drawer>
  <mat-drawer-content>
    <wd-navbar *ngIf="user" [userProfileImage]="userProfileImage" [hasManageAccess]="hasManageAccess" [user]="user" [isAdmin]="isAdmin" (logoutEvent)="logout()" (toggleSidenav)="drawer.toggle()"></wd-navbar>

    <main class="content">
      <router-outlet></router-outlet>
    </main>

  </mat-drawer-content>
</mat-drawer-container>
