import { AttachmentModel } from './../interfaces/attachment.model';
import { GroupModelEssentials } from './../interfaces/group.model';
import { UserDict } from './../interfaces/meetingDecision.model';
import { UserReference } from './../interfaces/user.model';
import { AgendaItemModelEssentials } from './../interfaces/agendaItem.model';
import { MeetingModelEssentials } from './../interfaces/meeting.model';
import { DocumentRequestModel, FirestoreDocumentRequestModel } from "../interfaces/documentRequest.model";
import { Base } from './base.model';
import { merge, omit } from 'lodash';

export class DocumentRequest extends Base<DocumentRequestModel, FirestoreDocumentRequestModel> implements DocumentRequestModel {
  users: UserDict;
  meeting: MeetingModelEssentials;
  agendaItem: AgendaItemModelEssentials;
  createdBy: UserReference;
  group: GroupModelEssentials;
  organization: string;
  requestedUploads?: AttachmentModel[];
  message?: string;

  constructor(data: Partial<DocumentRequestModel>) {
    super();
    merge(this, data);
  }

  addRequestedUpload(attachment: AttachmentModel) {
    this.requestedUploads.push(attachment);
  }

  validate() {
    // TODO: implement proper validation
    return true;
  }

  static fromFirestoreData(data: Partial<FirestoreDocumentRequestModel>) {
    return new DocumentRequest({
      ...omit(data, ['toFirestore', 'validate']),
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
      meeting: {
        ...omit(data.meeting),
        startDate: data.meeting?.startDate?.toDate() || null,
        endDate: data.meeting?.endDate?.toDate() || null,
      },
      requestedUploads: (data?.requestedUploads || []).map((a) => {
        return {...omit(a, ['createdAt']), createdAt: a?.createdAt?.toDate() || null };
      }),
    });
  }

  toFirestore() {
    return {
      ...(this as any),
    } as FirestoreDocumentRequestModel;
  }
}