<div class="auth-layout login mat-app-background">
  <div class="login-wrapper" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.lt-lg="start center">
    <div fxLayout="row" class="login-container" fxLayoutAlign="center center">
      <div class="left" fxFlex="50%" fxFlex.lt-sm="0" fxLayoutAlign="center center" fxFill fxHide.lt-sm>
        <img src="assets/images/login.svg" width="100%"/>
      </div>
      <div fxLayout="column" fxFlex="50%" fxFlex.lt-sm="100%" class="right" fxFill>
        <div class="logo-wrapper" fxLayoutAlign="space-between center" fxFlex="64px">
          <div>
            <button mat-button color="primary" *ngIf="showLoginForm" (click)="toggleLoginForm(false)"><mat-icon class="sm">arrow_back</mat-icon> <ng-container i18n="general|back@@general_back">Back</ng-container></button>
          </div>
          <img [src]="logo" width="120"/>
        </div>
        <div *ngIf="loading" class="spinner-container">
          <wd-apollo-spinner size="80"></wd-apollo-spinner>
        </div>
        <div fxLayoutAlign="center center" *ngIf="!loading">
          <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="!loading">
            <div fxHide.gt-xs>
              <img src="assets/images/login.svg" width="100%"/>
            </div>
            <h2 i18n="login|Headline; login to account@@login_headline_login-to-account">Login to your account</h2>
            <div fxLayout="column" fxFlexAlign="space-around center" fxLayoutGap="2px">
              <div fxFlex class="text-center m-b-10 m-t-15" *ngIf="!showLoginForm">
                <button mat-stroked-button color="primary" class="full-width-button" type="button" (click)="toggleLoginForm(true)">
                  <mat-icon class="m-r-10">mail_outline</mat-icon>
                  <ng-container i18n="login|button to login with email@@login_button_login-with-email">Login with Email</ng-container>
                </button>
              </div>
              <div *ngIf="showLoginForm" fxLayout="column" fxLayoutGap="0px">
                <mat-form-field appearance="standard">
                  <mat-label i18n="login|label for Your email@@login_label_email">Your email</mat-label>
                  <input matInput autocomplete="email" class="wd-autofill" required formControlName="email">
                  <mat-error *ngIf="(loginForm.get('email').touched || loginForm.get('email').dirty) && loginForm.get('email').invalid" i18n="login|Error; invalid email@@login_error_invalid-email">
                    Please enter a valid email
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard" *ngIf="showLoginForm">
                  <mat-label i18n="login|label for Your password@@login_label_password">Your password</mat-label>
                  <input matInput class="wd-autofill" autocomplete='current-password' required type="password" formControlName="password">
                  <mat-error *ngIf="(loginForm.get('email').touched || loginForm.get('password').dirty) && loginForm.get('password').invalid" i18n="login|Error; enter password@@login_error_enter-password">
                    Please enter your password
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxFlex fxFlexAlignment="end" class="text-center" *ngIf="showLoginForm" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center" fxLayoutGap="10px" fxFill>
                <small><a [routerLink]="'/auth/forget-password'" i18n="login|forgot password button@@login_button_forgot-password" fxHide.lt-sm>Forgot Password?</a></small>
                <button mat-flat-button color="primary" type="submit" class="p-t-5 p-b-5" ngClass.lt-sm="full-width-button" [disabled]="adLoginInProgress" i18n="login|button to login@@login_button_login" fxFlexOrder="1" fxFlexOrder.lt-sm="0">Login</button>
                <small><a [routerLink]="'/auth/forget-password'" i18n="login|forgot password button@@login_button_forgot-password" fxHide.gt-xs>Forgot Password?</a></small>
              </div>
              <div fxFlex class="text-center m-b-10" *ngIf="!showLoginForm">
                <button mat-stroked-button color="primary" type="button" class="full-width-button" [disabled]="adLoginInProgress" (click)="loginWithMicrosoft()">
                  <img src="/assets/icons/png/ms_az_ad.png" class="m-r-10" style="width: 20px; height: 20px;">
                  <ng-container i18n="login|button to login with AD@@login_button_login-activedirectory">Login with Active Directory</ng-container>
                </button>
                <p *ngIf="adLoginInProgress">
                  <ng-container i18n="login|AD login loading message@@login_text_ad-login-loading">Active Directory Login in Progress. Please check instructions in the popup.</ng-container>
                  <wd-apollo-spinner size="80" style="margin: auto; margin-top: 10px;" *ngIf="loading"></wd-apollo-spinner>
                </p>
              </div>
              <div class="nda-check text-center m-t-20" *ngIf="!showLoginForm">
                <mat-checkbox (change)="privacyPolicyCheck($event)" class="policy-checkbox" [ngClass]="{'invalid': showPrivacyPolicyWarning}" [checked]="privacyPolicyChecked">
                  <small class="text-left" [ngClass]="{'wd-red': showPrivacyPolicyWarning}" i18n="login|accept privacy policy text@@login_text_accept-privacy-policy">
                    I have read and accept the <a href="/privacy-policy">Privacy Policy</a>.
                  </small>
                </mat-checkbox>
              </div>
              <div class="text-center bottom-links m-t-10 p-10" fxLayoutAlign="center center" fxHide.gt-xs>
                <small>
                  <a href="/imprint" i18n="login|imprint link@@login_button_imprint">Imprint</a> |
                  <a href="/privacy-policy" i18n="login|privacy policy button@@login_button_privacy-policy">Privacy Policy</a>
                </small>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="text-center bottom-links" fxHide.lt-sm>
      <small>
        <a href="/imprint" i18n="login|imprint link@@login_button_imprint">Imprint</a> |
        <a href="/privacy-policy" i18n="login|privacy policy button@@login_button_privacy-policy">Privacy Policy</a>
      </small>
    </div>
  </div>
</div>
