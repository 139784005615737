import { RACIResponsible } from './../interfaces/raci.model';
import { RACIData } from './raci.model';

import { ID, BaseModel, DocumentStatus } from '../interfaces/base.model';

export abstract class Base<T extends Base<any>, FT = T> implements BaseModel<FT> {
  id: ID;
  createdAt?: Date;
  updatedAt?: Date;
  status?: DocumentStatus;

  abstract validate(): boolean;
  abstract toFirestore(): FT;
}

export abstract class BaseRACI<T extends Base<any>, FT = T> implements BaseModel<FT> {
  id: ID;
  createdAt?: Date;
  updatedAt?: Date;
  status?: DocumentStatus;
  raci: RACIData = new RACIData();

  set responsible(value: RACIResponsible) {
    this.raci.responsible = value;
  }

  get responsible(): RACIResponsible {
    return this.raci.responsible;
  }

  get reportToList() {
    return this.raci?.reportToList;
  }

  set reportsActive(value: boolean) {
    this.raci.reportsActive = value;
  }

  get reportsActive() {
    return this.raci?.reportsActive;
  }

  set decisionImplemented(value: boolean) {
    this.raci.decisionImplemented = value;
  }

  get decisionImplemented() {
    return this.raci?.decisionImplemented;
  }

  set nextReportDueDate(value: Date) {
    this.raci.nextReportDueDate = value;
  }

  get nextReportDueDate() {
    return this.raci?.nextReportDueDate || undefined;
  }

  abstract validate(): boolean;
  abstract toFirestore(): FT;
}
