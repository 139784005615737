import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DialogService } from './dialog.service';

@NgModule({
  declarations: [ConfirmDialogComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule, MatButtonModule, MatCardModule, MatDialogModule],
  exports: [MatDialogModule, ConfirmDialogComponent],
  providers: [
    DialogService,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true, backdropClass: 'dialog-backdrop', panelClass: 'dialog-panel' },
    },
  ],
})
export class DialogModule {}
