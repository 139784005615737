import { RequestedDocumentsFromDict } from './../interfaces/agendaItem.model';
import { flatten, isNil, merge, omit, uniqBy, omitBy, pick } from 'lodash';

import { GroupModelEssentials, UserModelEssentials, UserReference } from '..';
import {
  AgendaItemModel,
  FirestoreAgendaItemModel,
  AgendaTodoItem,
  AgendaItemType,
  AccessFromDecision,
  AgendaItemModelEssentials,
  AgendaItemUpdate,
} from '../interfaces/agendaItem.model';
import { AttachmentModel, AttachmentType } from '../interfaces/attachment.model';
import { AttendanceDict, MeetingAttachmentModel, MeetingAttachmentStatus } from '../interfaces/meeting.model';

import { Base } from './base.model';

/**
 * since there isnt a simple built-in hashing function that works for both the browser and nodejs envrionment
 * and I didnt want to add a depenedency to this shared library just to create a simple, arbitrary hash for the generation of the attachment ID
 * I decided to add this simple custom implementation (copied of stackoverflow)
 * @param str
 */
/* istanbul ignore next */
function hashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const character = str.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash).toString();
}

export function getIsFirstLevelItem(agendaItems: AgendaItem[]): (agendaItem: AgendaItem) => boolean {
  const childAgendaItems = flatten(agendaItems.map((agendaItem) => agendaItem.children)).filter((id) => !isNil(id));
  if (childAgendaItems.length > 0) {
    return (agendaItem: AgendaItem) => !childAgendaItems.includes(agendaItem.id);
  } else {
    return () => false;
  }
}

export class AgendaItem extends Base<AgendaItemModel, FirestoreAgendaItemModel> implements AgendaItemModel {
  title: string;
  description: string;
  type: AgendaItemType = AgendaItemType.INFO;
  submittedBy: string;
  duration: number;
  order: number;
  minutes: string;
  attachments: MeetingAttachmentModel[] = [];
  todos: AgendaTodoItem[] = [];
  guests?: (string | UserReference)[] = [];
  attendance: AttendanceDict = {};
  parent?: string;
  children?: string[] = [];
  accessFromDecision?: AccessFromDecision[];
  group: GroupModelEssentials;
  requestedDocumentsFrom?: RequestedDocumentsFromDict;
  sharedWith?: UserModelEssentials[];

  constructor(data: Partial<AgendaItemModel>) {
    super();
    merge(this, data);
  }

  get essentialModel(): AgendaItemModelEssentials {
    return pick(this, ['id', 'title', 'description', 'duration', 'group']);
  }

  get isGroupItem() {
    return this.type === AgendaItemType.GROUP;
  }

  get isOrgaItem() {
    return this.type === AgendaItemType.ORGA;
  }

  get hasMinutes() {
    return !(this.type === AgendaItemType.GROUP || this.type === AgendaItemType.ORGA);
  }

  get numberOfPresentations(): number {
    return this.attachments.filter((a) => a.status === MeetingAttachmentStatus.READY_FOR_PRESENTATION).length;
  }

  get guestNames() {
    return (
      this.guests?.map((g) => {
        if (typeof g == 'string') {
          return g;
        } else {
          return g.unit?.abbreviation ? g.name + ` (${g.unit.abbreviation})` : g.name;
        }
      }) || []
    );
  }

  get guestIds() {
    return this.guests
      ?.filter((g) => {
        return typeof g !== 'string';
      })
      .map((g: any) => g?.id);
  }

  get sharedWithIds() {
    return this.sharedWith?.map((u) => u.id) || [];
  }

  get sharedWithString() {
    const guests = (this.guests.filter((g) => g.hasOwnProperty('name')) || []) as UserModelEssentials[];
    return uniqBy([...guests, ...(this.sharedWith || [])], 'id')
      .map((u) => u.name)
      .sort()
      .join(', ');
  }

  update(update: AgendaItemUpdate) {
    if (this.id !== update.id) {
      throw Error("can not update agenditem, id's don't match");
    }
    Object.keys(update)
      .filter((key) => key != 'id')
      .forEach((key) => {
        this[key] = update[key];
      });
  }

  addAttachment(attachment: AttachmentModel) {
    const meetingAttachment = attachment as MeetingAttachmentModel;
    if (attachment.type === AttachmentType.FILE) {
      if (!meetingAttachment.id && attachment?.filePath) {
        meetingAttachment.id = hashCode(attachment.filePath);
      }
      if (!meetingAttachment.status) {
        meetingAttachment.status = meetingAttachment.filePath.endsWith('.pdf')
          ? MeetingAttachmentStatus.CONVERTABLE_TO_PRESENTATION
          : MeetingAttachmentStatus.READY;
      }
    }
    this.attachments.push(attachment);
  }

  removeAttachment(attachment: AttachmentModel) {
    this.attachments = this.attachments.filter((a) => attachment !== a);
  }

  updateAttachmentLabel(attachment: AttachmentModel) {
    const index =
      attachment.type === AttachmentType.FILE
        ? this.attachments.findIndex((a) => a.filePath === attachment.filePath)
        : this.attachments.findIndex((a) => a.url === attachment.url);
    this.attachments[index] = attachment;
  }

  validate() {
    // TODO: implement proper validation
    return true;
  }

  static fromFirestoreData(data: Partial<FirestoreAgendaItemModel>) {
    const result = new AgendaItem({
      ...omit(data, ['toFirestore', 'validate', 'todos', 'children', 'parent']),
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
      todos: data.todos
        ? data.todos.map((ref) => {
            return {
              ...ref,
              dueDate: ref.dueDate?.toDate(),
            };
          })
        : [],
      attachments: data.attachments
        ? data.attachments.map((ref) => {
            return {
              ...ref,
              createdAt: ref.createdAt?.toDate(),
            };
          })
        : [],
      requestedDocumentsFrom: data?.requestedDocumentsFrom || {},
    });

    if (data.type === AgendaItemType.GROUP) {
      result.children = data.children;
    }
    if (data.parent) {
      result.parent = data.parent;
    }

    return result;
  }

  toFirestore() {
    return {
      ...omit<any>(this, [
        // remove these properties from the data before saving
        'index',
        'displayedIndex',
        'decisions',
        'start',
        'end',
        'children',
      ]),
      // ensure children exists on group agenda items
      ...(this.type === AgendaItemType.GROUP ? { children: this.children || [] } : {}),
      todos: this.todos.map((todo) => omitBy<any>(todo, (value, field) => field === 'dueDate' && !value)),
    } as FirestoreAgendaItemModel;
  }
}
