import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum Savestate {
  DIRTY = 'dirty',
  IN_PROGRESS = 'in_progress',
  DONE_SAVING = 'done_saving',
  SAVED = 'saved',
  FAILED = 'failed',
}

@Injectable({
  providedIn: 'root',
})
export class SavestateService {
  private _loadingDelay = 500;
  private _displayDelay = 5000;
  private _doneSavingTimeout: number;
  private _savedTimeout: number;
  private savestate = new BehaviorSubject<Savestate>(Savestate.SAVED);
  savestate$ = this.savestate.asObservable();

  touch() {
    this.clearTimeouts();
    this.savestate.next(Savestate.DIRTY);
  }

  startSaving() {
    this.clearTimeouts();
    this.savestate.next(Savestate.IN_PROGRESS);
  }

  fail() {
    this.clearTimeouts();
    this.savestate.next(Savestate.FAILED);
  }

  finishSaving() {
    this._doneSavingTimeout = window.setTimeout(() => {
      this.savestate.next(Savestate.DONE_SAVING);
      this._savedTimeout = window.setTimeout(() => {
        this.savestate.next(Savestate.SAVED);
        this.clearTimeouts();
      }, this._displayDelay);
    }, this._loadingDelay);
  }

  clearTimeouts() {
    clearTimeout(this._doneSavingTimeout);
    clearTimeout(this._savedTimeout);
  }
}
