import { pick } from 'lodash';

import { BaseModel, ID } from './base.model';
import { OrganizationReference } from './organization.model';

export type GroupReference = ID;
export interface GroupModelEssentials {
  id: GroupReference;
  name: string;
  abbreviation?: string;
}

export function mapToGroupRef(group: Partial<GroupModel>): GroupModelEssentials {
  return pick(group, ['id', 'name', 'abbreviation']) as GroupModelEssentials;
}

export function getGroupDisplayName(group: GroupModelEssentials) {
  return group?.abbreviation || group?.name;
}

export interface GroupModel extends BaseModel<FirestoreGroupModel> {
  /**
   * name of the organization
   */
  name: string;

  /**
   * organization the group belongs to
   * will be implicit in the data structure due to the used firestore collection, e.g.: `orf_groups`
   */
  organization: OrganizationReference;

  /**
   * should the group be visible to all users or only special ones
   */
  hidden: boolean;

  /**
   * calculated member count for easy display
   */
  memberCount: number;

  votingPercentage: number;

  adminIds: ID[];

  /**
   * the unit that the group is a member of (if any)
   */
  unit: { id: ID; name: string; abbreviation?: string };

  /**
   * Abbreviation for this group. Freely assignable, not related to the name.
   */
  abbreviation?: string;
}

export type FirestoreGroupModel = GroupModel;
