import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  QueryFn,
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { pickBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import 'firebase/compat/firestore';

import { SavestateService } from './savestate.service';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  get fieldValue() {
    return firebase.firestore.FieldValue;
  }

  constructor(private firestore: AngularFirestore, private savestateService: SavestateService) {
    // firebase.firestore.setLogLevel('debug');
  }

  collection<T>(path: string, queryFn?: QueryFn) {
    return this.firestore.collection<T>(path, queryFn);
  }

  createId() {
    return this.firestore.createId();
  }

  get<T>(ref: DocPredicate<T>) {
    return this.doc(ref).ref.get();
  }

  set<T>(ref: DocPredicate<T>, data: any, merge = false) {
    this.savestateService.startSaving();
    const timestamp = this.timestamp;
    const cleaned: any = pickBy(data, (v) => v !== undefined);
    return this.doc(ref)
      .set(
        {
          createdAt: timestamp,
          ...cleaned,
          updatedAt: timestamp,
        },
        { merge },
      )
      .then(() => {
        this.savestateService.finishSaving();
      })
      .catch((e) => {
        this.savestateService.fail();
        throw e;
      });
  }

  // TODO: make update more sophisticated, currently only supports basic property filtering
  update<T>(ref: DocPredicate<T>, data: any, updateProperties: string[]) {
    this.savestateService.startSaving();
    const timestamp = this.timestamp;
    const cleaned: any = pickBy(data, (value, key) => {
      return value !== undefined && updateProperties.includes(key);
    });

    return this.doc(ref)
      .update({
        createdAt: timestamp,
        ...cleaned,
        updatedAt: timestamp,
      })
      .then(() => {
        this.savestateService.finishSaving();
      })
      .catch((e) => {
        this.savestateService.fail();
        throw e;
      });
  }

  batchSet<T>(refs: DocPredicate<T>[], objects: any[], merge = false) {
    this.savestateService.startSaving();
    const batch = this.firestore.firestore.batch();
    const timestamp = this.timestamp;

    refs.forEach((ref, i) => {
      batch.set(
        this.doc(ref).ref,
        {
          createdAt: timestamp,
          ...objects[i],
          updatedAt: timestamp,
        },
        { merge },
      );
    });
    return batch
      .commit()
      .then(() => {
        this.savestateService.finishSaving();
      })
      .catch((e) => {
        this.savestateService.fail();
        throw e;
      });
  }

  delete<T>(ref: DocPredicate<T>) {
    this.savestateService.startSaving();
    return this.doc(ref)
      .delete()
      .then(() => {
        this.savestateService.finishSaving();
      })
      .catch((e) => {
        this.savestateService.fail();
        throw e;
      });
  }

  /**
   * Helper method for deleting small subcollections
   * @param collectionPath the collection to be deleted
   */
  async deleteCollection(collectionPath: string) {
    const deletions = [];
    // FIXME: currently this only allows limited collection size
    await this.firestore
      .collection(collectionPath)
      .get()
      .toPromise()
      .then((result) => {
        result.docs.forEach((document) => {
          deletions.push(document.ref.delete());
        });
      });
    await Promise.all(deletions);
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.firestore.collection<T>(ref, queryFn) : ref;
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.firestore.doc<T>(ref) : ref;
  }

  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref)
      .snapshotChanges()
      .pipe(
        map((doc) => {
          if (!doc.payload.exists) {
            throw new Error('Document for ' + ref + ' does not exist');
          }
          return doc.payload.data() as T;
        }),
      );
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<T[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((docs) => {
          return docs.map((a) => a.payload.doc.data()) as T[];
        }),
      );
  }

  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<any[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        }),
      );
  }
}
