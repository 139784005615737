import { UnitModel } from '..';
import { ID, BaseModel, FirestoreTimestamp } from './base.model';
import { GroupReference } from './group.model';
import { NotificationType } from './notification.model';
import { OrganizationReference } from './organization.model';
import { Signature } from './signature.model';

export function mapToUserRef(user: Partial<UserModel>, organizationId: string = null) {
  if (organizationId && user.organizations[organizationId]?.unit) {
    return { id: user.id, name: user.name, unit: user.organizations[organizationId].unit } as UserModelEssentials;
  } else {
    return { id: user.id, name: user.name } as UserModelEssentials;
  }
}

export type UserReference = UserModelEssentials;

export interface UserModelEssentials {
  /**
   * user id
   */
  id: ID;

  /**
   * user name
   */
  name: string;

  /**
   * user avatar
   */
  avatar?: string;

  /**
   *
   */
  unit?: UnitModel;
}

export enum UserRegistrationState {
  ACTIVE = 'active',
  PENDING = 'pending',
}

export interface UserImpersonationReference extends UserReference {
  write: boolean;
}

export type ImpersonatorsDict = { [uid: string]: UserImpersonationReference };

export interface UserModel<DateType = Date> extends BaseModel<FirestoreUserModel, DateType>, UserModelEssentials {
  /**
   * email address of the user
   * must be unique
   */
  email: string;

  /**
   * firstname of the user
   */
  firstname: string;

  /**
   * lastname of the user
   */
  lastname: string;

  /**
   * user status
   */
  registrationState: UserRegistrationState;

  /**
   * last active date
   */
  lastActive?: DateType;

  /**
   * TODO: move this into an organization specific data model => e.g. the `OrganizationRoleAssignment` model
   * The abbreviation for the user within this organization
   */
  abbreviation?: string;

  /**
   * TODO: move this into an organization specific data model => e.g. the `OrganizationRoleAssignment` model
   * The job description of the user within this organization
   */
  jobDescription?: string;

  /**
   * dictionary of organizations the user is part of and in/with which roles/permissions
   */
  organizations: OrganizationList;

  /**
   * dictionary of user settings
   */
  settings: UserSettings;

  /**
   * timestamp when the auth claims were last updated at
   */
  claimsLastUpdatedAt?: DateType;

  /**
   * path to the user's current signature
   */
  signature?: Signature;

  /**
   * all users that are allowed to impersonate this user
   */
  allowImpersonationBy: ImpersonatorsDict;

  /**
   * user's preferred language
   */
  language?: 'en' | 'de';
}

export type OrganizationList = { [organizationId: string]: OrganizationRoleAssignment };

export interface OrganizationUnit {
  id: ID;
  name: string;
  abbreviation?: string;
}
export interface OrganizationRoleAssignment {
  /**
   * organization the user has access to
   */
  organization?: OrganizationReference;

  /**
   * the roles/permissions the user has in that particular organization
   */
  roles: Role[];

  /**
   * the groups that the user is member of in this organization
   */
  groups: GroupList;

  /**
   * the unit that the user is a member of (if any)
   */
  unit?: OrganizationUnit;
}

export type GroupList = { [groupId: string]: GroupRoleAssignment };

export interface GroupRoleAssignment {
  /**
   * group the user has access to
   */
  group: GroupReference;
  /**
   * the roles/permissions the user has in that particular group
   */
  roles: GroupRole[];
}

export interface UserWithClaims {
  claims: {
    [key: string]: any;
  };
}

export interface UserWithCustomClaims {
  customClaims: {
    [key: string]: any;
  };
}

export interface UserSettings {
  algoliaKey?: string;
  notifications: {
    emailFrequency?: EmailFrequency;
    email: {
      [NT in NotificationType]: boolean;
    };
    app: {
      [NT in NotificationType]: boolean;
    };
  };
}

export type FirestoreUserModel = UserModel<FirestoreTimestamp>;

/**
 * currently defined roles = permissions
 */
export enum Role {
  ADMIN = 'admin',
  ORGANIZER = 'sub_admin',
  USER = 'requester',
}

export enum GroupRole {
  GROUP_ADMIN = 'group_admin',
  ASSISTANT = 'group_assistant',
  DECIDER = 'group_decider',
}

export enum EmailFrequency {
  IMMEDIATELY = 'immediately',
  PERIODICALLY = 'periodically',
  DO_NOT_SEND = 'do_not_send',
}
