import { RACIData, ReportRecurring } from './raci.model';
import { isUndefined, merge, omit, pickBy, uniq } from 'lodash';
import { DateTime } from 'luxon';

import { GroupModelEssentials } from '..';
import {
  DeciderDict,
  FirestoreMeetingDecisionModel,
  MeetingDecisionModel,
  MeetingDecisionVotingResult,
  MemberDict,
} from '../interfaces/meetingDecision.model';
import { ReportTimeframeType } from '../interfaces/raci.model';
import { UserReference } from '../interfaces/user.model';

import { BaseRACI } from './base.model';

export class MeetingDecision
  extends BaseRACI<MeetingDecisionModel, FirestoreMeetingDecisionModel>
  implements MeetingDecisionModel
{
  private _owner: UserReference;
  private _decidedAt: Date;

  title: string;
  decisionText?: string;
  members: MemberDict = {};
  deciders: DeciderDict = {};
  organization: string;
  meetingId: string;
  agendaItemId: string;
  contextText: string;
  decisionVoting?: MeetingDecisionVotingResult;
  raci: RACIData = new RACIData();
  group: GroupModelEssentials;
  order?: number;

  get memberList() {
    return this.members ? Object.values(this.members) : [];
  }

  get deciderList() {
    return this.deciders ? Object.values(this.deciders) : [];
  }

  get owner() {
    return this._owner;
  }
  set owner(value: UserReference) {
    this._owner = value;
    this.members[value.id] = value;
  }

  set decidedAt(value: Date) {
    this._decidedAt = value;
  }
  get decidedAt() {
    return this._decidedAt;
  }

  constructor(data: Partial<MeetingDecisionModel>) {
    super();
    Object.assign(this, data);
    merge(this, data);
    if (data.raci) {
      this.raci = RACIData.fromFirestoreData(data.raci);
    }
  }

  validate() {
    // TODO: implement proper validation
    return true;
  }

  static fromFirestoreData(data: Partial<FirestoreMeetingDecisionModel>) {
    return new MeetingDecision({
      ...omit(data, ['decidedAt', 'createdAt', 'updatedAt', 'activities', 'raciReportToIds', 'reportDueDate']),
      decidedAt: data.decidedAt?.toDate(),
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
    });
  }

  calculateNextReportIntervalDuedate(date: Date) {
    if (this.raci?.reportAfterTimeFrame?.type === ReportTimeframeType.recurring) {
      const interval = (this.raci?.reportAfterTimeFrame as ReportRecurring)?.interval;
      const unit = (this.raci?.reportAfterTimeFrame as ReportRecurring)?.unit;
      const duration = unit === 'weeks' ? { weeks: interval } : { months: interval };
      return DateTime.fromJSDate(date).plus(duration).toJSDate();
    }
    return undefined;
  }

  generateACL() {
    const writeACLs = uniq([this._owner.id, ...Object.keys(this.deciders)]);

    return {
      read: uniq([...Object.keys(this.members), ...writeACLs]),
      write: writeACLs,
    };
  }

  toFirestore() {
    // use pickBy to actually delete unset private properties from for firestore encoded object
    return pickBy(
      {
        ...this,
        ...this.encodePrivateProperties(),
        acl: this.generateACL(),
        raci: this.raci.toFirestore(),
        raciReportToIds: this.reportToList,
      },
      (v) => !isUndefined(v),
    ) as FirestoreMeetingDecisionModel;
  }

  private encodePrivateProperties() {
    return Object.assign(
      {},
      ...Object.keys(this)
        .filter((p) => p.startsWith('_'))
        .map((p) => ({
          // unset private property from export
          [p]: undefined,

          [p.substr(1)]: this[p],
        })),
    );
  }
}
