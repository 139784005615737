import { Injectable, OnDestroy } from '@angular/core';
import { AttachmentModel, FirestoreOrganizationModel, Organization, OrganizationModel } from '@wedecide/models';
import { Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService implements OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  organization: OrganizationModel;
  constructor(private authService: AuthService, private db: FirestoreService, private storageService: StorageService) {
    this.currentOrganization$.pipe(takeUntil(this._destroy$)).subscribe((organization) => {
      this.organization = organization;
    });
  }

  get currentOrganization$() {
    return this.authService.currentOrganizationId$.pipe(
      switchMap((organizationId) => {
        if (!organizationId) {
          return [null];
        }
        return this.db.doc$<FirestoreOrganizationModel>('organizations/' + organizationId);
      }),
      map((org) => {
        return org ? Organization.fromFirestoreData(org) : null;
      }),
      shareReplay(1),
    );
  }

  getLogoUrl(filePath: string) {
    const base = 'https://firebasestorage.googleapis.com/v0/b';
    const storageBucket = environment.firebase.storageBucket;
    if (filePath) {
      return `${base}/${storageBucket}/o/${encodeURIComponent(filePath)}?alt=media`;
    }
    return undefined;
  }

  removeAttachment(attachment: AttachmentModel) {
    return this.storageService.deletePath(attachment.filePath).toPromise();
  }

  save(model: Organization) {
    return this.db.set<FirestoreOrganizationModel>(`organizations/${model.id}`, model.toFirestore()).then(() => {
      return model;
    });
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }
}
