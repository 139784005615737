import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '@wedecide/shared/services/auth.service';
import firebase from 'firebase/compat';

import { ThemeService } from './../../services/theme.service';

@Component({
  selector: 'wd-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @Output() toggleSidenav = new EventEmitter();
  @Output() logoutEvent = new EventEmitter();
  @Input() user: firebase.User;
  @Input() userProfileImage: string;
  @Input() hasManageAccess: boolean;
  @Input() isAdmin: boolean;

  get isNotBasicUser() {
    return !this.authService.isBasicUser();
  }

  constructor(
    // eslint-disable-next-line prettier/prettier
    private themeService: ThemeService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {}

  logout() {
    this.logoutEvent.emit();
  }

  changeTheme() {
    this.themeService.changeTheme();
  }

  close() {
    this.toggleSidenav.emit(true);
  }
}
