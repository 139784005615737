import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FunctionsError } from 'firebase/functions';
import { AppErrorOverlayComponent } from '../../error-handling/app-error-overlay/app-error-overlay.component';
import { DialogService } from '../modules/dialog/dialog.service';
import { ErrorMessageService } from './error-message.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(
    public snackBar: MatSnackBar,
    public router: Router,
    private dialogService: DialogService,
    private errorMessageService: ErrorMessageService,
  ) {}

  showErrorOverlay(error?: Error) {
    this.dialogService.openStandardDialog(AppErrorOverlayComponent, {
      width: '90%',
      maxWidth: '800px',
      data: {
        error,
      },
    });
  }

  showServerErrorSnackbar(error?: Error, message?: string) {
    message =
      message ||
      $localize`:error|Error; server error@@error_server-error:A server error occured. Your data might not have been saved.`;
    // if (!environment.production && error) {
    //   message += '\nError: ' + error.message;
    // }
    this.snackBar.open(message, $localize`:general|close@@general_close:Close`, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 0,
      panelClass: 'wd-red',
    });
    if (error) {
      console.error(error);
    }
  }

  showServerErrorMessageSnackbar(error: FunctionsError) {
    this.snackBar.open(
      this.errorMessageService.getErrorMessage(error),
      $localize`:general|close@@general_close:Close`,
      {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 0,
        panelClass: 'wd-red',
      },
    );
    console.error(error.message);
  }

  redirectToPermissionDenied() {
    this.router.navigateByUrl('error/permission-denied');
  }
}
