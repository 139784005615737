import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { ThemeService } from '../../shared/services/theme.service';

@Component({
  selector: 'wd-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  public isEnglish = true;
  public iconPath = '/assets/icons/png/languages/de.png';

  constructor(private themeService: ThemeService, private location: Location) {}

  get logo() {
    return this.themeService.svgLogo;
  }

  backClicked() {
    this.location.back();
  }

  toggleLanguage() {
    this.iconPath = this.isEnglish ? '/assets/icons/png/languages/en.png' : '/assets/icons/png/languages/de.png';
    this.isEnglish = !this.isEnglish;
  }

  ngOnInit() {}
}
