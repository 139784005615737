import { MEETING_UPLOADED_REQUESTED_DOCUMENTS_TEMPLATE } from './templates/meeting_uploaded_requested_documents';
import { MEETING_REQUEST_DOCUMENTS_TEMPLATE } from './templates/meeting_request_documents';
import { NotificationType } from '../interfaces/notification.model';
import { ADMIN_CONNECTED_USERS_TEMPLATE } from './templates/admin_connected_users';
import { CONNECT_USER_TEMPLATE } from './templates/connect_user';
import { CR_APPROVAL_RATE_REACHED_TEMPLATE } from './templates/cr_approval_rate_reached';
import { CR_CANCEL_CIRCULAR_RESOLUTION_TEMPLATE } from './templates/cr_cancel_circular_resolution';
import { CR_FINALIZE_CIRCULAR_RESOLUTION_TEMPLATE } from './templates/cr_finalize_circular_resolution';
import { CR_PUBLISH_CIRCULAR_RESOLUTION_TEMPLATE } from './templates/cr_publish_circular_resolution';
import { CR_SHARE_CIRCULAR_RESOLUTION_TEMPLATE } from './templates/cr_share_circular_resolution';
import { CR_VOTING_DUE_SOON_TEMPLATE } from './templates/cr_voting_duedate';
import { CR_VOTING_FINISHED } from './templates/cr_voting_finished';
import { GUEST_FINALIZE_MEETING_TEMPLATE } from './templates/guest_finalize_meeting';
import { GUEST_PUBLISH_AGENDA_TEMPLATE } from './templates/guest_publish_agenda';
import { MEETING_AGENDA_FEEDBACK_TEMPLATE } from './templates/meeting_agenda_feedback';
import { MEETING_MINUTES_ACCEPT_REVIEW } from './templates/meeting_minutes_accept_review';
import { MEETING_MINUTES_REVIEW_COMPLETE } from './templates/meeting_minutes_review_complete';
import { MEETING_MINUTES_REVIEW_DUE_SOON_TEMPLATE } from './templates/meeting_minutes_review_duedate';
import { MEETING_MINUTES_REVIEW_REQUEST } from './templates/meeting_minutes_review_request';
import { MEETING_MINUTES_SIGNING_COMPLETE_TEMPLATE } from './templates/meeting_minutes_signing_complete';
import { MEETING_MINUTES_SUBMIT_REVIEW } from './templates/meeting_minutes_submit_review';
import { MEETING_MINUTES_SIGNING_PENDING_TEMPLATE } from './templates/meeting_pending_minutes_signing';
import { MEETING_MINUTES_SIGNING_REQUEST_TEMPLATE } from './templates/meeting_request_minutes_signing';
import { MEETING_TODO_DUE_SOON_TEMPLATE } from './templates/meeting_todo_duedate';
import { PUBLISH_MEETING_TEMPLATE } from './templates/publish_meeting';
import { PUBLISH_MEETING_FINAL_TEMPLATE } from './templates/publish_meeting_final';
import { PUBLISH_MEETING_MINUTES_TEMPLATE } from './templates/publish_meeting_minutes';
import { RACI_DAY_BEFORE_REMINDER } from './templates/raci_day_before_reminder';
import { RACI_DECISION_IMPLEMENTED_TEMPLATE } from './templates/raci_decision_implemented';
import { RACI_REPORT_COMMENT } from './templates/raci_report_comment';
import { RACI_REPORT_OVERDUE_REMINDER } from './templates/raci_report_overdue_reminder';
import { RACI_REPORT_SHARED } from './templates/raci_report_shared';
import { RACI_RESPONSIBLE_FINALIZE_TEMPLATE } from './templates/raci_responsible_finalize';
import { RACI_WEEK_BEFORE_REMINDER } from './templates/raci_week_before_reminder';
import { SHARE_MEETING_AGENDA_TEMPLATE } from './templates/share_meeting_agenda';
import { SHARE_MEETING_MINUTES_TEMPLATE } from './templates/share_meeting_minutes';

export interface EmailTemplate {
  /**
   * Translation key for call to action text
   */
  ctaKey: string;

  /**
   * optional template key
   * default.handlebars is used if not provided
   */
  templateKey?: string;
}

export type EmailNotificationConfig = {
  /**
   * Translation key for call to action text
   */
  ctaKey: string;

  /**
   * optional template key
   * default.handlebars is used if not provided
   */
  templateKey?: string;
};

export interface NotificationConfiguration {
  inapp: string;
  email: EmailNotificationConfig;
}

export const NOTIFICATION_TEMPLATES: Record<NotificationType, NotificationConfiguration> = {
  [NotificationType.PUBLISH_MEETING]: PUBLISH_MEETING_TEMPLATE,
  [NotificationType.PUBLISH_MEETING_MINUTES]: PUBLISH_MEETING_MINUTES_TEMPLATE,
  [NotificationType.PUBLISH_MEETING_FINAL]: PUBLISH_MEETING_FINAL_TEMPLATE,
  [NotificationType.SHARE_MEETING_AGENDA]: SHARE_MEETING_AGENDA_TEMPLATE,
  [NotificationType.SHARE_MEETING_MINUTES]: SHARE_MEETING_MINUTES_TEMPLATE,
  [NotificationType.RACI_RESPONSIBLE_FINALIZE]: RACI_RESPONSIBLE_FINALIZE_TEMPLATE,
  [NotificationType.RACI_REPORT_SHARED]: RACI_REPORT_SHARED,
  [NotificationType.RACI_WEEK_BEFORE_REMINDER]: RACI_WEEK_BEFORE_REMINDER,
  [NotificationType.RACI_DAY_BEFORE_REMINDER]: RACI_DAY_BEFORE_REMINDER,
  [NotificationType.RACI_REPORT_OVERDUE_REMINDER]: RACI_REPORT_OVERDUE_REMINDER,
  [NotificationType.RACI_REPORT_COMMENT]: RACI_REPORT_COMMENT,
  [NotificationType.RACI_DECISION_IMPLEMENTED]: RACI_DECISION_IMPLEMENTED_TEMPLATE,
  [NotificationType.GUEST_PUBLISH_AGENDA]: GUEST_PUBLISH_AGENDA_TEMPLATE,
  [NotificationType.GUEST_FINALIZE_MEETING]: GUEST_FINALIZE_MEETING_TEMPLATE,
  [NotificationType.MEETING_MINUTES_REVIEW_REQUEST]: MEETING_MINUTES_REVIEW_REQUEST,
  [NotificationType.MEETING_MINUTES_REVIEW_COMPLETE]: MEETING_MINUTES_REVIEW_COMPLETE,
  [NotificationType.MEETING_MINUTES_REVIEW_DUE_SOON]: MEETING_MINUTES_REVIEW_DUE_SOON_TEMPLATE,
  [NotificationType.MEETING_MINUTES_SIGNING_REQUEST]: MEETING_MINUTES_SIGNING_REQUEST_TEMPLATE,
  [NotificationType.MEETING_MINUTES_SIGNING_PENDING]: MEETING_MINUTES_SIGNING_PENDING_TEMPLATE,
  [NotificationType.MEETING_MINUTES_SIGNING_COMPLETE]: MEETING_MINUTES_SIGNING_COMPLETE_TEMPLATE,
  [NotificationType.MEETING_MINUTES_SUBMIT_REVIEW]: MEETING_MINUTES_SUBMIT_REVIEW,
  [NotificationType.MEETING_MINUTES_ACCEPT_REVIEW]: MEETING_MINUTES_ACCEPT_REVIEW,
  [NotificationType.MEETING_AGENDA_FEEDBACK]: MEETING_AGENDA_FEEDBACK_TEMPLATE,
  [NotificationType.MEETING_REQUEST_DOCUMENTS]: MEETING_REQUEST_DOCUMENTS_TEMPLATE,
  [NotificationType.MEETING_UPLOADED_REQUESTED_DOCUMENTS]: MEETING_UPLOADED_REQUESTED_DOCUMENTS_TEMPLATE,
  [NotificationType.CR_SHARE_CIRCULAR_RESOLUTION]: CR_SHARE_CIRCULAR_RESOLUTION_TEMPLATE,
  [NotificationType.CR_PUBLISH_CIRCULAR_RESOLUTION]: CR_PUBLISH_CIRCULAR_RESOLUTION_TEMPLATE,
  [NotificationType.CR_CANCEL_CIRCULAR_RESOLUTION]: CR_CANCEL_CIRCULAR_RESOLUTION_TEMPLATE,
  [NotificationType.CR_APPROVAL_RATE_REACHED]: CR_APPROVAL_RATE_REACHED_TEMPLATE,
  [NotificationType.CR_FINALIZE_CIRCULAR_RESOLUTION]: CR_FINALIZE_CIRCULAR_RESOLUTION_TEMPLATE,
  [NotificationType.CR_VOTING_DUE_SOON]: CR_VOTING_DUE_SOON_TEMPLATE,
  [NotificationType.CR_VOTING_FINISHED]: CR_VOTING_FINISHED,
  [NotificationType.TODO_DUE_SOON]: MEETING_TODO_DUE_SOON_TEMPLATE,
  [NotificationType.CONNECT_USER]: CONNECT_USER_TEMPLATE,
  [NotificationType.ADMIN_CONNECTED_USERS]: ADMIN_CONNECTED_USERS_TEMPLATE,
};
