import { ID } from '@wedecide/models';

import { AttachmentModel } from './attachment.model';
import { BaseModel, BaseModelData, FirestoreTimestamp, ACL } from './base.model';
import { GroupModelEssentials } from './group.model';
import { ParticipantsDict } from './meeting.model';
import { RACIDataType } from './raci.model';
import { SignatoriesDict } from './signature.model';
import { UserReference } from './user.model';

export enum CircularResolutionState {
  IS_DRAFT = 'draft',
  IN_PROGRESS = 'in_progress',
  CANCELLED = 'cancelled',
  FINAL = 'final',
}
export interface SharedCircularResolutionModel<DateType = Date> extends BaseModelData<DateType> {
  /**
   * title of the circular resolution
   * is required
   */
  title: string;

  /**
   * circular resolution proposal
   */
  proposal: string;

  /**
   * circular resolution description
   */
  description?: string;

  /**
   * circular resolution state
   */
  state: CircularResolutionState;

  /**
   * circular resolution organization
   */
  organization: string;

  /**
   * circular resolution group
   */
  group: GroupModelEssentials;

  /**
   * circular resolution creator
   */
  createdBy: UserReference;

  /**
   * circular resolution due date
   */
  dueDate?: DateType;

  /**
   * allow participants to abstain from voting
   */
  allowAbstain?: boolean;

  /**
   * circular resolution approval rate percentage
   */
  approvalRate: number;

  /**
   * list of circular participants and their attendance status
   */
  participants: CircularResolutionParticipantsDict<DateType>;

  /**
   * a list of linked attachments (`documents` in customer-facing views)
   * (embedded subcollection)
   */
  attachments?: AttachmentModel<DateType>[];

  /**
   * viewers
   */
  viewers: ParticipantsDict;

  /**
   * signature is required for voting
   */
  requestSignatureForVoting?: boolean;

  /**
   * signatories
   */
  signatories?: SignatoriesDict<DateType>;

  /**
   * decided at date
   */
  decidedAt?: DateType;

  /**
   * RACI data
   */
   raci?: RACIDataType;
}

export type CircularResolutionCustomMetadata = { [key: string]: string };

export enum VotingResult {
  YES = 'yes',
  NO = 'no',
  ABSTAIN = 'abstain',
}
export interface VoterReference<DateType = Date> {
  /**
   * user id
   */
  id: ID;

  /**
   * user name
   */
  name: string;

  /**
   * user avatar
   */
  avatar?: string;

  /**
   * optional voting comment
   */
  comment?: string;

  /**
   * voting result
   */
  votingResult?: VotingResult;

  /**
   * voting date
   */
  votedAt?: DateType;

  /**
   * user impersonated by
   */
  impersonatedBy?: UserReference;
}

export type CircularResolutionParticipantsDict<DateType = Date> = { [uid: string]: VoterReference<DateType> };

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CircularResolutionModel
  extends SharedCircularResolutionModel,
    BaseModel<FirestoreCircularResolutionModel> {}

export interface FirestoreCircularResolutionModel<DateType = FirestoreTimestamp>
  extends SharedCircularResolutionModel<FirestoreTimestamp> {
  /**
   * defines the ACL for this decision and which users do have access to the decision
   */
  acl: ACL;
}
