import { redirectLoggedInTo } from '@angular/fire/compat/auth-guard';
import firebase from 'firebase/compat';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { GroupRole, OrganizationRoleAssignment, Role } from '@wedecide/models';
import { ClaimsUtility } from '@wedecide/models';

import { mergeMap } from 'rxjs/operators';

export const redirectLoggedInToHome = () => redirectLoggedInTo(['']);

const basicChecks = (user: firebase.User, state: RouterStateSnapshot) => {
  // check basic signin and verified status
  if (!user) {
    localStorage.setItem('redirectUrl', state?.url || '/');
    return ['login'];
  }

  const providerData = user?.providerData || [];
  const isMicrosoftUser = providerData.find((provider) => {
    return provider.providerId == 'microsoft.com';
  });

  if (!(user.emailVerified || isMicrosoftUser)) {
    // eslint-disable-next-line no-console
    console.error('User Email not verified, is this user setup correctly?');
    return ['logout'];
  }
  return true;
};

const getOrganizationClaims = (token: firebase.auth.IdTokenResult): OrganizationRoleAssignment => {
  // fetch token for role checks
  if (!token.claims.organizations) {
    return null;
  }

  if (Object.keys(token.claims.organizations).length != 1) {
    throw new Error('More than 1 organization claim');
  }

  return Object.values(token.claims.organizations)[0] as OrganizationRoleAssignment;
};

function hiearchicalGlobalRoleCheck(role, roles) {
  if (role === Role.ADMIN && roles.includes(Role.ADMIN)) {
    return true;
  } else if (role === Role.ORGANIZER && (roles.includes(Role.ORGANIZER) || roles.includes(Role.ADMIN))) {
    return true;
  } else if (!role || role == Role.USER) {
    // TODO: reenable auth check for global roles again
    return true;
  }
}

export const checkGlobalRole = (role: Role) => {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return mergeMap(async (user: firebase.User) => {
      const checkSuccessful = basicChecks(user, state);
      if (checkSuccessful !== true) {
        return checkSuccessful;
      }

      const token = await user.getIdTokenResult();
      const organizationClaims = getOrganizationClaims(token);
      if (organizationClaims == null) {
        // eslint-disable-next-line no-console
        console.error('No organization claims, is this user setup correctly?');
        return ['error', 'permission-denied'];
      }
      //console.log('got org info', organizationClaims);
      const roles = organizationClaims.roles as string[];
      if (hiearchicalGlobalRoleCheck(role, roles)) {
        return true;
      }
      return ['error', 'permission-denied'];
    });
  };
};

export const checkGlobalRolesOrGroupRoles = (allowedRoles: Role[], allowedGroupRoles: GroupRole[]) => {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return mergeMap(async (user: firebase.User) => {
      const checkSuccessful = basicChecks(user, state);
      if (checkSuccessful !== true) {
        return checkSuccessful;
      }

      const token = await user.getIdTokenResult();
      const organizationClaims = await getOrganizationClaims(token);
      if (organizationClaims == null) {
        // eslint-disable-next-line no-console
        console.error('No organization claims, is this user setup correctly?');
        return ['error', 'permission-denied'];
      }
      const roles = organizationClaims.roles as string[];
      for (const allowedRole of allowedRoles) {
        if (hiearchicalGlobalRoleCheck(allowedRole, roles)) {
          return true;
        }
      }

      const claimsUtility = new ClaimsUtility();
      for (const allowedGroupRole of allowedGroupRoles) {
        if (claimsUtility.hasRolesInAnyGroup(token, [allowedGroupRole])) {
          return true;
        }
      }

      return ['error', 'permission-denied'];
    });
  };
};

export const checkAnyGroupRole = (role: GroupRole) => {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return mergeMap(async (user: firebase.User) => {
      const checkSuccessful = basicChecks(user, state);
      if (checkSuccessful !== true) {
        return checkSuccessful;
      }

      const token = await user.getIdTokenResult();
      const organizationClaims = getOrganizationClaims(token);
      if (organizationClaims == null) {
        // eslint-disable-next-line no-console
        console.error('No organization claims, is this user setup correctly?');
        return ['error', 'permission-denied'];
      }
      //console.log('got org info', organizationClaims);
      const claimsUtility = new ClaimsUtility();
      if (claimsUtility.hasRolesInAnyGroup(token, [role])) {
        return true;
      }
      return ['error', 'permission-denied'];
    });
  };
};

export const checkImportActionsAccess = () => {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return mergeMap(async (user: firebase.User) => {
      const checkSuccessful = basicChecks(user, state);
      if (checkSuccessful !== true) {
        return checkSuccessful;
      }
      const claimsUtility = new ClaimsUtility();
      const token = await user.getIdTokenResult();
      if (token) {
        return claimsUtility.hasAccessToImportActions(token);
      }

      return ['error', 'permission-denied'];
    });
  };
};

export const hasAdminRole = checkGlobalRole(Role.ADMIN);

export const hasOrganizerRoleOrMore = checkGlobalRole(Role.ORGANIZER);

// TODO: remove?
export const hasUserRole = checkGlobalRole(Role.USER);

export const isAnyGroupAssistant = checkAnyGroupRole(GroupRole.ASSISTANT);

export const isAnyGroupAdmin = checkAnyGroupRole(GroupRole.GROUP_ADMIN);

export const isAnyGroupAdminOrSubAdminOrAdmin = checkGlobalRolesOrGroupRoles(
  [Role.ADMIN, Role.ORGANIZER],
  [GroupRole.GROUP_ADMIN],
);

export const hasAccessToImportActions = checkImportActionsAccess();
