import { AttachmentModel } from './attachment.model';
import { ID, BaseModelData, FirestoreTimestamp, BaseModel } from './base.model';
import { UserReference } from './user.model';

export type OrganizationReference = ID;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OrganizationSettings {
  // currently unused
}

export enum AccountType {
  TRIAL = 'trial',
  PAID = 'paid',
}

export interface AccountUsage {
  count: number;
  max: number;
}

export interface OrganizationAccount<DateType = Date> {
  /**
   * The type of account
   */
  type: AccountType;

  /**
   * If onboarding overlay should be shown
   */
  onboardingFinished?: boolean;

  /**
   * nhe date until the account is paid for (or trial lasts).
   */
  validUntil: DateType;

  /**
   * current usage and max boards of the account
   */
  boards: AccountUsage;

  /**
   * current usage and max members of the account
   */
  members: AccountUsage;
}

export interface SharedOrganizationModel<DateType = Date> extends BaseModelData<DateType> {
  /**
   * name of the organization
   */
  name: string;
  /**
   * domain of the organization
   */
  domain?: string;

  /**
   * logo of the organization
   */
  logo?: OrganizationAttachmentModel<DateType>;

  /**
   * user that created and owns the organization
   */
  owner: UserReference;

  /**
   * various settings for the organization
   */
  settings?: OrganizationSettings;

  /**
   * all information of the account for that organization
   * mostly related to billing
   */
  account: OrganizationAccount<DateType>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OrganizationModel extends SharedOrganizationModel, BaseModel<FirestoreOrganizationModel> {}
export type OrganizationAttachmentModel<DateType = Date> = AttachmentModel<DateType>;
export type FirestoreOrganizationModel = SharedOrganizationModel<FirestoreTimestamp>;
