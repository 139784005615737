import { Pipe, PipeTransform } from '@angular/core';
import {
  formatDateTime,
  formatDateWithoutYear,
  formatDateLong,
  formatDateShort,
  formatDateTimeAgo,
  formatDuration,
  formatTime,
  humanizeDuration,
  humanizeDurationLong,
  TZ_DEFAULT,
} from '@wedecide/models';
import { DateTime, Duration } from 'luxon';

@Pipe({
  name: 'humanizeDuration',
})
export class HumanizeDurationPipe {
  transform(duration: Duration | number, timer = false, hrsUnit = 'h', minsUnit = 'm', secsUnit = 's'): string {
    return humanizeDuration(duration, timer, hrsUnit, minsUnit, secsUnit);
  }
}

@Pipe({
  name: 'humanizeDurationLong',
})
export class HumanizeDurationLongPipe {
  transform(duration: Duration | number, timer = false): string {
    return humanizeDurationLong(duration, timer);
  }
}

@Pipe({
  name: 'formatDuration',
})
export class FormatDurationPipe {
  transform(duration: Duration): string {
    return formatDuration(duration);
  }
}

@Pipe({
  name: 'formatTime',
})
export class FormatTimePipe implements PipeTransform {
  transform(dt: DateTime | Date, zone = TZ_DEFAULT): string {
    return formatTime(dt, zone);
  }
}

@Pipe({
  name: 'formatDateShort',
})
export class DateShortPipe implements PipeTransform {
  transform(dt: DateTime | Date): string {
    return formatDateShort(dt);
  }
}

@Pipe({
  name: 'formatDateLong',
})
export class DateLongPipe implements PipeTransform {
  transform(dt: DateTime | Date): string {
    return formatDateLong(dt);
  }
}

@Pipe({
  name: 'formatDateTime',
})
export class DateTimePipe implements PipeTransform {
  transform(dt: DateTime | Date, zone = TZ_DEFAULT): string {
    return formatDateTime(dt, zone);
  }
}

@Pipe({
  name: 'formatDateTimeAgo',
  pure: false,
})
export class DateTimeToTimeAgoPipe implements PipeTransform {
  transform(dt: DateTime | Date): string {
    return formatDateTimeAgo(dt);
  }
}
@Pipe({
  name: 'formatDateWithoutYear',
})
export class DateWithoutYearPipe implements PipeTransform {
  transform(dt: DateTime | Date): string {
    return formatDateWithoutYear(dt);
  }
}
