import { Injectable } from '@angular/core';
import { QueryFn } from '@angular/fire/compat/firestore';
import {
  UserModel,
  DocumentStatus,
  UserSettings,
  GroupRole,
  FirestoreUserModel,
  User,
  Signature,
  UnitModel,
  UserModelEssentials,
  ImpersonatorsDict,
} from '@wedecide/models';
import { orderBy } from 'lodash';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';
import { OrganizationService } from './organization.service';
import { ImpersonationInfo } from './impersonation.service';

function sortBy<T = UserModel>(users: T[]): T[] {
  return orderBy(
    users,
    [
      (user: any) => {
        return user.lastname ? user.lastname.toLowerCase() : '';
      },
    ],
    ['asc'],
  );
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private organizationId: string;
  private impersonatedBy: ImpersonationInfo;
  private _organizationMembers$: BehaviorSubject<UserModel[]> = new BehaviorSubject([]);

  constructor(
    organizationService: OrganizationService,
    private db: FirestoreService,
    private authService: AuthService,
  ) {
    this.authService.user$.subscribe((user) => {
      this.impersonatedBy = user?.claims?.imp || null;
    });

    organizationService.currentOrganization$.subscribe((org) => {
      if (org && org.id) {
        this.organizationId = org.id;

        this.db
          .colWithIds$<UserModel>(`organizations/${this.organizationId}/users`)
          .pipe(
            map((docs) =>
              docs.map((doc) =>
                User.fromFirestoreData({
                  id: doc.id,
                  ...doc,
                }),
              ),
            ),
          )
          .subscribe((res) => {
            this._organizationMembers$.next(
              sortBy(
                res.filter((item) => {
                  return item?.status !== DocumentStatus.DELETED;
                }),
              ),
            );
          });
      } else {
        this._organizationMembers$.next([]);
      }
    });
  }

  get organizationMembers$() {
    return this._organizationMembers$;
  }

  get currentMembers() {
    return this._organizationMembers$.value;
  }

  getUser(userId: string, organizationId: string): Promise<UserModel> {
    return firstValueFrom(this.db.doc(`organizations/${organizationId}/users/${userId}`).get()).then((userRef) => {
      const userData = userRef.data() as UserModel;
      return userData;
    });
  }

  async getUserReference(userId: string, organizationId: string): Promise<UserModelEssentials> {
    // userreference,if possible with unit for organization
    const user = await this.getUser(userId, organizationId);
    if (user.organizations[organizationId].unit) {
      return { id: user.id, name: user.name, unit: user.organizations[organizationId].unit as UnitModel };
    } else {
      return { id: user.id, name: user.name };
    }
  }

  subscribeUser(userId: string, organizationId: string): Observable<User> {
    return this.db.doc$<FirestoreUserModel>(`organizations/${organizationId}/users/${userId}`).pipe(
      map((userRef) => {
        const userData = User.fromFirestoreData(userRef);
        return userData;
      }),
    );
  }

  updateSettings(userId: string, organizationId: string, settings: UserSettings) {
    return this.db.doc(`organizations/${organizationId}/users/${userId}`).update({ settings: settings });
  }

  updateAllowImpersonationBy(userId: string, organizationId: string, allowedImpersonationBy: ImpersonatorsDict) {
    return this.db
      .doc(`organizations/${organizationId}/users/${userId}`)
      .update({ allowImpersonationBy: allowedImpersonationBy });
  }

  getImpersonatableUsers(userId: string, organizationId: string): Observable<User[]> {
    return this.db
      .colWithIds$<UserModel>(`organizations/${organizationId}/users`, (ref) => {
        return ref.where(`allowImpersonationBy.${userId}.id`, '==', userId);
      })
      .pipe(
        map((users) => {
          return sortBy<User>(users.filter((user) => user.status !== 'deleted').map(User.fromFirestoreData));
        }),
      );
  }

  getUsersByOrganization(
    organizationId: string,
    query: QueryFn = (ref) => {
      return ref.where(`status`, '!=', 'deleted');
    },
  ): Observable<User[]> {
    return this.db.colWithIds$<UserModel>(`organizations/${organizationId}/users`, query).pipe(
      map((users) => {
        return sortBy<User>(users.map(User.fromFirestoreData));
      }),
    );
  }

  // remove deleted/disabled users in query instead of filtering - currently not all users have a status field
  getUsersByGroup(organizationId, groupId, groupRoles: GroupRole[] = []): Observable<UserModel[]> {
    return this.db
      .colWithIds$<UserModel>(`organizations/${organizationId}/users`, (ref) => {
        // check if field exists and is not null
        if (groupRoles.length == 0) {
          return ref.where(`organizations.${organizationId}.groups.${groupId}.group`, '==', groupId);
        } else {
          return ref.where(`organizations.${organizationId}.groups.${groupId}.roles`, 'array-contains-any', groupRoles);
        }
      })
      .pipe(
        map((users: any) => {
          return sortBy(
            users.filter((user) => {
              return user?.status !== DocumentStatus.DELETED;
            }),
          );
        }),
      );
  }

  updateSignatureUrl(userId: string, signature: Signature): Promise<void> {
    if (this.impersonatedBy) {
      return Promise.resolve();
    }
    return this.db.doc(`organizations/${this.organizationId}/users/${userId}`).update({ signature });
  }
}
