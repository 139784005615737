import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { formatDateTimeAgo, Notification } from '@wedecide/models';
import { BaseComponent } from '@wedecide/shared/base.component';
import { AuthService } from '@wedecide/shared/services/auth.service';
import { DateTime } from 'luxon';
import { Observable, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { NotificationService } from './../../../services/notification.service';

@Component({
  selector: 'wd-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent extends BaseComponent implements OnDestroy {
  @Input() notification: Notification;

  public date: Observable<string>;

  get read() {
    return this.notification?.read;
  }

  get icon() {
    return this.notification.icon;
  }

  get headline() {
    return this.notificationService.getInAppMessage(this.notification, this.authService.user.displayName);
  }

  get hasWriteAccess() {
    return this.authService.currentUserHasWriteAccess;
  }

  get displayClass() {
    let ngClass = [];
    if (this.read) {
      ngClass.push('read');
    }
    if (!this.hasWriteAccess) {
      ngClass.push('disabled');
    }
    return ngClass.join(' ');
  }

  constructor(
    private notificationService: NotificationService,
    private authService: AuthService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    // render date every minute
    this.date = timer(0, 60000).pipe(
      map(() => {
        return this.notification?.createdAt
          ? formatDateTimeAgo(DateTime.fromJSDate(this.notification?.createdAt))
          : '-';
      }),
      takeUntil(this._destroy$),
    );
  }

  markAsRead() {
    if (this.hasWriteAccess) {
      this.notification = this.notification.markAsRead();
      this.notificationService.update(this.notification, { read: true });
    }
  }

  markAsUnread() {
    if (this.hasWriteAccess) {
      this.notification = this.notification.markAsUnread();
      this.notificationService.update(this.notification, { read: false });
    }
  }

  toggleRead() {
    if (!this.hasWriteAccess) {
      return;
    }

    if (this.notification.read) {
      this.markAsUnread();
    } else {
      this.markAsRead();
    }
  }

  // todo refactor navigateTo
  navigateToRoute() {
    this.markAsRead();
    if (!this.notification.itemId && !this.notification.urlPath) {
      return;
    }

    if (this.notification.urlPath) {
      // console.log('navigating to ' + this.notification.urlPath);
      this.router.navigateByUrl(this.notification.urlPath);
      return;
    }

    // todo only use url path
    if (this.notification.isMeetingRoute()) {
      this.router.navigate(['meetings', this.notification.itemId]);
    } else {
      this.router.navigate(['manage', this.notification.itemId]);
    }
  }
}
