import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TrackingService } from '@wedecide/shared/services/tracking.service';

export interface NavigationHistory {
  currentUrl: string;
  previousUrl?: string;
}

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private _navigationHistory: NavigationHistory;

  get navigationHistory() {
    return this._navigationHistory;
  }

  get currentUrlFragment() {
    const urlTree = this.router.parseUrl(this.router.url);
    return urlTree.fragment || null;
  }

  constructor(private router: Router, private location: Location, private trackingService: TrackingService) {}

  replaceState(url: string) {
    this.location.replaceState(url);
    this._navigationHistory = {
      currentUrl: url,
      previousUrl: this._navigationHistory?.currentUrl || null,
    };
  }

  initHistory() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkHubspotState(event.url);
        if (event.urlAfterRedirects !== this._navigationHistory?.currentUrl) {
          this._navigationHistory = {
            currentUrl: event.urlAfterRedirects,
            previousUrl: this._navigationHistory?.currentUrl || null,
          };
        }
      }
    });
  }

  private checkHubspotState(url: string) {
    let hide = false;
    // hide on presentation viewer
    hide = (url.match(/\/meetings\/[a-zA-Z0-9]+\/presentation\/.+/gm) || []).length > 0;
    this.trackingService.displayHubspotChatButton(hide === true ? false : true);
  }
}
