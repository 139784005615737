import { Component } from '@angular/core';

@Component({
  selector: 'wd-internal-server-error',
  templateUrl: './internal-server-error.component.html',
  styleUrls: ['./../error-handling-base.component.scss', './internal-server-error.component.scss'],
})
export class InternalServerErrorComponent {
  constructor() {}
}
