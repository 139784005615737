import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ImpersonationService } from '../services/impersonation.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsGuard implements CanActivate {
  constructor(private impersonationService: ImpersonationService) {}

  canActivate(): Observable<boolean> {
    return this.impersonationService.impersonatedBy$.pipe(map((impersonationInfo) => !impersonationInfo));
  }
}
