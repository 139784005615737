import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { PrivacySettingsDialog } from '../../privacy-settings/privacy-settings-dialog/privacy-settings-dialog.component';

@Component({
  selector: 'wd-privacy-policy-de',
  templateUrl: './privacy-policy-de.component.html',
  styleUrls: ['../privacy-policy.component.scss'],
})
export class PrivacyPolicyDeComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  scrollToId(scrollId: string) {
    setTimeout(() => {
      const element = <HTMLElement>document.querySelector('#' + scrollId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }

  openSettingsDialog() {
    this.dialog.open(PrivacySettingsDialog);
  }
}
