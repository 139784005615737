import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { ImprintComponent } from './imprint/imprint.component';

@NgModule({
  declarations: [ImprintComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule, MatButtonModule],
})
export class ImprintModule {}
