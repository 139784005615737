<div class="notification-item m-b-5" [ngClass]="displayClass" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
  <div fxFlex="30px" fxLayout="column">
    <div class="notification-icon pointer" [ngClass]="displayClass" fxLayout="row" fxLayoutAlign="center center" (click)="toggleRead()">
      <mat-icon [ngClass]="{'disabled': !hasWriteAccess}" class="sm" *ngIf="!read">{{icon.icon}}</mat-icon>
      <mat-icon [ngClass]="{'disabled': !hasWriteAccess}"class="sm" *ngIf="read">check</mat-icon>
    </div>
  </div>
  <mat-card class="notification-card no-hover" fxFlex="calc(100%-35px)" (click)="navigateToRoute()">
    <mat-card-content>
      <div fxLayout="column">
        <div class="notification-date light-text"><small>{{(date | async) || '-'}}</small></div>
        <div *ngIf="headline" class="m-b-5" [innerHTML]="headline"></div>
        <div class="m-b-5 light-text" [innerHTML]="notification.message"></div>
        <div class="light-text" *ngIf="notification?.title">
          <mat-card class="item-card" fxLayout="row" fxLayoutAlign="center center">
            <div fxFlex="calc(100%-20px)">{{notification.title}}</div>
            <mat-icon class="sm light-text" fxFlex="20px" *ngIf="notification?.itemId">open_in_new</mat-icon>
          </mat-card>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
