import { compact, intersection, invert, omit, pick, some } from 'lodash';

import {
  Role,
  GroupRole,
  GroupRoleAssignment,
  UserWithClaims,
  UserModel,
  OrganizationRoleAssignment,
  GroupList,
  UserWithCustomClaims,
} from '../interfaces/user.model';
// import { User } from '../models/user.model';

// maps group role string enum to numeric enum and back
export enum GroupRoleNumeric {
  group_admin = '1',
  group_assistant = '2',
  group_decider = '3',
}

export const numericToGroupRoleMap = {
  [GroupRoleNumeric.group_admin]: GroupRole.GROUP_ADMIN,
  [GroupRoleNumeric.group_assistant]: GroupRole.ASSISTANT,
  [GroupRoleNumeric.group_decider]: GroupRole.DECIDER,
};

type UserWithClaimsOrOrganizations = UserWithClaims | UserWithCustomClaims | UserModel;

interface NumericGroupList {
  [groupId: string]: GroupRoleNumeric[];
}
interface NumericOrganizationRoleAssignment {
  organization: string;
  roles: Role[];
  g: NumericGroupList;
}

export interface NumericOrganizationList {
  [organizationId: string]: NumericOrganizationRoleAssignment;
}

type GenericOrgRoleAssignment = NumericOrganizationRoleAssignment | OrganizationRoleAssignment;

function hasClaims(user: UserWithClaimsOrOrganizations): user is UserWithClaims {
  return user && (user as UserWithClaims).claims !== undefined;
}

function hasCustomClaims(user: UserWithClaimsOrOrganizations): user is UserWithCustomClaims {
  return user && (user as UserWithCustomClaims).customClaims !== undefined;
}

function isNumericOrganization(
  organization: GenericOrgRoleAssignment,
): organization is NumericOrganizationRoleAssignment {
  return organization && (organization as NumericOrganizationRoleAssignment).g !== undefined;
}

export class ClaimsUtility {
  /**
   * essential user fragment, including only user id and name
   */
  // get essentialUserFragment() {
  //   return { id: this.user?.uid, name: this.user?.displayName };
  // }

  currentOrganizationId(user: UserWithClaimsOrOrganizations) {
    return this.getFirstOrganizationId(user);
  }

  private getFirstOrganizationId(user: UserWithClaimsOrOrganizations) {
    const organizations = this.getUserOrganizations(user);
    if (!organizations || Object.values(organizations).length == 0) {
      return null;
    }
    return Object.keys(organizations)[0];
  }

  private getFirstOrganization(
    user: UserWithClaimsOrOrganizations,
  ): OrganizationRoleAssignment | NumericOrganizationRoleAssignment {
    const organizations = this.getUserOrganizations(user);
    if (!organizations || Object.values(organizations).length == 0) {
      return null;
    }
    return Object.values<OrganizationRoleAssignment | NumericOrganizationRoleAssignment>(organizations)[0];
  }

  private getUserOrganizations(user: UserWithClaimsOrOrganizations) {
    if (hasClaims(user)) {
      return user?.claims?.organizations;
    } else if (hasCustomClaims(user)) {
      return user?.customClaims?.organizations;
    } else {
      return user?.organizations;
    }
  }

  private getUserRoles(user: UserWithClaimsOrOrganizations) {
    return this.getFirstOrganization(user)?.roles;
  }

  private getOrganizationGroups(organization: GenericOrgRoleAssignment): GroupList {
    if (isNumericOrganization(organization)) {
      return this.numericToFullGroups(organization.g);
    } else {
      return organization?.groups;
    }
  }

  private numericToFullGroups(numericGroups: NumericGroupList): GroupList {
    const groups = {};
    Object.entries(numericGroups).forEach(([groupId, numericRoles]) => {
      const roles = compact(numericRoles.map((nR) => numericToGroupRoleMap[nR]));
      groups[groupId] = {
        group: groupId,
        roles: roles,
      };
    });
    return groups;
  }

  public compactOrganizationGroupRoles(organization: OrganizationRoleAssignment): NumericOrganizationRoleAssignment {
    const groups = organization.groups || {};
    // remove all non claim info from organization
    const numericOrganization = pick(organization, ['roles']) as NumericOrganizationRoleAssignment;
    const numericGroups = {};

    // convert group roles to numeric roles
    Object.entries(groups).forEach(([groupId, group]) => {
      const numericRoles = group.roles?.map((role) => GroupRoleNumeric[role]);
      numericGroups[groupId] = numericRoles;
    });
    numericOrganization.g = numericGroups;
    return numericOrganization;
  }

  hasRoles(user: UserWithClaimsOrOrganizations, roles: string[]) {
    return some(this.getUserRoles(user) || [], (role) => roles.includes(role));
  }

  hasRolesInGroup(user: UserWithClaimsOrOrganizations, groupId: string, roles: GroupRole[]) {
    const organizationClaims = this.getFirstOrganization(user);
    if (!organizationClaims) {
      return false;
    }
    const groups = this.getOrganizationGroups(organizationClaims);
    if (!groups || !groups[groupId]) {
      return false;
    }
    const groupRoles = groups[groupId].roles || [];
    const matches = intersection(groupRoles, roles);
    return matches.length > 0;
  }

  hasRolesInAnyGroup(user: UserWithClaimsOrOrganizations, roles: GroupRole[]) {
    // console.log(this.getUserOrganizations());
    const organizationClaims = this.getFirstOrganization(user);
    // console.log('organizationClaims', this.currentOrganizationId, organizationClaims);
    let hasRoles = false;
    if (!organizationClaims) {
      return false;
    }
    const groups = this.getOrganizationGroups(organizationClaims);
    if (!groups) {
      return false;
    }
    Object.values(groups).forEach((group: GroupRoleAssignment) => {
      hasRoles = hasRoles || some(group.roles || [], (role) => roles.includes(role));
    });
    return hasRoles;
  }
  isAdmin(user: UserWithClaimsOrOrganizations) {
    return this.hasRoles(user, [Role.ADMIN]);
  }
  isSubAdmin(user: UserWithClaimsOrOrganizations) {
    return this.hasRoles(user, [Role.ORGANIZER]);
  }
  isMoreThanUser(user: UserWithClaimsOrOrganizations) {
    return this.hasRoles(user, [Role.ADMIN, Role.ORGANIZER]);
  }

  hasAccessToImportActions(user: UserWithClaimsOrOrganizations) {
    let email = '';
    const hiddenAccessEmails = ['andreas@apollo.ai', 'katharina@apollo.ai'];
    if ((user as UserModel)?.email) {
      email = (user as UserModel)?.email;
    } else if ((user as UserWithClaims)?.claims?.email) {
      email = (user as UserWithClaims)?.claims?.email;
    } else if ((user as UserWithCustomClaims)?.customClaims?.email) {
      email = (user as UserWithCustomClaims)?.customClaims?.email;
    }
    return hiddenAccessEmails.includes(email);
  }

  isBasicUser(user: UserWithClaimsOrOrganizations) {
    return this.getGroupIdsWhereUserHasAnyGivenRoles(user, [GroupRole.ASSISTANT, GroupRole.DECIDER]).length === 0;
  }

  isGroupMember(user: UserWithClaimsOrOrganizations, groupId: string) {
    return !!this.getOrganizationGroups(this.getFirstOrganization(user))[groupId];
  }

  getGroups(user: UserWithClaimsOrOrganizations): GroupList {
    return this.getOrganizationGroups(this.getFirstOrganization(user)) || {};
  }

  getGroupRoles(user: UserWithClaimsOrOrganizations, groupId: string) {
    const organization = this.getFirstOrganization(user);
    return this.getOrganizationGroups(organization)[groupId]?.roles;
  }

  getGroupIds(user: UserWithClaimsOrOrganizations) {
    return Object.keys(this.getGroups(user) || {});
  }

  isGroupAdmin(user: UserWithClaimsOrOrganizations) {
    return this.hasRolesInAnyGroup(user, [GroupRole.GROUP_ADMIN]);
  }

  getGroupIdsWhereUserHasAnyGivenRoles(user: UserWithClaimsOrOrganizations, requiredRoles: GroupRole[]) {
    const matchingGroupIds: string[] = [];
    const groups = this.getGroups(user);
    Object.entries(groups).forEach((params) => {
      const groupId = params[0];
      const group = params[1];
      const groupRoles = group.roles;
      if (intersection(groupRoles, requiredRoles).length > 0) {
        matchingGroupIds.push(groupId);
      }
    });
    return matchingGroupIds;
  }

  getManagedGroupIds(user: UserWithClaimsOrOrganizations) {
    return this.getGroupIdsWhereUserHasAnyGivenRoles(user, [GroupRole.GROUP_ADMIN]);
  }

  getAssistedGroupIds(user: UserWithClaimsOrOrganizations) {
    return this.getGroupIdsWhereUserHasAnyGivenRoles(user, [GroupRole.ASSISTANT]);
  }

  getDeciderGroupIds(user: UserWithClaimsOrOrganizations) {
    return this.getGroupIdsWhereUserHasAnyGivenRoles(user, [GroupRole.DECIDER]);
  }

  canManageGroups(user: UserWithClaimsOrOrganizations) {
    if (this.hasRoles(user, [Role.ADMIN, Role.ORGANIZER])) {
      return true;
    }
    if (this.hasRolesInAnyGroup(user, [GroupRole.GROUP_ADMIN])) {
      return true;
    }
    return false;
  }

  canManageUsers(user: UserWithClaimsOrOrganizations) {
    if (this.hasRoles(user, [Role.ADMIN, Role.ORGANIZER])) {
      return true;
    }
    return false;
  }
}
