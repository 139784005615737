import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { QueryFn } from '@angular/fire/compat/firestore';
import { NotificationModel, Notification, SUPPORTED_NOTIFICATIONS } from '@wedecide/models';
import { BaseComponent } from '@wedecide/shared/base.component';
import { AuthService } from '@wedecide/shared/services/auth.service';
import { NotificationService } from '@wedecide/shared/services/notification.service';

import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wd-notification-widget',
  templateUrl: './notification-widget.component.html',
  styleUrls: ['../widgets.component.scss', './notification-widget.component.scss'],
})
export class NotificationWidgetComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() displayUnreadBadge = true;

  private _notificationItems: NotificationModel[] = [];
  private _displayAllNotifications = false;
  private _loading = true;
  private _limit = 50;
  showMoreNotificationsLink = false;

  get loading() {
    return this._loading;
  }

  get notificationItems() {
    return this._displayAllNotifications
      ? this._notificationItems
      : this._notificationItems.filter((item) => item.read === false);
  }

  get displayAllNotifications() {
    return this._displayAllNotifications;
  }

  get filterText() {
    return this._displayAllNotifications
      ? $localize`:notifications|Label; view unread@@notifications_label_view-unread:View unread only`
      : $localize`:notifications|Label; view all@@notifications_label_view-all:View all`;
  }

  get numberOfUnreadNotifications() {
    return this._notificationItems.filter((item) => item.read === false).length;
  }

  get hasWriteAccess() {
    return this.authService.currentUserHasWriteAccess;
  }

  constructor(private authService: AuthService, private notificationService: NotificationService) {
    super();
  }

  ngOnInit(): void {
    this.authService.user$.pipe(takeUntil(this._destroy$)).subscribe(async (user) => {
      if (user) {
        this.loadNotifactions((ref) => ref.limit(this._limit + 1).orderBy('createdAt', 'desc'));
      }
    });
  }

  toggleFilter($event: MouseEvent) {
    $event.preventDefault();
    this._displayAllNotifications = !this._displayAllNotifications;
  }

  async markAllRead($event: MouseEvent) {
    $event.preventDefault();

    if (this.hasWriteAccess) {
      this._loading = true;
      await this.notificationService.markAllAsRead().then(() => {
        this._loading = false;
      });
    }
  }

  loadAllNotifications() {
    this._loading = true;
    this._notificationItems = [];
    this.loadNotifactions();
  }

  loadNotifactions(queryFn?: QueryFn) {
    this._loading = true;
    this.notificationService
      .query(queryFn)
      .pipe(takeUntil(this._destroy$))
      .subscribe((items: Notification[]) => {
        if (queryFn) {
          this.showMoreNotificationsLink = items.length > this._limit;
        } else {
          this.showMoreNotificationsLink = false;
        }
        this._notificationItems = this.sortItems(items.filter((n) => SUPPORTED_NOTIFICATIONS.inapp[n.type] === true));
        this._loading = false;
      });
  }

  private sortItems(items: Notification[]) {
    return items.sort((a: any, b: any) => {
      if (!b.createdAt) {
        return -1;
      }
      return b?.createdAt - a?.createdAt;
    });
  }
}
