import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipeModule } from '@wedecide/shared/pipes/pipe.module';

// import { DecisionCardModule } from './../../../../decision-card/decision-card.module';
import { NotificationItemComponent } from './notification-item.component';
import { NotificationWidgetComponent } from './notification-widget.component';

@NgModule({
  declarations: [NotificationWidgetComponent, NotificationItemComponent],
  imports: [
    CommonModule,
    // DecisionCardModule,
    FlexLayoutModule,

    MatDividerModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    PipeModule,
  ],
  exports: [NotificationWidgetComponent],
})
export class NotificationWidgetModule {}
