import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wd-switch-user',
  templateUrl: './switch-user.component.html',
})
export class SwitchUserComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
