import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ThemeService } from './../../shared/services/theme.service';

@Component({
  selector: 'wd-error-pages',
  templateUrl: './error-pages.component.html',
  styleUrls: ['./error-pages.component.scss'],
})
export class ErrorPagesComponent {
  get logo() {
    return this.themeService.logo;
  }

  constructor(private themeService: ThemeService, private dialogRef: MatDialog) {}

  ngOnInit() {
    // close all dialogs, to avoid overlapping error message
    this.dialogRef.closeAll();
  }
}
