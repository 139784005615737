import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import {
  Notification,
  NotificationModel,
  Meeting,
  FirestoreNotificationModel,
  CircularResolution,
  MeetingDecision,
  AgendaItem,
  MeetingModelEssentials,
  AgendaItemModelEssentials,
  NotificationType,
} from '@wedecide/models';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, take } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  unreadNotifications: number = 0;
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService,
    private firestoreService: FirestoreService,
  ) {
    this.authService.user$.pipe(take(1), takeUntil(this._destroy$)).subscribe(async () => {
      this.firestore
        .collection(
          `organizations/${this.authService.currentOrganizationId}/users/${this.authService.user.uid}/notifications`,
        )
        .valueChanges()
        .pipe(takeUntil(this._destroy$))
        .subscribe(async () => {
          this.updateCounter();
        });
    });
  }

  private updateCounter(user = this.authService.user, organization = this.authService.currentOrganizationId) {
    this.firestore
      .collection(`organizations/${organization}/users/${user.uid}/notifications`)
      .ref.where('read', '==', false)
      .get()
      .then((snaphot) => {
        this.unreadNotifications = snaphot.size;
      });
  }

  async addMeetingNotification(
    data: Partial<NotificationModel>,
    item:
      | Meeting
      | CircularResolution
      | MeetingDecision
      | AgendaItem
      | MeetingModelEssentials
      | AgendaItemModelEssentials = null,
    recipients: string[] = [],
  ) {
    const notification = new Notification({
      ...data,
      read: false,
    });

    if (item) {
      notification.title = item.title;
      notification.itemId = item.id;
      if (item?.group) {
        notification.board = item.group;
      }

      if (recipients.length == 0 && (item instanceof Meeting || item instanceof CircularResolution)) {
        recipients = Object.keys(item?.participants).filter((id) => id !== this.authService.user.uid);
        // recipients = item?.participantsList
        //   .map((participant) => participant.id)
        //   .filter((id) => id !== this.authService.user.uid);
      }
    }

    // if (!notification?.message) {
    //   notification.message = notification.getInAppMessage(this.authService.user.displayName);
    // }

    return Promise.all(
      recipients.map((recipient) => {
        this.save(notification, recipient);
      }),
    );
  }

  query(
    queryFn?: QueryFn,
    organization = this.authService.currentOrganizationId,
    user = this.authService.user,
  ): Observable<Notification[]> {
    return this.firestoreService
      .col$(`organizations/${organization}/users/${user.uid}/notifications`, queryFn)
      .pipe(map((docs) => docs.map((data) => Notification.fromFirestoreData(data))));
  }

  async markAllAsRead(organization = this.authService.currentOrganizationId, user = this.authService.user) {
    this.firestoreService
      .col(`organizations/${organization}/users/${user.uid}/notifications`, (ref) => ref.where('read', '==', false))
      .get()
      .toPromise()
      .then((querySnapshot) => {
        const batch = this.firestore.firestore.batch();
        querySnapshot.forEach((doc) => {
          batch.update(doc.ref, { read: true });
        });
        return batch.commit();
      });
  }

  async update(
    model: NotificationModel,
    data: Partial<NotificationModel>,
    userId = this.authService.user.uid,
    organization = this.authService.currentOrganizationId,
  ) {
    await this.firestoreService
      .doc(`organizations/${organization}/users/${userId}/notifications/${model.id}`)
      .update(data)
      .then(() => {
        return model;
      });
  }

  async save(
    model: NotificationModel,
    userId = this.authService.user.uid,
    organization = this.authService.currentOrganizationId,
  ) {
    if (!model.id) {
      model.id = this.firestore.createId();
      model.createdAt = new Date();
    }
    model.updatedAt = new Date();
    // console.log(this.firestore);
    return this.firestore
      .collection<FirestoreNotificationModel>(`organizations/${organization}/users/${userId}/notifications`)
      .doc(model.id)
      .set(model.toFirestore())
      .then(() => {
        return model;
      });
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  getInAppMessage(notification: Notification, displayName: string) {
    const { triggeredBy, title, dueDate, mentionedUsers } = notification;
    const triggeredByName = triggeredBy?.name || '';
    const notificationTranslationsMap: Record<NotificationType, any> = {
      [NotificationType.ADMIN_CONNECTED_USERS]: $localize`:notification|@@ADMIN_CONNECTED_USERS:Your Admin <b>${triggeredByName}</b> has connected your user to <b>${mentionedUsers}</b>.`,
      [NotificationType.CONNECT_USER]: $localize`:notification|@@CONNECT_USER:<b>${triggeredByName}</b> has connected your users.`,
      [NotificationType.CR_APPROVAL_RATE_REACHED]: $localize`:notification|@@CR_APPROVAL_RATE_REACHED:Approval rate is reached`,
      [NotificationType.CR_CANCEL_CIRCULAR_RESOLUTION]: $localize`:notification|@@CR_CANCEL_CIRCULAR_RESOLUTION:The circular resolution has been <b>canceled</b>.`,
      [NotificationType.CR_FINALIZE_CIRCULAR_RESOLUTION]: $localize`:notification|@@CR_FINALIZE_CIRCULAR_RESOLUTION:The circular resolution is <b>final</b>.`,
      [NotificationType.CR_PUBLISH_CIRCULAR_RESOLUTION]: $localize`:notification|@@CR_PUBLISH_CIRCULAR_RESOLUTION:<b>${triggeredByName}</b> asks you to vote until <b>${dueDate}</b>.`,
      [NotificationType.CR_SHARE_CIRCULAR_RESOLUTION]: $localize`:notification|@@CR_SHARE_CIRCULAR_RESOLUTION:<b>${triggeredByName}</b> shared a circular resolution with you.`,
      [NotificationType.CR_VOTING_DUE_SOON]: $localize`:notification|@@CR_VOTING_DUE_SOON:The voting is pending until <b>${dueDate}</b>`,
      [NotificationType.CR_VOTING_FINISHED]: $localize`:notification|@@CR_VOTING_FINISHED:The voting for the circular resolution is finished`,
      [NotificationType.GUEST_FINALIZE_MEETING]: $localize`:notification|@@GUEST_FINALIZE_MEETING:<b>${triggeredByName}</b> shared the minutes for the agenda item <b>${title}</b> with you.`,
      [NotificationType.GUEST_PUBLISH_AGENDA]: $localize`:notification|@@GUEST_PUBLISH_AGENDA:You are invited to a new meeting for the agenda item <b>${title}</b>.`,
      [NotificationType.MEETING_AGENDA_FEEDBACK]: $localize`:notification|@@MEETING_AGENDA_FEEDBACK:<b>${triggeredByName}</b> has shared feedback on the agenda <b>${title}</b> with you.`,
      [NotificationType.MEETING_MINUTES_ACCEPT_REVIEW]: $localize`:notification|@@MEETING_MINUTES_ACCEPT_REVIEW:<b>${triggeredByName}</b> has accepted the minutes without changes.`,
      [NotificationType.MEETING_MINUTES_REVIEW_COMPLETE]: $localize`:notification|@@MEETING_MINUTES_REVIEW_COMPLETE:All reviewers have now submitted their meeting minutes reviews.`,
      [NotificationType.MEETING_MINUTES_REVIEW_DUE_SOON]: $localize`:notification|@@MEETING_MINUTES_REVIEW_DUE_SOON:The review is pending until <b>${dueDate}</b>`,
      [NotificationType.MEETING_MINUTES_REVIEW_REQUEST]: $localize`:notification|@@MEETING_MINUTES_REVIEW_REQUEST:<b>${triggeredByName}</b> asks you to review meeting minutes.`,
      [NotificationType.MEETING_MINUTES_SIGNING_COMPLETE]: $localize`:notification|@@MEETING_MINUTES_SIGNING_COMPLETE:All signatories have signed the meeting mintues.`,
      [NotificationType.MEETING_MINUTES_SUBMIT_REVIEW]: $localize`:notification|@@MEETING_MINUTES_SUBMIT_REVIEW:<b>${triggeredByName}</b> has made review comment(s).`,
      [NotificationType.MEETING_MINUTES_SIGNING_PENDING]: $localize`:notification|@@MEETING_MINUTES_SIGNING_PENDING:Your signature is pending until tomorrow <b>${dueDate}</b>.`,
      [NotificationType.MEETING_MINUTES_SIGNING_REQUEST]: $localize`:notification|@@MEETING_MINUTES_SIGNING_REQUEST:<b>${triggeredByName}</b> asks you to sign until <b>${dueDate}</b>.`,
      [NotificationType.TODO_DUE_SOON]: $localize`:notification|@@TODO_DUE_SOON:Your todo is pending until <b>${dueDate}</b>`,
      [NotificationType.PUBLISH_MEETING_FINAL]: $localize`:notification|@@PUBLISH_MEETING_FINAL:<b>${triggeredByName}</b> published the final version of the meeting minutes.`,
      [NotificationType.PUBLISH_MEETING_MINUTES]: $localize`:notification|@@PUBLISH_MEETING_MINUTES:<b>${triggeredByName}</b> published meeting minutes.`,
      [NotificationType.PUBLISH_MEETING]: $localize`:notification|@@PUBLISH_MEETING:<b>${triggeredByName}</b> published meeting agenda.`,
      [NotificationType.RACI_DAY_BEFORE_REMINDER]: $localize`:notification|@@RACI_DAY_BEFORE_REMINDER:A report is due tomorrow.`,
      [NotificationType.RACI_DECISION_IMPLEMENTED]: $localize`:notification|@@RACI_DECISION_IMPLEMENTED:The decision is implemented.`,
      [NotificationType.RACI_REPORT_COMMENT]: $localize`:notification|@@RACI_REPORT_COMMENT:${triggeredByName} commented on a decision report`,
      [NotificationType.RACI_REPORT_OVERDUE_REMINDER]: $localize`:notification|@@RACI_REPORT_OVERDUE_REMINDER:A report is overdue.`,
      [NotificationType.RACI_REPORT_SHARED]: $localize`:notification|@@RACI_REPORT_SHARED:<b>${triggeredByName}</b> shared a report with you.`,
      [NotificationType.RACI_RESPONSIBLE_FINALIZE]: $localize`:notification|@@RACI_RESPONSIBLE_FINALIZE:You are responsible for a new decision.`,
      [NotificationType.RACI_WEEK_BEFORE_REMINDER]: $localize`:notification|@@RACI_WEEK_BEFORE_REMINDER:A report is due soon.`,
      [NotificationType.SHARE_MEETING_AGENDA]: $localize`:notification|@@SHARE_MEETING_AGENDA:<b>${triggeredByName}</b> shared a meeting agenda with you.`,
      [NotificationType.SHARE_MEETING_MINUTES]: $localize`:notification|@@SHARE_MEETING_MINUTES:<b>${triggeredByName}</b> shared meeting minutes with you.`,
      [NotificationType.MEETING_REQUEST_DOCUMENTS]: $localize`:notification|@@MEETING_REQUEST_DOCUMENTS:<b>${triggeredByName}</b> asks you to upload documents for an agenda item.`,
      [NotificationType.MEETING_UPLOADED_REQUESTED_DOCUMENTS]: $localize`:notification|@@MEETING_UPLOADED_REQUESTED_DOCUMENTS:<b>${triggeredByName}</b> has uploaded documents for the agenda.`,
    };
    return notificationTranslationsMap[notification.type];
  }
}
