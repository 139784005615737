import { SharedCircularResolutionModel } from './circular-resolution.model';
import { MeetingDecisionReportsData, SharedMeetingDecisionModel } from './meetingDecision.model';
import { GroupModelEssentials } from './group.model';
import { AlgoliaACL, BaseModelData } from './base.model';

export enum AlgoliaDecisionType {
  MEETING_DECISION = 'meeting_decision',
  CIRCULAR_RESOLUTION = 'circular_resolution',

}

export interface AlgoliaDecisionModel<DateType = Date> extends BaseModelData<number> {
  /**
   * defines the ACL for this decision and which users do have access to the decision
   * we need this in order to support client-specific Algolia tokens
   */
  acl: AlgoliaACL;

  /**
   * keep a reference to the group this agenda item is part of
   */
  group: GroupModelEssentials;

  /**
   * algolia decision type (meeting decision or circular resolution)
   */
  type: AlgoliaDecisionType;
}

export interface AlgoliaCircularResolutionModel extends SharedCircularResolutionModel<number>, AlgoliaDecisionModel<number> {
  /**
   * decided at date
   */
   decidedAt?: number;
}

export interface AlgoliaMeetingDecisionModel extends SharedMeetingDecisionModel<number>, AlgoliaDecisionModel<number> {
  agendaItemTitle: string;

  /**
   *  whether or not the meeting is final
   */
  meetingIsFinal: boolean;

  meetingTitle: string;

  meetingStartDate: number;

  /**
   * ids for filtering in the decision reports view
   */
  raciReportToIds?: string[];

  /**
   * reports data
   */
  reportData?: MeetingDecisionReportsData<number>;
}
