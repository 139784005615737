import { BaseModel, BaseModelData, FirestoreTimestamp } from './base.model';
import { UserReference } from './user.model';

export enum ActivityLogEntryType {
  CREATE = 'create',
  PUBLISH_AGENDA = 'publish_agenda',
  PUBLISH_MINUTES = 'publish_minutes',
  START_REVIEW = 'start_review',
  START_SIGNING = 'start_signing',
  FINALIZE = 'finalize',
  SUBMIT_REVIEW = 'submit_review',
  ACCEPT_MINUTES = 'accept_minutes',
  SIGN_MINUTES = 'sign_minutes',
  UPLOAD_DOCUMENT = 'upload_document',
}

export interface SharedActivityLogEntryModel<DateType = Date> extends BaseModelData<DateType> {
  /**
   * activity log entry id
   */
  id: string;
  /**
   * activity log type
   */
  type: ActivityLogEntryType;
  /**
   * activity log triggered by
   */
  triggeredBy: UserReference;
  /**
   * reference link
   */
  urlPath?: string;

  /**
   * agendaItem title if available
   */
  agendaItem?: string;
}

export interface FirestoreActivityLogEntryModel<DateType = FirestoreTimestamp>
  extends SharedActivityLogEntryModel<FirestoreTimestamp> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ActivityLogEntryModel extends SharedActivityLogEntryModel, BaseModel<FirestoreActivityLogEntryModel> {}
