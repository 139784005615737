import { map, mapValues, merge, omit } from 'lodash';

import { OrganizationReference } from '..';
import {
  FirestoreMeetingNoteModel,
  MeetingNoteItem,
  MeetingNoteModel,
  NotesDict,
} from '../interfaces/meetingNote.model';
import { UserReference } from '../interfaces/user.model';

import { Base } from './base.model';

export class MeetingNote extends Base<MeetingNoteModel, FirestoreMeetingNoteModel> implements MeetingNoteModel {
  meetingId: string;
  owner: UserReference;
  organization: OrganizationReference;

  agendaNotes: NotesDict;
  participantsNote: MeetingNoteItem;
  documentNotes: NotesDict;

  constructor(data: Partial<MeetingNoteModel>) {
    super();
    merge(this, data);
  }

  validate() {
    // TODO: implement proper validation
    return true;
  }

  static fromFirestoreData(data: Partial<FirestoreMeetingNoteModel>) {
    const convertFirestoreNoteItem = (an: any): MeetingNoteItem => {
      if (an) {
        (an as MeetingNoteItem).updatedAt = an?.updatedAt?.toDate();
      }
      return an;
    };
    return new MeetingNote({
      ...omit(data, ['createdAt', 'updatedAt', 'agendaNotes', 'participantsNote']),
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
      agendaNotes: mapValues(data.agendaNotes, convertFirestoreNoteItem),
      participantsNote: convertFirestoreNoteItem(data.participantsNote),
      documentNotes: mapValues(data.documentNotes, convertFirestoreNoteItem),
    });
  }

  toFirestore() {
    // TODO: fix typing
    return {
      ...(this as any),
    } as FirestoreMeetingNoteModel;
  }
}
