import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorPagesComponent } from './error-pages/error-pages.component';
import { InternalServerErrorComponent } from './internal-server-error/internal-server-error.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PermissionDeniedComponent } from './permission-denied/permission-denied.component';

const routes: Routes = [
  {
    path: '',
    component: ErrorPagesComponent,
    children: [
      {
        path: '',
        redirectTo: 'not-found',
        pathMatch: 'full',
      },
      {
        path: 'not-found',
        component: NotFoundComponent,
      },
      {
        path: 'permission-denied',
        component: PermissionDeniedComponent,
      },
      {
        path: 'internal-error',
        component: InternalServerErrorComponent,
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ErrorHandlingRoutingModule {}
