import { merge, pick } from 'lodash';

import { FirestoreGroupModel, GroupModel, GroupModelEssentials } from '../interfaces/group.model';

import { Base } from './base.model';

export class Group extends Base<GroupModel> implements GroupModel {
  public static ABBREVIATION_REGEX = new RegExp(/^[a-zA-Z0-9üÜäÄöÖ]{0,4}$/);
  name: string;
  organization: string;
  hidden: boolean;
  memberCount: number;
  votingPercentage: number;
  adminIds: string[];
  unit: { id: string; name: string };
  abbreviation?: string;

  get displayName() {
    return this.abbreviation || this.name;
  }

  private constructor(group: Partial<GroupModel>) {
    super();
    merge(this, { ...group, abbreviation: group.abbreviation ?? '' });
  }

  static fromFirestoreData(obj: Partial<FirestoreGroupModel>): Group {
    return new Group(obj);
  }

  getGroupRef() {
    return pick(this, ['id', 'name', 'abbreviation']) as GroupModelEssentials;
  }

  validate(): boolean {
    if (!(this.name && this.name.length > 0)) {
      return false;
    }
    if (!this.votingPercentage) {
      return false;
    }

    if (!Group.ABBREVIATION_REGEX.test(this.abbreviation)) {
      return false;
    }
    return true;
  }
  toFirestore(): FirestoreGroupModel {
    return { ...this };
  }
}
