import { Injectable } from '@angular/core';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { UploadMetadata } from '@angular/fire/compat/storage/interfaces';
import { EMPTY, from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { trace } from '@angular/fire/compat/performance';

import { environment } from '../../../environments/environment';

export interface FilesUploadMetadata {
  uploadProgress$: Observable<number>;
  downloadUrl$: Observable<string>;
  filePath: string;
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(
    private storage: AngularFireStorage,
    private performance: AngularFirePerformance
  ) {}

  uploadFileAndGetMetadata(
    mediaFolderPath: string,
    fileToUpload: File,
    metadata: UploadMetadata = null,
  ): FilesUploadMetadata {

    const filePath = `${mediaFolderPath}/${new Date().getTime()}_${fileToUpload.name}`;
    const uploadTask: AngularFireUploadTask = this.storage.upload(filePath, fileToUpload, metadata);
    const downloadUrl$ = this.getDownloadUrl$(uploadTask, filePath);
    // TODO: switch trace to other method when removing download url generation
    downloadUrl$.pipe(trace('uploadFile')).subscribe();
    return {
      uploadProgress$: uploadTask.percentageChanges().pipe(catchError(() => EMPTY)),
      downloadUrl$,
      filePath,
    };
  }

  private getDownloadUrl$(uploadTask: AngularFireUploadTask, path: string): Observable<string> {
    return from(uploadTask).pipe(
      switchMap(() => {
        return this.storage.ref(path).getDownloadURL();
      }),
      catchError((err) => {
        console.error('Failed uploading attachment:', err);
        return throwError(err);
      }),
    );
  }

  deletePath(path: string) {
    /**
     * delete files under a given path
     */
    return this.storage.ref(path).delete();
  }

  markPathAsDeleted(path: string) {
    const metaData = {
      customMetadata: {
        deleted: 'true',
        deletedAt: new Date().toUTCString(),
      },
    };

    if (path.startsWith('http')) {
      return this.storage.refFromURL(path).updateMetadata(metaData);
    } else {
      return this.storage.ref(path).updateMetadata(metaData);
    }
  }
}
