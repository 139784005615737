import { isUndefined, merge, omit, pickBy } from 'lodash';

import {
  FirestoreMeetingDecisionReportModel,
  MeetingDecisionReportModel,
  MeetingDecisionReportState,
} from '../interfaces/meetingDecisionReport.model';

import { AttachmentModel, AttachmentType } from './../interfaces/attachment.model';
import { ACL } from './../interfaces/base.model';
import { MeetingDecisionReportProgress } from './../interfaces/meetingDecisionReport.model';
import { UserModelEssentials } from './../interfaces/user.model';
import { Base } from './base.model';

export class MeetingDecisionReport
  extends Base<MeetingDecisionReportModel, FirestoreMeetingDecisionReportModel>
  implements MeetingDecisionReportModel
{
  acl: ACL;
  organization: string;
  decisionId: string;
  agendaItemId: string;
  meetingId: string;
  description: string;
  state: MeetingDecisionReportState;
  stateDescription?: string;
  progress: MeetingDecisionReportProgress;
  attachments: AttachmentModel[] = [];
  createdBy: UserModelEssentials;
  commentCount = 0;
  impersonatedBy?: UserModelEssentials;

  get trend() {
    return (this.progress?.value || 0) - (this.progress?.previousValue || 0);
  }

  constructor(data: Partial<MeetingDecisionReportModel>) {
    super();
    merge(this, data);
  }

  addAttachment(attachment: AttachmentModel) {
    this.attachments.push(attachment);
  }

  removeAttachment(attachment: AttachmentModel) {
    const index =
      attachment.type === AttachmentType.FILE
        ? this.attachments.findIndex((a) => a.filePath === attachment.filePath)
        : this.attachments.findIndex((a) => a.url === attachment.url);
    this.attachments.splice(index, 1);
  }

  updateAttachmentLabel(attachment: AttachmentModel) {
    const index =
      attachment.type === AttachmentType.FILE
        ? this.attachments.findIndex((a) => a.filePath === attachment.filePath)
        : this.attachments.findIndex((a) => a.url === attachment.url);
    this.attachments[index] = attachment;
  }

  validate() {
    // TODO: implement proper validation
    return true;
  }

  static fromFirestoreData(data: Partial<FirestoreMeetingDecisionReportModel>) {
    return new MeetingDecisionReport({
      ...omit(data, ['createdAt', 'updatedAt']),
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
      attachments: data.attachments
        ? data.attachments.map((ref) => {
            return {
              ...ref,
              createdAt: ref.createdAt?.toDate(),
            };
          })
        : [],
    });
  }

  toFirestore() {
    // use pickBy to actually delete unset private properties from for firestore encoded object
    return pickBy(
      {
        ...this,
        ...this.encodePrivateProperties(),
      },
      (v) => !isUndefined(v),
    ) as unknown as FirestoreMeetingDecisionReportModel;
  }

  private encodePrivateProperties() {
    return Object.assign(
      {},
      ...Object.keys(this)
        .filter((p) => p.startsWith('_'))
        .map((p) => ({
          // unset private property from export
          [p]: undefined,

          [p.substr(1)]: this[p],
        })),
    );
  }
}
