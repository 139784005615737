import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

declare const updateHubspotTrackingUser: any;
declare const disableHubpsotTracking: any;
// declare const cookieTracking: any;
declare const enableHubspotTracking: any;
declare const removeHubspotIdentityCookie: any;

export enum acceptance {
  ACCEPTED = 'accepted',
  NOT_ACCEPTED = 'not_accepted',
}

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  get allCookiesAccepted() {
    return (
      Object.keys({ ...localStorage }).filter(
        (key) =>
          key.endsWith('Cookies') &&
          (!localStorage.getItem(key) || localStorage.getItem(key) == acceptance.NOT_ACCEPTED),
      ).length == 0
    );
  }

  get performanceCookiesAccepted() {
    const performanceCookies = localStorage.getItem('performanceCookies');
    if (performanceCookies) {
      return performanceCookies === acceptance.ACCEPTED;
    }
    return false;
  }

  get marketingCookiesAccepted() {
    const marketingCookies = localStorage.getItem('marketingCookies');
    if (marketingCookies) {
      return marketingCookies === acceptance.ACCEPTED;
    }
    return false;
  }

  constructor(private analytics: AngularFireAnalytics) {}

  async logEvent(event: string, data: any) {
    await this.analytics.logEvent(event, data);
  }

  async setUserId(userId: string) {
    await this.analytics.setUserId(userId);
  }

  async setUserProperties(data: string[]) {
    await this.analytics.setUserProperties(data);
  }

  async setHubspotParams(email: string, userId: string, userName: string) {
    if (localStorage.getItem('marketingCookies') === acceptance.ACCEPTED) {
      updateHubspotTrackingUser(email, userId, userName);
    } else {
      disableHubpsotTracking();
    }
  }

  async setAnalyticsCollectionEnabled(value: boolean) {
    await this.analytics.setAnalyticsCollectionEnabled(value).then(() => {
      localStorage.setItem('performanceCookies', value ? acceptance.ACCEPTED : acceptance.NOT_ACCEPTED);
    });
  }

  removeHubspotIdentityCookie() {
    removeHubspotIdentityCookie();
  }

  setHubspotTracking(value: boolean) {
    const storageValue = value ? acceptance.ACCEPTED : acceptance.NOT_ACCEPTED;
    localStorage.setItem('marketingCookies', storageValue);
    if (storageValue === acceptance.ACCEPTED) {
      enableHubspotTracking();
    } else {
      disableHubpsotTracking();
    }
  }

  moveUpHubspotChatButton(moveUp = false) {
    const body = document.getElementById('hubspot-body');
    if (moveUp) {
      body?.classList?.add('hubspot-move-up');
    } else {
      body?.classList?.remove('hubspot-move-up');
    }
  }

  displayHubspotChatButton(display = true) {
    const body = document.getElementById('hubspot-body');
    if (!display) {
      body.classList.add('hubspot-hide');
    } else {
      body.classList.remove('hubspot-hide');
    }
  }
}
