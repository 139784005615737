<div fxLayout="row">
  <div class="p-20" fxFlex="66" fxFlexOffset="15" fxFlexOffset.lt-sm="0" fxFlex.lt-sm="100">
    <div class="error-container">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="error-code m-b-20">
        <mat-icon class="wd-red">report_problem</mat-icon>
        <div class="wd-red">500</div>
      </div>
      <h1 i18n="error_handling|Error; server error@@error_handling_error-server-error">Server error</h1>
      <div class="error-description">
        <ng-container i18n="error_handling|Message; error due to technical problems@@error_handling_message_technical-problems">
          Sorry, we had some technical problems during your last operation.
        </ng-container><br>
        <small class="light-text"
          i18n="error_handling|Message; try later or contact support@@error_handling_message-try-later-or-contact-support">
          Please try again later or feel free to contact us if the problem persists.
      </small>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
</div>
