import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountType, Organization, OrganizationModel } from '@wedecide/models';
import { DateTime } from 'luxon';

import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base.component';

import { TrialEndedComponent } from '../dialogs/trial-ended.component';
import { DialogService } from '../modules/dialog/dialog.service';
import { OrganizationService } from '../services/organization.service';
import { UserAvatarService } from '../services/userAvatar.service';

import { AuthService, AuthUser } from './../services/auth.service';
import { ThemeService } from './../services/theme.service';

@Component({
  selector: 'wd-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss'],
})
export class FullLayoutComponent extends BaseComponent implements OnInit, OnDestroy {
  user: AuthUser;
  isAdmin = false;
  hasManageAccess = false;

  get darkTheme() {
    return this.themeService.isDarkThemeEnabled || false;
  }

  get userProfileImage() {
    return (
      this.user?.photoURL ||
      this.userAvatarService.getDefaultAvatar(
        this.user?.displayName || $localize`:general|anonymous@@general_anonymous:Anonymous`,
      )
    );
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private themeService: ThemeService,
    private userAvatarService: UserAvatarService,
    private organizationService: OrganizationService,
    private dialogService: DialogService,
  ) {
    super();
  }

  ngOnInit() {
    this.authService.user$.pipe(takeUntil(this._destroy$)).subscribe(async (user) => {
      this.user = user;
      this.isAdmin = user ? this.authService.isAdmin() : false;
      this.hasManageAccess = user ? this.authService.isMoreThanUser() : false;
    });

    this.organizationService.currentOrganization$.pipe(takeUntil(this._destroy$)).subscribe((organization) => {
      if (!this.isValidOrganization(organization)) {
        if (organization.account?.type === AccountType.TRIAL) {
          this.dialogService.openStandardDialog(TrialEndedComponent, {
            width: '90%',
            maxWidth: '500px',
            height: '250px',
            disableClose: true,
            data: { organization },
          });
        } else {
          // TODO: show a dialog to contact sales to upgrade/pay the account
          // user's of paid organization that have expired should be logged-out for
          this.router.navigateByUrl('/logout');
        }
      }
    });
  }

  private isValidOrganization(organization: Organization) {
    return organization.account?.validUntil
      ? DateTime.utc() <= DateTime.fromJSDate(organization.account?.validUntil)
      : false;
  }

  logout() {
    this.router.navigateByUrl('/logout');
  }
}
