import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';

import { AppErrorOverlayComponent } from './app-error-overlay/app-error-overlay.component';
import { ErrorHandlingRoutingModule } from './error-handling-routing.module';
import { ErrorPagesComponent } from './error-pages/error-pages.component';
import { InternalServerErrorComponent } from './internal-server-error/internal-server-error.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PermissionDeniedComponent } from './permission-denied/permission-denied.component';

@NgModule({
  declarations: [
    ErrorPagesComponent,
    NotFoundComponent,
    PermissionDeniedComponent,
    AppErrorOverlayComponent,
    InternalServerErrorComponent,
  ],
  imports: [
    CommonModule,
    ErrorHandlingRoutingModule,
    FlexLayoutModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
  ],
  exports: [AppErrorOverlayComponent],
})
export class ErrorHandlingModule {}
