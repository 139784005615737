import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'wd-privacy-policy-settings-dialog',
  templateUrl: 'privacy-settings-dialog.component.html',
  styleUrls: ['privacy-settings-dialog.component.scss'],
})
export class PrivacySettingsDialog {
  constructor(public dialog: MatDialog) {}
  close() {
    this.dialog.closeAll();
  }
}
