import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  private onlineSubject$ = new Subject<boolean>();
  online$ = this.onlineSubject$.asObservable();

  constructor() {
    this.onlineSubject$.next(window.navigator.onLine);
    window.addEventListener('online', () => this.updateOnlineStatus(true));
    window.addEventListener('offline', () => this.updateOnlineStatus(false));
  }

  private updateOnlineStatus(status) {
    this.onlineSubject$.next(status);
  }
}
