import { NgModule } from '@angular/core';
import { canActivate } from '@angular/fire/compat/auth-guard';
import { Routes, RouterModule } from '@angular/router';

import { AuthActionComponent } from './auth/action/auth-action.component';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { LoginComponent } from './auth/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { SwitchUserComponent } from './auth/switch-user/switch-user.component';
import { ImprintComponent } from './imprint/imprint/imprint.component';
import { PrivacyPolicyComponent } from './privacy/privacy-policy/privacy-policy.component';
import { PrivacySettings } from './privacy/privacy-settings/privacy-settings.component';
import { hasUserRole, redirectLoggedInToHome } from './shared/guards/auth.guard';
import { SettingsGuard } from './shared/guards/settings.guard';
import { FullLayoutComponent } from './shared/layout/full-layout.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    component: FullLayoutComponent,
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    ...canActivate(hasUserRole),
  },
  {
    path: 'search',
    component: FullLayoutComponent,
    loadChildren: () => import('./search/search.module').then((m) => m.SearchModule),
    ...canActivate(hasUserRole),
    // pathMatch: 'full',
  },
  {
    path: 'meetings',
    component: FullLayoutComponent,
    loadChildren: () => import('./meeting-manager/meeting-manager.module').then((m) => m.MeetingManagerModule),
    ...canActivate(hasUserRole),
  },
  {
    path: 'cr',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./circular-resolutions/circular-resolutions.module').then((m) => m.CircularResolutionsModule),
    ...canActivate(hasUserRole),
  },
  {
    path: 'todos',
    component: FullLayoutComponent,
    loadChildren: () => import('./todos/todos.module').then((m) => m.TodosModule),
    ...canActivate(hasUserRole),
  },
  {
    path: 'decisions',
    component: FullLayoutComponent,
    loadChildren: () => import('./decisions/decisions.module').then((m) => m.DecisionsModule),
    ...canActivate(hasUserRole),
  },
  {
    path: 'boards',
    component: FullLayoutComponent,
    loadChildren: () => import('./boards/boards.module').then((m) => m.BoardsModule),
    ...canActivate(hasUserRole),
  },
  {
    path: 'settings',
    component: FullLayoutComponent,
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
    ...canActivate(hasUserRole),
    canActivate: [SettingsGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    ...canActivate(redirectLoggedInToHome),
  },
  {
    path: 'auth/forget-password',
    component: ForgetPasswordComponent,
  },
  {
    path: 'auth/action',
    component: AuthActionComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'switch-user',
    component: SwitchUserComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'privacy-settings',
    component: PrivacySettings,
  },
  {
    path: 'imprint',
    component: ImprintComponent,
  },
  {
    path: 'error',
    component: FullLayoutComponent,
    loadChildren: () => import('./error-handling/error-handling.module').then((m) => m.ErrorHandlingModule),
  },
  { path: '**', redirectTo: 'error/not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      enableTracing: false,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
