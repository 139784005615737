import { isString, isUndefined, merge, omit, pickBy, uniq } from 'lodash';

import { AgendaItemModel, AgendaTodoItem } from '../interfaces/agendaItem.model';
import { MeetingSnapshotModel, FirestoreMeetingSnapshotModel } from '../interfaces/meetingSnapshot.model';

import { AttachmentModel } from './../interfaces/attachment.model';
import { MeetingModel, MeetingSigningState } from './../interfaces/meeting.model';
import { ReviewDict } from './../interfaces/meeting.model';
import { MeetingDecisionModel } from './../interfaces/meetingDecision.model';
import { SignatoriesDict } from './../interfaces/signature.model';
import { UserReference } from './../interfaces/user.model';
import { AgendaItem } from './agendaItem.model';
import { Base } from './base.model';
import { Meeting } from './meeting.model';
import { MeetingDecision } from './meetingDecision.model';

export class MeetingSnapshot
  extends Base<MeetingSnapshotModel, FirestoreMeetingSnapshotModel>
  implements MeetingSnapshotModel {
  private _createdBy: UserReference;
  meeting: Meeting;
  agendaItems: AgendaItem[] = [];
  meetingDecisions: MeetingDecision[] = [];
  organization: string;
  version = 0;
  reviewers: ReviewDict;
  signingState?: MeetingSigningState = MeetingSigningState.NOT_STARTED;
  signatories?: SignatoriesDict;
  isAgendaSnapshot?: boolean;

  get createdBy() {
    return this._createdBy;
  }

  set createdBy(value: UserReference) {
    this._createdBy = value;
  }

  get pendingSignatures() {
    return Object.values(this.signatories).filter((s) => !s?.signature);
  }

  constructor(data: Partial<MeetingSnapshotModel>) {
    super();
    merge(this, data);
  }

  validate() {
    // TODO: implement proper validation
    return true;
  }

  private static convertMeetingFromFirestore(data: string) {
    const temp = JSON.parse(data as string);
    return {
      ...omit(temp, [
        'createdAt',
        'updatedAt',
        'endDate',
        'startDate',
        'todos',
        'lastSnapshotPublishedAt',
        'attachments',
        'reviewers',
      ]),
      createdAt: new Date(temp.createdAt),
      updatedAt: new Date(temp.updatedAt),
      endDate: new Date(temp.endDate),
      startDate: new Date(temp.startDate),
      lastSnapshotPublishedAt: new Date(temp.lastSnapshotPublishedAt),
      attachments: (temp?.attachments || []).map((attachment: AttachmentModel) => {
        return {
          ...omit(attachment, ['createdAt']),
          createdAt: new Date(attachment.createdAt),
        };
      }),
    } as MeetingModel;
  }

  private static convertAgendaItemFromFirestore(agendaItem: string) {
    const temp = JSON.parse(agendaItem);
    return {
      ...omit(temp, ['todos', 'attachments']),
      todos: (temp?.todos || []).map((todo: AgendaTodoItem) => {
        return {
          ...omit(todo, ['dueDate']),
          dueDate: todo?.dueDate ? new Date(todo.dueDate) : '',
        };
      }),
      attachments: (temp?.attachments || []).map((attachment: AttachmentModel) => {
        return {
          ...omit(attachment, ['createdAt']),
          createdAt: new Date(attachment.createdAt),
        };
      }),
    } as AgendaItemModel;
  }

  private static convertMeetingDecisionFromFirestore(meetingDecision: string) {
    const temp = JSON.parse(meetingDecision);
    return {
      ...omit(temp, ['createdAt', 'updatedAt']),
      createdAt: new Date(temp.createdAt),
      updatedAt: new Date(temp.updatedAt),
    } as MeetingDecisionModel;
  }

  static fromFirestoreData(data: Partial<FirestoreMeetingSnapshotModel>) {
    const reviewers = Object.assign(
      {},
      ...Object.keys(data.reviewers || {}).map((k) => {
        return {
          [k]: {
            ...omit(data.reviewers[k], ['reviewedOn', 'dueDate']),
            reviewedOn: data.reviewers[k].reviewedOn?.toDate() || null,
            dueDate: data.reviewers[k].dueDate?.toDate() || null,
          },
        };
      }),
    );

    const signatories = Object.assign(
      {},
      ...Object.keys(data?.signatories || {}).map((k) => {
        return {
          [k]: {
            ...omit(data.signatories[k], 'signedOn', 'dueDate'),
            dueDate: data.signatories[k]?.dueDate?.toDate() || null,
            signature: data.signatories[k]?.signature
              ? {
                  ...omit(data.signatories[k].signature, 'signedOn'),
                  signedOn: data.signatories[k].signature?.signedOn?.toDate() || null,
                }
              : null,
          },
        };
      }),
    );
    return new MeetingSnapshot({
      ...omit(data, ['meeting', 'agendaItems', 'meetingDecisions', 'createdAt', 'updatedAt']),
      meeting: {
        ...this.convertMeetingFromFirestore(data.meeting as string),
        reviewers: reviewers,
      },
      agendaItems: ((data?.agendaItems as string[]) || []).map((item) =>
        this.convertAgendaItemFromFirestore(item as string),
      ),
      meetingDecisions: ((data?.meetingDecisions as any[]) || []).map((item: string | MeetingDecisionModel) =>
        isString(item) ? this.convertMeetingDecisionFromFirestore(item as string) : item,
      ),
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
      reviewers: reviewers,
      signatories: signatories,
    });
  }

  get acls() {
    return this.generateACLs();
  }

  private generateACLs() {
    return {
      read: uniq([this.createdBy?.id, ...Object.keys(this.meeting?.participants || [])]),
      write: uniq([this.createdBy?.id, ...Object.keys(this.meeting?.participants || [])]),
    };
  }

  toFirestore() {
    return pickBy(
      {
        ...this.convertDataToStrings(),
        ...this.encodePrivateProperties(),
        acl: this.generateACLs(),
      },
      (v) => !isUndefined(v),
    ) as FirestoreMeetingSnapshotModel;
  }

  private convertDataToStrings() {
    return {
      ...omit(this, ['meeting', 'agendaItems', 'meetingDecisions']),
      meeting: JSON.stringify(this.meeting),
      agendaItems: this.agendaItems?.map((item) => JSON.stringify(item)),
      meetingDecisions: this.meetingDecisions?.map((item) => JSON.stringify(item)),
    };
  }

  private encodePrivateProperties() {
    return Object.assign(
      {},
      ...Object.keys(this)
        .filter((p) => p.startsWith('_'))
        .map((p) => ({
          // unset private property from export
          [p]: undefined,

          [p.substr(1)]: this[p],
        })),
    );
  }
}
