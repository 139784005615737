export type ID = string;

export interface ACL {
  read: ID[];
  write: ID[];
}

export interface AlgoliaACL extends ACL {
  groupRead: ID[];
}

/**
 * allowed statuses of a document
 */
export enum DocumentStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  DELETED = 'deleted',
}

export type FirestoreTimestamp = {
  toDate(): Date;
};

export interface BaseModelData<DateType = Date> {
  /**
   * identifier
   */
  id: ID;

  /**
   * date the document was created
   */
  createdAt?: DateType;

  /**
   * date the document was last updated
   */
  updatedAt?: DateType;

  // /**
  //  * status of document - used to flag disabled or deleted documents as such
  //  */
  status?: DocumentStatus;
}

export interface BaseModel<FT, DateType = Date> extends BaseModelData<DateType> {
  validate(): boolean;
  toFirestore(): FT;
}

// TODO: figure out a way to make this work ;)
// export type FirestoreModel<T, FT = T> = {
//   [P in keyof T]: T[P];
// } & {
//   toFirestore: () => FT;
// };
