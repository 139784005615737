import { Injectable } from '@angular/core';
import {
  CircularResolutionState,
  MeetingAgendaState,
  MeetingDecisionReportState,
  MeetingMinutesState,
  VotingResult,
} from '@wedecide/models';

export enum ConfirmButtonColor {
  ACCENT = 'accent',
  PRIMARY = 'primary',
  WARN = 'warn',
}

export enum WdColors {
  PRIMARY = '#35b9c6',
  ACCENT = '#2e7d9e',
  WARN = '#f2506b',
  GREEN = '#35c69b',
  BLUE = '#41A3E4',
  RED = '#f2506b',
  YELLOW = '#FFA000',
  GRAY = '#62747f',
  LIGHT_GRAY = '#D0D6D9',
  ORANGE = '#e67e22',
}

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  public getColorCodesForCircularResolution(state: string) {
    switch (state) {
      case CircularResolutionState.IN_PROGRESS:
        return { color: 'wd-green', bg: 'bg-wd-green-20' };
      case CircularResolutionState.CANCELLED:
        return { color: 'wd-red', bg: 'bg-wd-red-20' };
      case CircularResolutionState.FINAL:
        return { color: 'wd-primary', bg: 'bg-wd-primary-20' };
      default:
        return { color: '', bg: '' };
    }
  }

  public getColorCodesForVotingResult(state: VotingResult) {
    switch (state) {
      case VotingResult.YES:
        return { color: 'wd-green', bg: 'bg-wd-green-20' };
      case VotingResult.NO:
        return { color: 'wd-red', bg: 'bg-wd-red-20' };
      case VotingResult.ABSTAIN:
        return { color: 'wd-gray', bg: 'bg-wd-gray-20' };
      default:
        return { color: '', bg: '' };
    }
  }

  getMeetingDecisionReportStateColor(value: MeetingDecisionReportState) {
    switch (value) {
      case MeetingDecisionReportState.ON_TRACK:
        return 'wd-green';
      case MeetingDecisionReportState.OFF_TRACK:
        return 'wd-red';
      case MeetingDecisionReportState.NEEDS_ATTENTION:
        return 'wd-yellow';
      case MeetingDecisionReportState.IMPLEMENTED:
        return 'wd-green';
      default:
        return '';
    }
  }

  getMeetingAgendaStateColor(agendaState: MeetingAgendaState) {
    switch (agendaState) {
      case MeetingAgendaState.PUBLISHED: {
        return 'wd-green';
      }
      case MeetingAgendaState.REPUBLISH: {
        return 'wd-orange';
      }
      default: {
        return '';
      }
    }
  }

  getMeetingMinutesStateColor(minutesState: MeetingMinutesState) {
    switch (minutesState) {
      case MeetingMinutesState.PUBLISHED: {
        return 'wd-green';
      }
      case MeetingMinutesState.IN_REVIEW: {
        return 'wd-yellow';
      }
      case MeetingMinutesState.REVIEWED: {
        return 'wd-primary';
      }
      case MeetingMinutesState.FINAL: {
        return 'wd-primary';
      }
      default: {
        return '';
      }
    }
  }
}
