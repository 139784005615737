import { ID } from '@wedecide/models';
import { UnitModel } from '..';
import { AttachmentModel } from './attachment.model';
import { BaseModel, BaseModelData, FirestoreTimestamp } from './base.model';
import { ACL } from './base.model';
import { GroupModelEssentials } from './group.model';
import { SignatoriesDict } from './signature.model';
import { UserReference } from './user.model';

export enum MeetingSigningState {
  NOT_STARTED = 'not_started',
  NOT_SIGNED = 'not_signed',
  PARTIALLY_SIGNED = 'partially_signed',
  FULLY_SIGNED = 'fully_signed',
}

export enum MeetingMinutesState {
  NOT_STARTED = 'not_started',
  IS_DRAFT = 'draft',
  PUBLISHED = 'published',
  IN_REVIEW = 'in_review',
  REVIEWED = 'reviewed',
  FINAL = 'final',
}

export enum MeetingAgendaState {
  IS_DRAFT = 'is_draft',
  PUBLISHED = 'published',
  REPUBLISH = 'republish', // need to be republished
}

export interface MeetingModelEssentials<DateType = Date> {
  /**
   * meeting id
   */
  id: ID;

  /**
   * meeting name
   */
  title: string;

  /**
   * meeting start date
   */
  startDate?: DateType;

  /**
   * meeting end date
   */
  endDate?: DateType;

  /**
   * meeting organization
   */
  organization: string;

  /**
   * meeting group
   */
  group?: GroupModelEssentials;
}

interface SharedMeetingModel<DateType = Date> extends BaseModelData<DateType> {
  /**
   * title of the meeting
   * is required
   */
  title: string;

  /**
   * meeting organization
   */
  organization: string;

  group: GroupModelEssentials;

  /**
   *
   */
  agendaState: MeetingAgendaState;

  /**
   * meeting minutes state
   */
  minutesState: MeetingMinutesState;

  /**
   * start date of the meeting
   * is required
   */
  startDate: DateType;

  /**
   * end date of the meeting
   * is required
   */
  endDate: DateType;

  /**
   * meeting location
   */
  location: string;

  /**
   * meeting link
   */
  link: string;

  /**
   * meeting creator
   */
  createdBy: UserReference;

  /**
   * minutes taker
   */
  minutesTaker: UserReference;

  /**
   * board chairman
   */
  boardChairman?: UserReference;

  /**
   * list of meeting participants and their attendance status
   */
  participants: ParticipantsDict;

  /**
   * holds the attendance for participants
   * (guest participance is recorded inside the agenda items)
   */
  attendance: AttendanceDict;

  /**
   * a list of linked attachments (`documents` in customer-facing views)
   * (embedded subcollection)
   */
  attachments?: MeetingAttachmentModel<DateType>[];
  reviewers: ReviewDict<DateType>;

  /**
   * store version of last snapshot
   */
  lastSnapshotVersion?: number;

  /**
   * store last publish date
   */
  lastSnapshotPublishedAt?: DateType;

  /**
   * users with special view access to the meeting
   * (share, deciders that left group)
   */
  viewers: ParticipantsDict;

  /**
   * meeting signing state
   */
  signingState?: MeetingSigningState;

  /**
   * meeting signatures
   */
  signatories?: SignatoriesDict<DateType>;
}

export enum MeetingReviewType {
  INAPP = 'inapp',
  MAIL = 'mail',
}

export interface MeetingMinutesReview<DateType = Date> {
  changeRequest: boolean;
  type: MeetingReviewType;
  createdAt: DateType;
  comment?: string;
}

export type ParticipantsDict = { [uid: string]: UserReference };

export enum AttendanceStatus {
  ATTENDING = 'attending',
  ABSENT = 'absent',
  // TODO - legacy states
  EXCUSED = 'excused',
  INVITED = '-',
}

export interface Attendance {
  id: string;
  name: string;
  attendance: AttendanceStatus;
  notes?: string;
  unit?: UnitModel;
}

export type AttendanceDict = {
  [uid: string]: Attendance;
};

export enum MeetingAttachmentStatus {
  CONVERTABLE_TO_PRESENTATION = 'convertable_to_presentation',
  WAITING_FOR_RENDERING = 'waiting_for_rendering',
  RENDERING = 'rendering',
  READY = 'ready',
  READY_FOR_PRESENTATION = 'ready_for_presentation',
  ERROR = 'error',
}

export function isMeetingAttachment(
  attachment: AttachmentModel | MeetingAttachmentModel,
): attachment is MeetingAttachmentModel {
  return (attachment as MeetingAttachmentModel).status !== undefined;
}

export interface MeetingAttachmentModel<DateType = Date> extends AttachmentModel<DateType> {
  id?: string;
  renderedImages?: string[];
  status?: MeetingAttachmentStatus;
}
export enum ReviewStatus {
  PENDING,
  FEEDBACK_EMAIL,
  FEEDBACK_NOTE,
  OK,
}

export interface Review<DateType = Date> {
  id: string;
  name: string;
  reviewStatus: ReviewStatus;
  reviewedOn?: DateType;
  dueDate?: DateType;
}

export type ReviewDict<DateType = Date> = {
  [uid: string]: Review<DateType>;
};

export type MeetingCustomMetadata = { [key: string]: string };

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MeetingModel extends SharedMeetingModel, BaseModel<FirestoreMeetingModel> {}

export interface FirestoreMeetingModel extends SharedMeetingModel<FirestoreTimestamp> {
  /**
   * defines the ACL for this decision and which users do have access to the decision
   */
  acl: ACL;
}
