<router-outlet></router-outlet>
<div class="cookies-optin" fxLayout="row" fxLayoutAlign="center center" *ngIf="showCookieBanner">
  <div fxFlex="60" fxFlex.lt-lg="100">
    <div class="m-b-10" i18n="cookies optin|Overlay title@@cookies-optin_title">We value your privacy</div>
    <div>
      <small i18n="cookies optin|Explaination what kind of data we collect@@cookies-optin_content">We use technologies, such as cookies, to measure the performance content and derive insights about the audiences who saw content.
        We also process your personal data in order to provide our service within the application.
        Furthermore, we process your data for marketing purposes and to improve our application.
        Click below to consent to the use of this technology and the processing of your personal data for these purposes. You can change your consent choices at our settings page. If you want to know more about which cookies we set and what they do, you can go to the "Cookies" section of our <a href="/privacy-policy">privacy policy</a>.
      </small>
      <div>
        <div fxLayout="row" fxLayoutAlign="center center" class="m-t-15">
          <button mat-flat-button (click)="confirmCookies()" i18n="cookies optin|Accept the cookies@@cookies-optin_accept">Accept</button>
          <button mat-button (click)="openPrivacySettingsDialog()" i18n="cookies optin|Configure the allowed cookies@@cookies-optin_configure">Settings</button>
        </div>
      </div>
    </div>
  </div>
</div>
