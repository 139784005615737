import { merge, omit } from 'lodash';
import { GroupModelEssentials } from '..';

import { FirestoreNotificationModel, NotificationModel, NotificationType } from '../interfaces/notification.model';
import { UserReference } from '../interfaces/user.model';

import { NotificationRenderer } from '../notifications/notification.renderer';
import { EmailTemplate } from '../notifications/notification.templates';
import { NOTIFICATION_CONFIG } from './../notifications/notification.config';
import { Base } from './base.model';

/**
 * Lists supported notifications and whether or not they are enabled by default.
 */
export const SUPPORTED_NOTIFICATIONS: {
  inapp: Record<NotificationType, boolean>;
  email: Record<NotificationType, boolean>;
} = {
  inapp: {
    [NotificationType.PUBLISH_MEETING]: true,
    [NotificationType.PUBLISH_MEETING_MINUTES]: true,
    [NotificationType.PUBLISH_MEETING_FINAL]: true,
    [NotificationType.SHARE_MEETING_AGENDA]: true,
    [NotificationType.SHARE_MEETING_MINUTES]: true,
    [NotificationType.MEETING_MINUTES_REVIEW_REQUEST]: true,
    [NotificationType.MEETING_MINUTES_REVIEW_COMPLETE]: true,
    [NotificationType.MEETING_MINUTES_SIGNING_REQUEST]: true,
    [NotificationType.MEETING_MINUTES_SIGNING_PENDING]: true,
    [NotificationType.MEETING_MINUTES_SIGNING_COMPLETE]: true,
    [NotificationType.MEETING_MINUTES_SUBMIT_REVIEW]: true,
    [NotificationType.MEETING_MINUTES_ACCEPT_REVIEW]: true,
    [NotificationType.MEETING_REQUEST_DOCUMENTS]: true,
    [NotificationType.MEETING_UPLOADED_REQUESTED_DOCUMENTS]: true,
    [NotificationType.CR_SHARE_CIRCULAR_RESOLUTION]: true,
    [NotificationType.CR_PUBLISH_CIRCULAR_RESOLUTION]: true,
    [NotificationType.CR_CANCEL_CIRCULAR_RESOLUTION]: true,
    [NotificationType.CR_FINALIZE_CIRCULAR_RESOLUTION]: true,
    [NotificationType.CR_VOTING_DUE_SOON]: true,
    [NotificationType.CR_VOTING_FINISHED]: true,
    [NotificationType.CR_APPROVAL_RATE_REACHED]: true,
    [NotificationType.RACI_RESPONSIBLE_FINALIZE]: true,
    [NotificationType.RACI_REPORT_SHARED]: true,
    [NotificationType.RACI_WEEK_BEFORE_REMINDER]: true,
    [NotificationType.RACI_DAY_BEFORE_REMINDER]: true,
    [NotificationType.RACI_REPORT_OVERDUE_REMINDER]: true,
    [NotificationType.RACI_REPORT_COMMENT]: true,
    [NotificationType.RACI_DECISION_IMPLEMENTED]: true,
    [NotificationType.GUEST_PUBLISH_AGENDA]: true,
    [NotificationType.GUEST_FINALIZE_MEETING]: true,
    [NotificationType.MEETING_MINUTES_REVIEW_DUE_SOON]: true,
    [NotificationType.TODO_DUE_SOON]: true,
    [NotificationType.MEETING_AGENDA_FEEDBACK]: true,
    [NotificationType.CONNECT_USER]: true,
    [NotificationType.ADMIN_CONNECTED_USERS]: true,
  },
  email: {
    [NotificationType.PUBLISH_MEETING]: true,
    [NotificationType.PUBLISH_MEETING_MINUTES]: true,
    [NotificationType.PUBLISH_MEETING_FINAL]: true,
    [NotificationType.SHARE_MEETING_AGENDA]: true,
    [NotificationType.SHARE_MEETING_MINUTES]: true,
    [NotificationType.MEETING_MINUTES_REVIEW_REQUEST]: true,
    [NotificationType.MEETING_MINUTES_REVIEW_COMPLETE]: true,
    [NotificationType.MEETING_MINUTES_SIGNING_REQUEST]: true,
    [NotificationType.MEETING_MINUTES_SIGNING_PENDING]: true,
    [NotificationType.MEETING_MINUTES_SIGNING_COMPLETE]: true,
    [NotificationType.MEETING_MINUTES_SUBMIT_REVIEW]: false,
    [NotificationType.MEETING_MINUTES_ACCEPT_REVIEW]: false,
    [NotificationType.MEETING_REQUEST_DOCUMENTS]: true,
    [NotificationType.MEETING_UPLOADED_REQUESTED_DOCUMENTS]: true,
    [NotificationType.CR_SHARE_CIRCULAR_RESOLUTION]: true,
    [NotificationType.CR_PUBLISH_CIRCULAR_RESOLUTION]: true,
    [NotificationType.CR_CANCEL_CIRCULAR_RESOLUTION]: true,
    [NotificationType.CR_FINALIZE_CIRCULAR_RESOLUTION]: true,
    [NotificationType.CR_VOTING_DUE_SOON]: true,
    [NotificationType.CR_VOTING_FINISHED]: true,
    [NotificationType.CR_APPROVAL_RATE_REACHED]: true,
    [NotificationType.RACI_RESPONSIBLE_FINALIZE]: true,
    [NotificationType.RACI_REPORT_SHARED]: true,
    [NotificationType.RACI_WEEK_BEFORE_REMINDER]: true,
    [NotificationType.RACI_DAY_BEFORE_REMINDER]: true,
    [NotificationType.RACI_REPORT_OVERDUE_REMINDER]: true,
    [NotificationType.RACI_REPORT_COMMENT]: true,
    [NotificationType.RACI_DECISION_IMPLEMENTED]: true,
    [NotificationType.GUEST_PUBLISH_AGENDA]: true,
    [NotificationType.GUEST_FINALIZE_MEETING]: true,
    [NotificationType.MEETING_MINUTES_REVIEW_DUE_SOON]: true,
    [NotificationType.TODO_DUE_SOON]: true,
    [NotificationType.MEETING_AGENDA_FEEDBACK]: true,
    [NotificationType.CONNECT_USER]: true,
    [NotificationType.ADMIN_CONNECTED_USERS]: true,
  },
};

export class Notification extends Base<NotificationModel, FirestoreNotificationModel> implements NotificationModel {
  title: string;
  itemId: string;
  type: NotificationType;
  read?: boolean = false;
  dueDate?: Date;
  message?: string;
  triggeredBy?: UserReference;
  urlPath?: string;
  emailPending?: boolean;
  board?: GroupModelEssentials;
  mentionedUsers?: string[];

  get icon() {
    const config = NOTIFICATION_CONFIG[this.type];
    if (config) {
      return {
        icon: config.icon,
      };
    } else {
      return { icon: '' };
    }
  }

  constructor(data: Partial<NotificationModel>) {
    super();
    // Object.assign(this, data);
    merge(this, data);
  }

  markAsRead() {
    this.read = true;
    return this;
  }

  markAsUnread() {
    this.read = false;
    return this;
  }

  validate() {
    // TODO: implement proper validation
    return true;
  }

  static fromFirestoreData(data: Partial<FirestoreNotificationModel>) {
    return new Notification({
      ...omit(data, ['toFirestore', 'validate']),
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
      dueDate: data?.dueDate?.toDate(),
    });
  }

  isMeetingRoute() {
    return [
      NotificationType.PUBLISH_MEETING,
      NotificationType.PUBLISH_MEETING_MINUTES,
      NotificationType.PUBLISH_MEETING_FINAL,
      NotificationType.SHARE_MEETING_AGENDA,
      NotificationType.MEETING_MINUTES_REVIEW_REQUEST,
      NotificationType.MEETING_MINUTES_SIGNING_REQUEST,
    ].includes(this.type);
  }

  toFirestore() {
    return {
      ...(this as any),
    } as FirestoreNotificationModel;
  }

  getEmailTemplateConfig(): EmailTemplate {
    return NOTIFICATION_CONFIG[this.type].templates.email;
  }
}
