import { Component, OnInit } from '@angular/core';
import { AuthService } from '@wedecide/shared/services/auth.service';
import { ErrorService } from '@wedecide/shared/services/error.service';
import { TrackingService } from '@wedecide/shared/services/tracking.service';

@Component({
  selector: 'wd-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  error = false;
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private trackingService: TrackingService,
  ) {}

  ngOnInit(): void {
    this.trackingService.removeHubspotIdentityCookie();
    this.authService.logout().catch((e) => {
      this.error = true;
      this.errorService.showServerErrorSnackbar(
        e,
        $localize`:logout|error; logout failure@@logout_error-snackbar_logout-failure:Could not logout.`,
      );
    });
  }
}
