<div class="spinner-container" [ngStyle]= "{ 'width':width, 'height':height }">
  <svg class="spinner" [attr.width]="width" [attr.height]="height" viewBox="0 0 180 150" xmlns="http://www.w3.org/2000/svg" >
    <g id="Exports" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Artboard" transform="translate(-423.000000, -272.000000)">
          <g id="Icon" transform="translate(423.000000, 272.000000)">
              <polyline id="top" [attr.fill]="colors.top" points="87.5260387 21.9588082 133.482666 75 144 65.2830683 136.992688 57.0929013 87.5260387 0 31 65.2425859 41.5624674 74.9942168 87.5260387 21.9588082"></polyline>
              <polyline id="middle" [attr.fill]="colors.middle" points="88.5323578 58.7402783 132.456939 109 161 82.876167 151.620856 72.0821789 133.614886 88.5766203 88.5323578 37 43.4498294 88.5777654 25.4172803 72.0672922 16 82.8223459 44.6019989 109 88.5323578 58.7402783"></polyline>
              <polygon id="bottom" [attr.fill]="colors.bottom" points="130.429013 145 177 102.187367 167.57404 91.4089432 131.583589 124.498014 88.5109685 75 45.4360384 124.498014 9.36938612 91.3354017 0 102.167832 46.5802235 145 88.5109685 96.8153912"></polygon>
          </g>
      </g>
    </g>
  </svg>
</div>
