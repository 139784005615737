import { RACIData } from './raci.model';
import { pickBy, isUndefined, merge, omit, orderBy } from 'lodash';

import { GroupModelEssentials } from '..';
import { AttachmentModel, AttachmentType } from '../interfaces/attachment.model';
import {
  CircularResolutionModel,
  FirestoreCircularResolutionModel,
  CircularResolutionState,
  CircularResolutionParticipantsDict,
  VotingResult,
} from '../interfaces/circular-resolution.model';
import { ParticipantsDict } from '../interfaces/meeting.model';
import { UserReference } from '../interfaces/user.model';

import { CircularResolutionCustomMetadata } from './../interfaces/circular-resolution.model';
import { SignatoriesDict } from './../interfaces/signature.model';
import { Base, BaseRACI } from './base.model';

export class CircularResolution
  extends BaseRACI<CircularResolutionModel, FirestoreCircularResolutionModel>
  implements CircularResolutionModel {
  private _approvalRate: number;
  title: string;
  proposal: string;
  description?: string;
  organization: string;
  createdBy: UserReference;
  dueDate?: Date;
  state: CircularResolutionState;
  participants: CircularResolutionParticipantsDict = {};
  attachments: AttachmentModel[] = [];
  group: GroupModelEssentials;
  viewers: ParticipantsDict = {};
  allowAbstain = false;
  signatories: SignatoriesDict = {};
  requestSignatureForVoting = false;
  decidedAt: Date;

  constructor(data: Partial<CircularResolutionModel>) {
    super();
    Object.assign(this, data);
    merge(this, data);
    if (data?.raci) {
      this.raci = RACIData.fromFirestoreData(data.raci);
    }
  }

  get participantsList() {
    return orderBy(Object.values(this.participants), [(p) => p.name.toLowerCase()], ['asc']);
  }

  get isFinalAndApproved() {
    if (this.state === CircularResolutionState.FINAL) {
      return !!this.approvalRateReached;
    }
    return false;
  }

  get isFinalAndRejected() {
    if (this.state === CircularResolutionState.FINAL) {
      return !this.approvalRateReached;
    }
    return false;
  }

  get approves() {
    return this.participantsList.filter((p) => p.votingResult === VotingResult.YES);
  }

  get rejects() {
    return this.participantsList.filter((p) => p.votingResult === VotingResult.NO);
  }

  get abstains() {
    return this.participantsList.filter((p) => p.votingResult === VotingResult.ABSTAIN);
  }

  get pending() {
    return this.participantsList.filter((p) => !p?.votingResult);
  }

  get allVotedReceived() {
    return this.pending.length == 0;
  }

  get numberOfAlreadyVoted() {
    return this.participantsList.filter((p) => p?.votingResult).length;
  }

  get numberOfParticipants() {
    return this.participantsList.length;
  }

  set approvalRate(value: number) {
    value = value > 100 ? 100 : value;
    value = value < 1 ? 1 : value;
    this._approvalRate = value;
  }

  get approvalRate() {
    return this._approvalRate;
  }

  get currentApprovalRate() {
    return (
      (this.participantsList.filter((p) => p?.votingResult === VotingResult.YES).length /
        this.participantsList.filter((p) => p?.votingResult !== VotingResult.ABSTAIN).length) *
      100
    );
  }

  get currentRejectionRate() {
    return (
      (this.participantsList.filter((p) => p?.votingResult === VotingResult.NO).length /
        this.participantsList.filter((p) => p?.votingResult !== VotingResult.ABSTAIN).length) *
      100
    );
  }

  get currentPendingRate() {
    // todo - not sure if factoring out abstained in pending rate does make sence?
    return (
      (this.participantsList.filter((p) => p?.votingResult === VotingResult.YES).length /
        this.participantsList.filter((p) => p?.votingResult !== VotingResult.ABSTAIN).length) *
      100
    );
  }

  get approvalRateReached() {
    return this.currentApprovalRate >= this._approvalRate;
  }

  get allVotesReceived() {
    return this.participantsList.filter((p) => p?.votingResult).length === this.participantsList.length;
  }

  get customMetadata(): CircularResolutionCustomMetadata {
    return {
      read: this.acls.read.join(';'),
      write: this.acls.write.join(';'),
      group: this.group?.id,
    };
  }

  userHasVoted(uid: string) {
    return !!this.participants[uid]?.votingResult;
  }

  addAttachment(attachment: AttachmentModel) {
    this.attachments.push(attachment);
  }

  removeAttachment(attachment: AttachmentModel) {
    const index =
      attachment.type === AttachmentType.FILE
        ? this.attachments.findIndex((a) => a.filePath === attachment.filePath)
        : this.attachments.findIndex((a) => a.url === attachment.url);
    this.attachments.splice(index, 1);
  }

  updateAttachmentLabel(attachment: AttachmentModel) {
    const index =
      attachment.type === AttachmentType.FILE
        ? this.attachments.findIndex((a) => a.filePath === attachment.filePath)
        : this.attachments.findIndex((a) => a.url === attachment.url);
    this.attachments[index] = attachment;
  }

  validate() {
    return true;
  }

  static fromFirestoreData(data: Partial<FirestoreCircularResolutionModel>) {
    const signatories = Object.assign(
      {},
      ...Object.keys(data?.signatories || {}).map((k) => {
        return {
          [k]: {
            ...omit(data.signatories[k], 'signedOn', 'dueDate'),
            dueDate: data.signatories[k]?.dueDate?.toDate() || null,
            signature: data.signatories[k]?.signature
              ? {
                  ...omit(data.signatories[k].signature, 'signedOn'),
                  signedOn: data.signatories[k].signature?.signedOn?.toDate() || null,
                }
              : null,
          },
        };
      }),
    );

    return new CircularResolution({
      ...omit(data, ['date', 'participants']),
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
      dueDate: data?.dueDate?.toDate(),
      decidedAt: data?.decidedAt?.toDate(),
      attachments: data.attachments
        ? data.attachments.map((ref) => {
            return {
              ...ref,
              createdAt: ref.createdAt?.toDate(),
            };
          })
        : [],
      participants: Object.assign(
        {},
        ...Object.keys(data.participants || {}).map((k) => {
          return {
            [k]: data.participants[k]?.votedAt
              ? { ...data.participants[k], votedAt: data.participants[k].votedAt?.toDate() }
              : data.participants[k],
          };
        }),
      ),
      signatories: signatories,
    });
  }

  get acls() {
    return this.generateACLs();
  }

  private generateACLs() {
    return {
      read: Object.keys(this.viewers),
      write: [] as string[],
    };
  }

  toFirestore() {
    return pickBy(
      {
        ...this,
        ...this.encodePrivateProperties(),
        acl: this.generateACLs(),
        raci: this.raci ? this.raci?.toFirestore() : null,
        raciReportToIds: this.reportToList,
      },
      (v) => !isUndefined(v),
    ) as FirestoreCircularResolutionModel;
  }

  private encodePrivateProperties() {
    return Object.assign(
      {},
      ...Object.keys(this)
        .filter((p) => p.startsWith('_'))
        .map((p) => ({
          // unset private property from export
          [p]: undefined,

          [p.substr(1)]: this[p],
        })),
    );
  }
}
