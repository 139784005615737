import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LayoutService } from '@wedecide/shared/services/layout.service';

import { ConfirmDialogComponent, ConfirmDialogData } from './confirm-dialog/confirm-dialog.component';
export interface SideDialogConfig<D = any> {
  id?: string;
  height?: string;
  width?: string;
  maxWidth?: '400px' | '600px';
  data?: D;
  panelClass?: string[];
  disableClose?: boolean;
}
export type ConfirmDialogResultFn = () => void;

export type SideDialogRef = {
  close: () => void;
};

export const PanelClassConfiguration = {
  slideInRight: ['animate__animated', 'animate__faster', 'animate__slideInRight'],
  zoomIn: ['animate__animated', 'animate__faster', 'animate__zoomIn'],
  fixedBottomBar: ['fixed-bottom-bar'],
  noPadding: ['no-padding-dialog'],
} as const;

export type PanelClassConfigurations = typeof PanelClassConfiguration[keyof typeof PanelClassConfiguration];

export type StandardDialogConfig<D> = {
  width?: string;
  maxWidth?: string;
  height?: string;
  maxHeight?: string;
  panelClass?: PanelClassConfigurations[];
  disableClose?: boolean;
  data?: D;
};

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog, private layoutService: LayoutService) {}

  openSideDialog<T, D, R>(
    template: ComponentType<T> | TemplateRef<T>,
    data?: SideDialogConfig<D>,
    afterClosed?: (result: R) => void,
  ): SideDialogRef {
    const config = {
      id: data?.id || 'side-dialog',
      position: { right: '0px', top: '0px', bottom: '0px' },
      height: data?.height || '100%',
      width: data?.width || '100%',
      maxWidth: data?.maxWidth || '400px',
      panelClass: data?.panelClass || [
        'no-padding-dialog',
        'animate__animated',
        'animate__faster',
        'animate__slideInRight',
      ],
      disableClose: true,
      data: data?.data || {},
    };

    const width: 600 | 400 = config.maxWidth === '600px' ? 600 : 400;
    this.moveHubspotChatButton(true, width);
    const dialogRef = this.dialog.open(template, config);
    dialogRef.afterClosed().subscribe(afterClosed);

    dialogRef.keydownEvents().subscribe((key) => {
      if (key.code === 'Escape') {
        this.closeSideDialog(dialogRef, null, width);
      }
    });
    dialogRef.backdropClick().subscribe(() => {
      this.closeSideDialog(dialogRef, null, width);
    });

    return {
      close: () => this.closeSideDialog(dialogRef, null, width),
    };
  }

  closeSideDialog(dialogRef: MatDialogRef<any, any>, value = null, width: 400 | 600 = 400) {
    document.getElementsByClassName('animate__animated')[0]?.classList?.remove('animate__slideInLeft');
    document.getElementsByClassName('animate__animated')[0]?.classList?.add('animate__slideOutRight');
    this.moveHubspotChatButton(false, width || 400);
    setTimeout(() => {
      dialogRef.close(value);
    }, 500);
  }

  moveHubspotChatButton(moveIn = false, width: 400 | 600 = 400) {
    const domElement = document.getElementById('hubspot-body');
    if (moveIn === true) {
      domElement?.classList?.add(`hubspot-move-in-${width}`);
      domElement?.classList?.remove(`hubspot-move-out-${width}`);
    } else {
      domElement?.classList?.remove(`hubspot-move-in-${width}`);
      domElement?.classList?.add(`hubspot-move-out-${width}`);
      setTimeout(() => {
        domElement?.classList?.remove(`hubspot-move-out-${width}`);
      }, 1000);
    }
  }

  openConfirmDialog(data: ConfirmDialogData, onConfirm: ConfirmDialogResultFn, onDeny?: ConfirmDialogResultFn) {
    const dialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
      width: 'auto',
      maxWidth: this.layoutService.lt_sm ? '100%' : '400px',
      data,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === true) {
        onConfirm();
      } else onDeny?.();
    });
  }

  openStandardDialog<T, D, R>(
    template: ComponentType<T> | TemplateRef<T>,
    config: StandardDialogConfig<D>,
    afterClosed?: (result: R) => void,
  ) {
    const classes = config.panelClass?.reduce((acc, curr) => {
      return acc.concat(curr);
    }, []);

    const dialogRef = this.dialog.open<T, D, R>(template, { ...config, panelClass: classes });
    dialogRef.afterClosed().subscribe(afterClosed);
  }
}
