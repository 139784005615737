import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  DateLongPipe,
  DateTimePipe,
  DateShortPipe,
  DateTimeToTimeAgoPipe,
  HumanizeDurationPipe,
  FormatTimePipe,
  DateWithoutYearPipe,
} from './datetime.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { Nl2BrPipe } from './nl2br.pipe';
import { NameWithUnitPipe } from './name-with-unit.pipe';

@NgModule({
  declarations: [
    DateShortPipe,
    DateLongPipe,
    DateTimePipe,
    DateTimeToTimeAgoPipe,
    DateWithoutYearPipe,
    HumanizeDurationPipe,
    FormatTimePipe,
    EllipsisPipe,
    Nl2BrPipe,
    NameWithUnitPipe,
  ],
  imports: [CommonModule],
  exports: [
    DateLongPipe,
    DateTimePipe,
    DateShortPipe,
    DateTimeToTimeAgoPipe,
    DateWithoutYearPipe,
    HumanizeDurationPipe,
    FormatTimePipe,
    EllipsisPipe,
    Nl2BrPipe,
    NameWithUnitPipe,
  ],
})
export class PipeModule {}
