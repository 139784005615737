import { identity, mapValues, merge, omit, omitBy, pickBy } from 'lodash';

import {
  FirestoreMeetingReviewCommentModel,
  MeetingReviewCommentItem,
  MeetingReviewCommentModel,
  ReviewCommentDict,
} from '../interfaces/meetingReviewComment.model';
import { OrganizationReference } from '../interfaces/organization.model';
import { UserReference } from '../interfaces/user.model';

import { Base } from './base.model';

export class MeetingReviewComment
  extends Base<MeetingReviewCommentModel, FirestoreMeetingReviewCommentModel>
  implements MeetingReviewCommentModel {
  meetingId: string;
  owner: UserReference;
  snapshotVersion: number;
  organization: OrganizationReference;

  agendaComments: ReviewCommentDict;

  impersonatedBy?: UserReference;

  constructor(data: Partial<MeetingReviewCommentModel>) {
    super();
    merge(this, data);
  }

  validate() {
    // TODO: implement proper validation
    return true;
  }

  static fromFirestoreData(data: Partial<FirestoreMeetingReviewCommentModel>) {
    const convertFirestoreCommentItem = (an: any): MeetingReviewCommentItem => {
      if (an?.createdAt) {
        (an as MeetingReviewCommentItem).createdAt = an?.createdAt?.toDate();
      }
      if (an?.updatedAt) {
        (an as MeetingReviewCommentItem).updatedAt = an?.updatedAt?.toDate();
      }
      return an;
    };
    return new MeetingReviewComment(
      pickBy(
        {
          ...omit(data, ['createdAt', 'updatedAt', 'agendaComments']),
          createdAt: data.createdAt?.toDate(),
          updatedAt: data.updatedAt?.toDate(),
          agendaComments: mapValues(data.agendaComments, (comments: any[]) =>
            comments.map(convertFirestoreCommentItem),
          ),
        },
        identity,
      ),
    );
  }

  toFirestore() {
    // TODO: fix typing
    return {
      ...(this as any),
    } as FirestoreMeetingReviewCommentModel;
  }
}
