<mat-toolbar class="navbar" [ngClass]="{'show-impersonation' : impersonatedBy$ | async}" #mainToolbar>
  <mat-toolbar-row>
    <div fxFill fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.lt-sm="space-between center"
      fxLayoutGap.lt-sm="10px">
      <div fxLayoutAlign="start" fxHide fxShow.lt-md>
        <button mat-icon-button (click)="toggleSideNav()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div fxShow fxHide.lt-md>
        <a class="nav-item" routerLink="/dashboard" routerLinkActive="active">Dashboard</a>
      </div>
      <div fxShow fxHide.lt-md *ngIf="isNotBasicUser">
        <a class="nav-item" routerLink="/meetings" routerLinkActive="active" i18n="general|meetings@@general_meetings">Meetings</a>
      </div>
      <div fxShow fxHide.lt-md *ngIf="isNotBasicUser">
        <a class="nav-item" routerLink="/cr" routerLinkActive="active" i18n="general|circular resolutions@@general_circular-resolutions">Circular Resolutions</a>
      </div>
      <div fxShow fxHide.lt-md>
        <a class="nav-item" routerLink="/decisions" routerLinkActive="active" i18n="general|decisions@@general_decisions">Decisions</a>
      </div>
      <div fxShow fxHide.lt-md>
        <a class="nav-item" routerLink="/todos" routerLinkActive="active" i18n="general|todos@@general_todos">Todos</a>
      </div>
      <div fxShow fxHide.lt-md *ngIf="isNotBasicUser">
        <a class="nav-item" routerLink="/boards" routerLinkActive="active" i18n="general|boards@@general_boards">Boards</a>
      </div>

      <div fxFlex fxFill>
        <wd-network-state></wd-network-state>
      </div>

      <div #notifications fxLayoutAlign="center center">
        <button mat-icon-button fxHide.lt-md (click)="navigateToSearch()" matTooltip="Search" i18n-matTooltip="general|search@@general_search">
          <mat-icon>search</mat-icon>
        </button>

        <a mat-icon-button fxHide.lt-md matTooltip="Support Centre" i18n-matTooltip="general|support centre@@general_support-centre" href="https://help.apollo.ai" target="_blank"
          rel="noopener noreferrer">
          <mat-icon>help_outlined</mat-icon>
        </a>

        <div>
          <button mat-icon-button [mdePopoverTriggerFor]="notificationsPopover" [mdePopoverTargetAt]="mainToolbar"
            mdePopoverTriggerOn="click" (opened)="popoverOpened = true" (closed)="popoverOpened = false">
            <mat-icon  [@.disabled]="true" [matBadge]="unreadNotifications" [matBadgeHidden]="unreadNotifications < 1" matBadgeOverlap="true"
              matBadgeSize="small" matBadgeColor="warn">notifications_none</mat-icon>
          </button>
        </div>
      </div>
      <div fxHide fxShow.lt-sm fxFlex="0 0 90px" class="m-t-10">
        <img [src]="logo" width="90" />
      </div>
      <div fxFlex="0 0 130px" fxShow fxHide.lt-md fxLayoutAlign="center center">
        <div mat-button [matMenuTriggerFor]="menu">
          <div class="navbar-profile">
            <div class="profile-picture" [ngStyle]="{
              'background-image': !(impersonatedBy$ | async) ? 'url(' + userProfileImage + ')' : null
            }">
              <mat-icon *ngIf="impersonatedBy$ | async">supervisor_account</mat-icon>
            </div>
            <div>
              <div class="profile-name">{{ user?.displayName }}</div>
              <div class="active-organization">{{ organization?.name}}</div>
            </div>
          </div>
        </div>
        <mat-menu #menu="matMenu" xPosition="after" direction="ltr">
          <button [disabled]="impersonatedBy$ | async" mat-menu-item routerLink="/settings" i18n="general|settings@@general_settings">Settings</button>
          <mat-divider></mat-divider>

          <!-- impersonation handling -->
          <ng-container *ngIf="!(impersonatedBy$ | async) && impersonatableUsers?.length == 1">
            <ng-container *ngFor="let user of impersonatableUsers">
              <div class="mat-menu-item mat-menu-item-multiline" (click)="impersonate(user.id)">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <span class="p-r-10">Switch to</span>
                  <wd-user-avatar-and-name [lightText]="false" [user]="user" [background]="orange"></wd-user-avatar-and-name>
                </div>
              </div>
              <mat-divider></mat-divider>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="impersonatedBy$ | async">
            <button mat-menu-item (click)="impersonate(null)">
               <span class="p-r-10" i18n="navigation|account back switch@@navigation_account-switch-back">Switch back to your account</span>
            </button>
            <mat-divider></mat-divider>
          </ng-container>
          <ng-container *ngIf="!(impersonatedBy$ | async) && impersonatableUsers?.length > 1">
            <button mat-menu-item [matMenuTriggerFor]="impersonation" i18n="navigation|account switch@@navigation_account-switch">Switch account</button>
            <mat-divider></mat-divider>
          </ng-container>

          <button mat-menu-item (click)="logout()" i18n="general|logout@@general_logout">Logout</button>
          <mat-divider></mat-divider>
          <div class="p-t-5 p-b-5 p-l-15 p-r-15">
            <a class="privacy-policy" href="/privacy-policy" i18n="general|privacy policy@@general_privacy-policy">Privacy Policy</a>
          </div>
        </mat-menu>
      </div>
      <div fxHide.lt-sm class="navbar-item-margin" class='p-l-10' style="padding-top: 7px;">
        <img [src]="logo" width="125" />
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<!-- sub menu when more than 1 impersonation user-->
<mat-menu #impersonation="matMenu" xPosition="before">
  <ng-container *ngFor="let user of impersonatableUsers; last as isLast">
    <div class="mat-menu-item mat-menu-item-multiline" (click)="impersonate(user.id)">
     <wd-user-avatar-and-name [lightText]="false"  [user]="user" [background]="orange"></wd-user-avatar-and-name>
    </div>
    <mat-divider *ngIf="!isLast"></mat-divider>
  </ng-container>
</mat-menu>

<mde-popover #notificationsPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverOffsetX]="notificationPopoverOffset"
  [mdePopoverOffsetY]="-10" [mdePopoverArrowOffsetX]="lt_sm ? 165 : 108" mdePopoverArrowColor="#62747f"
  [mdePopoverCloseOnClick]="false" >
  <mat-card class="navbar-popover no-hover p-b-1">
    <mat-card-content>
      <wd-notification-widget *ngIf="popoverOpened" [displayUnreadBadge]="false"></wd-notification-widget>
    </mat-card-content>
  </mat-card>
</mde-popover>
