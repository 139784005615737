import { Injectable, TrackByFunction } from '@angular/core';
import { Subject } from 'rxjs';


// Decorator needed, else angular complains
@Injectable()
export class BaseComponent {
  protected _destroy$ = new Subject<boolean>();

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  trackById: TrackByFunction<any> = (i, item) => item.id;
}
