import { MeetingModelEssentials } from './../interfaces/meeting.model';
import { pickBy, isUndefined, merge, omit, orderBy, pick } from 'lodash';

import { GroupModelEssentials } from '..';
import { AttachmentModel, AttachmentType } from '../interfaces/attachment.model';
import {
  MeetingModel,
  FirestoreMeetingModel,
  ParticipantsDict,
  AttendanceDict,
  MeetingAttachmentModel,
  MeetingAttachmentStatus,
  MeetingMinutesState,
  ReviewDict,
  MeetingAgendaState,
  MeetingCustomMetadata,
  MeetingSigningState,
} from '../interfaces/meeting.model';
import { UserReference } from '../interfaces/user.model';

import { SignatoriesDict } from './../interfaces/signature.model';
import { Base } from './base.model';

/**
 * since there isnt a simple built-in hashing function that works for both the browser and nodejs envrionment
 * and I didnt want to add a depenedency to this shared library just to create a simple, arbitrary hash for the generation of the attachment ID
 * I decided to add this simple custom implementation (copied of stackoverflow)
 * @param str
 */
/* istanbul ignore next */
function hashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const character = str.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash.toString();
}

// TODO: extend this to all possible states
// export enum MeetingState {
//   DRAFT,
//   PUBLISHED,
//   IN_REVIEW,
//   REVIEWED,
// }

export class Meeting extends Base<MeetingModel, FirestoreMeetingModel> implements MeetingModel {
  title: string;
  organization: string;
  startDate: Date;
  endDate: Date;
  createdBy: UserReference;
  location: string;
  link: string;
  agendaState = MeetingAgendaState.IS_DRAFT;
  participants: ParticipantsDict = {};
  attendance: AttendanceDict = {};
  attachments: MeetingAttachmentModel[] = [];
  minutesState: MeetingMinutesState = MeetingMinutesState.NOT_STARTED;
  minutesTaker: UserReference;
  boardChairman?: UserReference;
  reviewers: ReviewDict;
  group: GroupModelEssentials;
  lastSnapshotVersion: number;
  lastSnapshotPublishedAt: Date;
  viewers: ParticipantsDict = {};
  signingState?: MeetingSigningState = MeetingSigningState.NOT_STARTED;
  signatories?: SignatoriesDict;

  get date() {
    return this.startDate;
  }

  constructor(data: Partial<MeetingModel>) {
    super();
    // Object.assign(this, data);
    merge(this, data);
  }

  get participantsList() {
    return orderBy(Object.values(this.participants), [(p) => p.name.toLowerCase()], ['asc']);
  }

  set isDraft(value: boolean) {
    console.error('Using deprecated isDraft setter');
  }

  get isDraft() {
    return this.agendaState == MeetingAgendaState.IS_DRAFT;
  }

  get isFinal() {
    return this.minutesState == MeetingMinutesState.FINAL;
  }

  get isPublished() {
    // or was published earlier
    return (
      [MeetingAgendaState.PUBLISHED, MeetingAgendaState.REPUBLISH].includes(this.agendaState) ||
      [
        MeetingMinutesState.PUBLISHED,
        MeetingMinutesState.IN_REVIEW,
        MeetingMinutesState.REVIEWED,
        MeetingMinutesState.FINAL,
      ].includes(this.minutesState)
    );
  }

  get isDeletable() {
    return !this.lastSnapshotVersion;
  }

  get areMinutesNotStartedOrInDraft() {
    return [MeetingMinutesState.NOT_STARTED, MeetingMinutesState.IS_DRAFT].includes(this.minutesState);
  }

  get pendingSignatures() {
    return Object.values(this.signatories).filter((s) => !s.signature);
  }

  get essentialModel(): MeetingModelEssentials {
    return pick(this, ['id', 'title', 'startDate', 'endDate', 'organization', 'group']);
  }

  addAttachment(attachment: AttachmentModel) {
    const meetingAttachment = attachment as MeetingAttachmentModel;
    if (!meetingAttachment.id) {
      meetingAttachment.id = hashCode(attachment.filePath);
    }
    if (!meetingAttachment.status) {
      meetingAttachment.status = meetingAttachment.filePath.endsWith('.pdf')
        ? MeetingAttachmentStatus.CONVERTABLE_TO_PRESENTATION
        : MeetingAttachmentStatus.READY;
    }
    this.attachments.push(attachment);
  }

  removeAttachment(attachment: AttachmentModel) {
    this.attachments = this.attachments.filter((a) => attachment !== a);
  }

  updateAttachmentLabel(attachment: AttachmentModel) {
    const index =
      attachment.type === AttachmentType.FILE
        ? this.attachments.findIndex((a) => a.filePath === attachment.filePath)
        : this.attachments.findIndex((a) => a.url === attachment.url);
    this.attachments[index] = attachment;
  }

  validate() {
    return true;
  }

  static fromFirestoreData(data: Partial<FirestoreMeetingModel>) {
    const reviewers = Object.assign(
      {},
      ...Object.keys(data.reviewers || {}).map((k) => {
        return {
          [k]: {
            ...omit(data.reviewers[k], 'reviewedOn'),
            reviewedOn: data.reviewers[k]?.reviewedOn?.toDate() || null,
            dueDate: data.reviewers[k]?.dueDate?.toDate() || null,
          },
        };
      }),
    );

    const signatories = Object.assign(
      {},
      ...Object.keys(data?.signatories || {}).map((k) => {
        return {
          [k]: {
            ...omit(data.signatories[k], 'signedOn', 'dueDate'),
            dueDate: data.signatories[k]?.dueDate?.toDate() || null,
            signature: data.signatories[k]?.signature
              ? {
                  ...omit(data.signatories[k].signature, 'signedOn'),
                  signedOn: data.signatories[k].signature?.signedOn?.toDate() || null,
                }
              : null,
          },
        };
      }),
    );

    return new Meeting({
      ...omit(data, ['date', 'reviewers']),
      startDate: data.startDate?.toDate(),
      endDate: data.endDate?.toDate(),
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
      lastSnapshotPublishedAt: data?.lastSnapshotPublishedAt?.toDate() || null,
      attachments: data.attachments
        ? data.attachments.map((ref) => {
            return {
              ...ref,
              createdAt: ref.createdAt?.toDate(),
            };
          })
        : [],
      reviewers: reviewers,
      signatories: signatories,
      participants: Object.assign(
        {},
        ...Object.keys(data.participants || {}).map((k) => {
          return { [k]: data.participants[k] };
        }),
      ),
    });
  }

  get acls() {
    return this.generateACLs();
  }

  get customMetadata(): MeetingCustomMetadata {
    return {
      read: this.acls.read.join(';'),
      write: this.acls.write.join(';'),
      group: this.group?.id,
    };
  }

  private generateACLs() {
    return {
      read: Object.keys(this.viewers),
      write: [] as string[],
    };
    // if (this.isDraft) {
    //   return {
    //     read: uniq([this.createdBy.id]),
    //     write: uniq([this.createdBy.id]),
    //   };
    // } else {
    //   return {
    //     read: uniq([this.createdBy.id, ...Object.keys(this.participants)]),
    //     write: uniq([this.createdBy.id, ...Object.keys(this.participants)]),
    //   };
    // }
  }

  toFirestore() {
    return pickBy(
      {
        ...this,
        ...this.encodePrivateProperties(),
        acl: this.generateACLs(),
      },
      (v) => !isUndefined(v),
    ) as FirestoreMeetingModel;
  }

  private encodePrivateProperties() {
    return Object.assign(
      {},
      ...Object.keys(this)
        .filter((p) => p.startsWith('_'))
        .map((p) => ({
          // unset private property from export
          [p]: undefined,

          [p.substr(1)]: this[p],
        })),
    );
  }
}
