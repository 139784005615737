import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import firebase from 'firebase/compat/app';

import { environment } from '../environments/environment';

import { PrivacySettingsDialog } from './privacy/privacy-settings/privacy-settings-dialog/privacy-settings-dialog.component';
import { IconService } from './shared/services/icon.service';
import { NavigationService } from './shared/services/navigation.service';
import { ThemeService } from './shared/services/theme.service';
import { acceptance, TrackingService } from './shared/services/tracking.service';

@Component({
  selector: 'wd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showCookieBanner: boolean;

  constructor(
    private themeService: ThemeService,
    private trackingService: TrackingService,
    private iconService: IconService,
    private navigationService: NavigationService,
    private dialog: MatDialog,
  ) {
    if (environment.production === false) {
      // replace favicon in dev environments
      const favIcon: HTMLLinkElement = document.querySelector('#favicon');
      favIcon.href = 'assets/icons/favicons/favicon-development.ico';
    }
  }

  ngOnInit() {
    this.themeService.checkTheme();
    this.navigationService.initHistory();
    this.iconService.registerIcons();
    // firebase.firestore.setLogLevel("debug");

    // check if cookies are set
    const flagsToCheck = ['marketingCookies', 'performanceCookies'];
    this.showCookieBanner =
      flagsToCheck.filter((cookieName: string) =>
        [acceptance.ACCEPTED as string, acceptance.NOT_ACCEPTED].includes(localStorage.getItem(cookieName)),
      ).length != flagsToCheck.length;

    if (this.showCookieBanner) {
      this.trackingService.setAnalyticsCollectionEnabled(false);
      this.trackingService.setHubspotTracking(false);
    }
  }

  async confirmCookies() {
    await this.trackingService.setAnalyticsCollectionEnabled(true).then(() => {});
    this.showCookieBanner = false;
    this.trackingService.setHubspotTracking(true);
  }

  openPrivacySettingsDialog() {
    this.showCookieBanner = false;
    this.dialog.open(PrivacySettingsDialog);
  }
}
