import { RACIDataType } from './raci.model';
import { UnitModel } from '..';
import { ACL, BaseModel, BaseModelData, FirestoreTimestamp } from './base.model';
import { GroupModelEssentials } from './group.model';
import { EssentialMeetingDecisionReport } from './meetingDecisionReport.model';
import { OrganizationReference } from './organization.model';
import { UserReference } from './user.model';

export enum DecisionResult {
  UNDECIDED = 'undecided',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  NEED_MORE_INFO = 'need_more_info',
  DOCUMENTED = 'documented',
}
export interface DeciderModel extends UserReference {
  /**
   * decision voting
   */
  voting?: DecisionResult;
  /**
   * decision voting argument
   */
  votingArgument?: string;
}

export interface MeetingDecisionReportsData<DateType = Date> {
  lastReport?: EssentialMeetingDecisionReport<DateType>;
  numberOfReports?: number;
}

export type DeciderReference = DeciderModel;
export type MemberReference = UserReference;

export type MemberDict = { [uid: string]: MemberReference };

export type DeciderDict = { [uid: string]: DeciderReference };

export type UserDict = { [uid: string]: UserReference };

export enum ActivityType {
  CREATE = 'create',
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
  REQUEST_APPROVAL = 'request_approval',
  CANCEL_REQUEST = 'cancel_request',
  APPROVAL = 'approval',
  REJECT = 'reject',
  INFO_REQUEST = 'info_request',
  STATE_CHANGE = 'state_change',
  REFLECT = 'reflect',
  VOTING_SUCCEEDED = 'voting_succeeded',
  VOTING_FAILED = 'voting_failed',
  DOCUMENTED_DECISION = 'documented_decision',
}

export interface SharedMeetingDecisionModel<DateType = Date> extends BaseModelData<DateType> {
  /**
   * meeting id
   * is required to be defined
   */
  meetingId: string;

  /**
   * agenda item id
   * is required to be defined
   */
  agendaItemId: string;

  /**
   * title of a decision
   * is required to be defined
   */
  title: string;

  /**
   * optional text that is agreed on in markdown syntax
   */
  decisionText?: string;

  /**
   * additional text, meeting notes
   */
  contextText?: string;

  /**
   * date when decision was made
   */
  decidedAt: DateType;

  /**
   * user that created and owns the decision
   */
  owner: UserReference;

  /**
   * all users that have access to this decisions in the manage section
   */
  members: MemberDict;

  /**
   * all users that have access and able to decide this decisions
   */
  deciders?: MemberDict;

  /**
   * organization the decision belongs to
   * will be implizit in the data structure due to the used firestore collection, e.g.: `orf_decisions`
   */
  organization: OrganizationReference;

  /**
   * documented decision voting result
   */
  decisionVoting?: MeetingDecisionVotingResult;

  /**
   * RACI data
   */
  raci: RACIDataType;

  /**
   * board
   */
  group: GroupModelEssentials;

  /**
   * order of this decision within an agenda item.
   */
  order?: number
}

export interface MeetingDecisionVotingResult {
  unanimous?: boolean;
  notes?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MeetingDecisionModel extends SharedMeetingDecisionModel, BaseModel<FirestoreMeetingDecisionModel> {}

export interface FirestoreMeetingDecisionModel extends SharedMeetingDecisionModel<FirestoreTimestamp> {
  /**
   * defines the ACL for this decision and which users do have access to the decision
   */
  acl: ACL;

  /**
   * ids for filtering in the decision reports view
   */
  raciReportToIds?: string[];
}