import { isUndefined, merge, omit, pickBy } from 'lodash';

import { UserReference } from '../interfaces/user.model';

import { MeetingFeedbackModel, FirestoreMeetingFeedbackModel } from './../interfaces/meetingFeedback.model';
import { Base } from './base.model';

export class MeetingFeedback
  extends Base<MeetingFeedbackModel, FirestoreMeetingFeedbackModel>
  implements MeetingFeedbackModel {
  organization: string;
  meetingId: string;
  createdBy: UserReference;
  comment: string;
  snapshotVersion: number;
  impersonatedBy?: UserReference;

  constructor(data: Partial<MeetingFeedbackModel>) {
    super();
    // Object.assign(this, data);
    merge(this, data);
  }

  validate() {
    // TODO: implement proper validation
    return true;
  }

  static fromFirestoreData(data: Partial<FirestoreMeetingFeedbackModel>) {
    return new MeetingFeedback({
      ...omit(data, ['createdAt', 'updatedAt']),
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
    });
  }

  toFirestore() {
    // use pickBy to actually delete unset private properties from for firestore encoded object
    return (pickBy(
      {
        ...this,
      },
      (v) => !isUndefined(v),
    ) as unknown) as FirestoreMeetingFeedbackModel;
  }
}
