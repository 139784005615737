import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { throttle } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ErrorLoggingService {
  private throttledLogger;

  constructor(private functions: AngularFireFunctions) {
    this.throttledLogger = throttle(this.logErrorRemote, 10000, { leading: true });
  }

  logError(message: string, stack: string, logLocal = true) {
    if (logLocal) {
      console.error(message, stack);
    }
    this.throttledLogger(message, stack);
  }

  private logErrorRemote(message: string, stack: string) {
    const callable = this.functions.httpsCallable('logFrontendError');
    callable({
      message,
      stack,
    }).subscribe({
      error: (e) => {
        // eslint-disable-next-line no-console
        console.error('Failed reporting error log to cloud function');
        // eslint-disable-next-line no-console
        console.error('Original Error', e);
      },
    });
  }
}
