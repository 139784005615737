import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';

import { AuthService } from '@wedecide/shared/services/auth.service';
import { ErrorService } from '@wedecide/shared/services/error.service';
import { ThemeService } from '@wedecide/shared/services/theme.service';

@Component({
  selector: 'wd-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private _privacyPolicyChecked = false;
  public adLoginInProgress = false;
  public loading = false;
  private _privacyPolicyWarn = false;
  private _showLoginForm = false;
  loginForm = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.email])],
    password: ['', Validators.required],
  });
  @ViewChild('password') passwordField: ElementRef;

  get privacyPolicyChecked() {
    return this._privacyPolicyChecked;
  }

  get logo() {
    return this.themeService.svgLogo;
  }

  get showPrivacyPolicyWarning() {
    return !this._privacyPolicyChecked && this._privacyPolicyWarn;
  }

  get showLoginForm() {
    return this._showLoginForm;
  }

  constructor(
    public router: Router,
    public formBuilder: FormBuilder,
    public authService: AuthService,
    public snackBar: MatSnackBar,
    public functions: AngularFireFunctions,
    public auth: AngularFireAuth,
    private themeService: ThemeService,
    private ngZone: NgZone,
    private errorService: ErrorService,
  ) {}

  ngOnInit() {
    this._privacyPolicyChecked = localStorage.getItem('privacyPolicyCheck') === 'accepted';
  }

  toggleLoginForm(value: boolean) {
    this._privacyPolicyWarn = false;
    if (!this._privacyPolicyChecked) {
      this._privacyPolicyWarn = true;
      return;
    }
    this._showLoginForm = value;
  }

  navigateToRedirectURL() {
    const defaultUrl = '/dashboard';
    const redirectUrl = localStorage.getItem('redirectUrl') || defaultUrl;
    localStorage.removeItem('redirectUrl');
    if (redirectUrl.startsWith('/error')) {
      this.router.navigateByUrl(defaultUrl);
    } else {
      this.router.navigateByUrl(redirectUrl);
    }
  }

  private async checkUser(credential: firebase.auth.UserCredential, token?: firebase.auth.IdTokenResult) {
    const user = credential.user;
    const providerData = user?.providerData || [];
    const isMicrosoftUser = providerData.find((provider) => {
      return provider.providerId == 'microsoft.com';
    });

    const isValidAuthUser = user.emailVerified || isMicrosoftUser;

    const result = token || (await user.getIdTokenResult());
    const hasValidClaims = result.claims.organizations && Object.keys(result.claims.organizations).length == 1;

    if (isValidAuthUser && hasValidClaims) {
      return true;
    }

    if (!isValidAuthUser) {
      console.error('Invalid Auth user: Email not verfied and no ms user');
    }

    if (!hasValidClaims) {
      console.error('Invalid claims object: Organization claims invalid');
    }
    await this.auth.signOut();
    throw new Error('User is invalid.');
  }

  async loginWithMicrosoft() {
    this._privacyPolicyWarn = false;
    if (!this._privacyPolicyChecked) {
      this._privacyPolicyWarn = true;
      return;
    }
    this.adLoginInProgress = true;

    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      tenant: 'organizations',
      prompt: 'select_account',
    });
    try {
      const signinResult = await this.auth.signInWithPopup(provider);
      this.loading = true;
      const idToken = await signinResult.user.getIdTokenResult();
      // if user has organization claims, he shall pass
      if (idToken.claims['organizations']) {
        await this.checkUser(signinResult, idToken);
        this.navigateToRedirectURL();
        return;
      }

      // upgrade or exchange token - server decides
      const callable = this.functions.httpsCallable('handleMicrosoftAuth');
      const authHandlerResult = await callable({}).toPromise();
      if (authHandlerResult.action == 'exchange') {
        // we need to sign out and sign in with the new token
        await this.auth.signOut();
        const customTokenAuth = await this.auth.signInWithCustomToken(authHandlerResult.token);
        await this.checkUser(customTokenAuth);
        this.navigateToRedirectURL();
      } else if (authHandlerResult.action == 'update') {
        // server added organization claims
        const newToken = await signinResult.user.getIdTokenResult(true);
        await this.checkUser(signinResult, newToken);
        this.navigateToRedirectURL();
      } else {
        // invalid action
        Promise.reject('Active directory login failed');
      }
    } catch (e) {
      this.ngZone.run(() => {
        this.errorService.showServerErrorSnackbar(
          e,
          $localize`:login|Error; active directory login failed@@login_error_active-directory-failed:An error occurred. Could not login user into Apollo. Try again or contact your admin.`,
        );
        console.error((e && e.details) || (e && e.message) || 'An unexpected error occured');
        this.adLoginInProgress = false;
        this.loading = false;
        this.auth.signOut().then();
      });
    }
  }

  onSubmit() {
    this._privacyPolicyWarn = false;
    if (!this._privacyPolicyChecked) {
      this._privacyPolicyWarn = true;
      return;
    }
    localStorage.setItem('privacyPolicyCheck', this._privacyPolicyChecked ? 'accepted' : 'not-accepted');

    this.loginForm.setValue({
      ...this.loginForm.value,
      // workaround for iOS Chrome to properly work with autofill
      password: this.loginForm.value.password || this.passwordField?.nativeElement?.value,
    });
    // ensure to check validation
    this.loginForm.updateValueAndValidity();
    if (!this.loginForm.valid) {
      return;
    }

    this.authService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .then((result) => {
        return this.checkUser(result);
        // console.debug(res);
      })
      .then(() => {
        this.navigateToRedirectURL();
      })
      .catch((err) => {
        // display generic error message unless too many login tries
        const errMsg =
          err.code == 'auth/too-many-requests'
            ? $localize`:login|Error; Too many auth requests@@login_error_too-many-auth-request:Login disabled due to too many failed attempts. Try again later.`
            : $localize`:login|Error; unkwnon login error@@login_error:Login failed.`;
        this.errorService.showServerErrorSnackbar(err, errMsg);
        this.loginForm.controls['password'].setValue('');
      });
  }

  privacyPolicyCheck(args) {
    this._privacyPolicyChecked = args?.checked || false;
  }
}
