export const ellipsis = (value: string, max: number): string => {
  const biggestWord = max > 50 ? 50 : 1;
  const ellipsis = '...';
  if (typeof value === 'undefined' || value.length <= max) {
    return value;
  }
  let truncatedText = value.slice(0, max + biggestWord);
  while (truncatedText.length > max - ellipsis.length) {
    const lastSpace = truncatedText.lastIndexOf(' ');
    if (lastSpace === -1) break;
    truncatedText = truncatedText.slice(0, lastSpace).replace(/[!,.?;:]$/, '');
  }
  return truncatedText + ellipsis;
};
