import { Pipe } from '@angular/core';
import { ellipsis } from '@wedecide/models';

@Pipe({
  name: 'ellipsis',
})
export class EllipsisPipe {
  transform(value: string, length = 140): string {
    if (!value || typeof value != 'string') {
      return '';
    }
    if (length < 1) {
      return value;
    }
    return ellipsis(value, length);
  }
}
