import { isUndefined, pickBy } from 'lodash';
import { RACIDataType, RACIResponsible, ReportOnceModel, ReportRecurringModel, ReportTimeframeModel, ReportTimeframeType, ReportTimeframeTypes, UserOrGroupReference } from '../interfaces/raci.model';
import { UserModelEssentials } from '../interfaces/user.model';

export class RACIData implements RACIDataType {
  constructor(
    public responsible: RACIResponsible = { user: null, shareMinutesAndDocuments: false },
    public reportOnCompletion?: UserOrGroupReference[],
    public reportAfterTimeFrame?: ReportTimeframe,
    public reportsActive?: boolean,
    public lastReportDate?: Date,
    public nextReportDueDate?: Date,
    public decisionImplemented?: boolean,
  ) {}

  get responsibleUser(): UserModelEssentials {
    return this.responsible.user;
  }

  get reportToList() {
    return [...(this.reportAfterTimeFrame?.users || []), ...(this.reportOnCompletion || [])];
  }

  static fromFirestoreData(data: any): RACIData {
    const raciData = new RACIData(
      data.responsible,
      data.reportOnCompletion,
      data.reportAfterTimeFrame ? ReportTimeframe.fromFirestore(data.reportAfterTimeFrame) : undefined,
      data?.reportsActive,
      data?.lastReportDate ? toJsDate(data?.lastReportDate) : undefined,
      data?.nextReportDueDate ? toJsDate(data?.nextReportDueDate) : undefined,
      data?.decisionImplemented,
    );
    return raciData;
  }

  toFirestore() {
    return pickBy(
      {
        ...this,
        reportAfterTimeFrame: this.reportAfterTimeFrame?.toFirestore(),
      },
      (v: any) => !isUndefined(v),
    );
  }
}

export abstract class ReportTimeframe implements ReportTimeframeModel {
  constructor(public type: ReportTimeframeTypes, public users: UserOrGroupReference[] = []) {}

  static fromFirestore(data: any): ReportTimeframe {
    const { type } = data;

    if (type === ReportTimeframeType.onetime) {
      return ReportOnce.fromFirestore(data);
    } else if (type === ReportTimeframeType.recurring) {
      return ReportRecurring.fromFirestore(data);
    } else {
      return null;
    }
  }

  toFirestore(): ReportTimeframeModel {
    return { ...this };
  }
}

function toJsDate(date: any) {
  if (!date) {
    return null;
  }

  if (date instanceof Date) {
    return date;
  }

  // for DateTime
  if (date.toJSDate) {
    return date.toJSDate();
  }

  // for string
  if (typeof date == 'string') {
    return new Date(date);
  }

  if (date.toDate) {
    return date.toDate();
  }

  console.error(new Error('toJsDate: invalid date - ignoring' + date));
  console.error(date);

  return null;
}

export class ReportOnce extends ReportTimeframe implements ReportOnceModel {
  constructor(public date: Date, users: UserOrGroupReference[] = []) {
    super(ReportTimeframeType.onetime, users);
  }

  static fromFirestore(data: any): ReportOnce {
    const targetDate = toJsDate(data.date);
    return new ReportOnce(targetDate, data.users);
  }
}

export class ReportRecurring extends ReportTimeframe implements ReportRecurringModel {
  constructor(users: UserOrGroupReference[] = [], public unit: 'weeks' | 'months', public interval: number) {
    super(ReportTimeframeType.recurring, users);
  }

  static fromFirestore(data: any): ReportRecurring {
    return new ReportRecurring(data.users, data.unit, data.interval);
  }
}

export function isRecurring(reportTimeframe: ReportTimeframe): reportTimeframe is ReportRecurring {
  return reportTimeframe?.type === 'recurring';
}

export function isOnetime(reportTimeframe: ReportTimeframe): reportTimeframe is ReportOnce {
  return reportTimeframe?.type === 'onetime';
}