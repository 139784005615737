<h3 mat-dialog-title class="text-center" *ngIf="title">
  {{title}}
</h3>

<div mat-dialog-content class="text-center">
  <p>{{message}}</p>
</div>

<div class="m-b-0 confirm-actions" mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around center">
  <button mat-button (click)="onDismiss()">{{ labelCancel }}</button>
  <button mat-flat-button [color]="buttonColor" (click)="onConfirm()"><span class="wd-white">{{ labelConfirm }}</span></button>
</div>
