export const environment = {
  production: true,
  emulators: {
    firestore: false,
    functions: false,
    auth: false,
    storage: false,
  },
  algolia: {
    appId: '7U0XAGXY51',
  },
  firebase: {
    apiKey: 'AIzaSyChHNsbR93rqoBkRlqKyG17tTC124GzrEY',
    authDomain: 'testing.apollo.ai',
    projectId: 'coco-mvp-testing',
    storageBucket: 'coco-mvp-testing.appspot.com',
    messagingSenderId: '110381171754',
    appId: '1:110381171754:web:f84a84ca605f0c70ac2f53',
    measurementId: 'G-4ZV54H1WTQ',
  },
  endpoints: {
    calendarUrl: 'https://testing.apollo.ai/functions/calendar.ics',
    fileDownloadUrl: 'https://file-download-proxy-tnc7tax7la-ey.a.run.app',
  },
  PSPDFKit_licenseKey:
    'hAAc-27OqBjWNNgG5jIHdEklz59HnpDLPx_1DV6z65hFC3XzbUAA4nlJgx3Gr8fgtRFT8kjOvJ6eeb6LgMTas38rDlXoCTM75XK6vGE_y-iuGcBr1tZSgyUtciSbm1yu8posu07QP2BcLpR037OkyDHJp7qkBxC7j939YcW4o66Y6IYLuht94xiU06-3L7Knv37KLV69RK2twDs6gLzbHg2T8PhyyHA9yaJjGQY0Gut2AM2TOUyI-bUI5klKM0Hha4hPRCBPek22XAiPL_shTXA3GFb8zOiRuDN9-Ho_UY--BwDEGPNLmqy7FihJ0ykur2Y5Gqk0eLHUWyNzrC3_d4q_0YQJ695HQsU98UTS1w1ovO9_Mkv71W8R73E1om5ncby0RLotRnKdACtG46GVraUk8BDTXjqGVs2ZQe673ZaKX_qIDhz1-xEMOodCgLVqO6Xgcn2t_TDaeb4LT4aQzSuz38DmKRwcn8NMGVAs1Yf1btZX6vuY0W6O4LvhxVQuwz_QpAZcSj9lM4nm53yWAEykIDAW5bZpob9d760hiovVshveHpeFrSlem4TWagu2OtBAzIu-kIvLphvP-xDVXm7DxzRBXOQUEcNVFXa90MhOD7ZfFpR3AOHInKTrZDv-',
};
