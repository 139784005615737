import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { ErrorLoggingService } from './shared/services/error-logging.service';
import { ErrorService } from './shared/services/error.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  constructor(private injector: Injector) {
    super();
  }

  handleError(error: Error | HttpErrorResponse) {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(ErrorLoggingService);
    const router = this.injector.get(Router);
    const ngZone = this.injector.get(NgZone);

    let message;
    let stackTrace;

    if (error instanceof HttpErrorResponse) {
      // Server Error
      errorService.showServerErrorSnackbar(error, 'An unhandled server error occurred');
    } else {
      // detect firebase error via duck typing (TODO: find better solution here)
      if (error['code']) {
        const code = error['code'];
        if (code === 'permission-denied') {
          // eslint-disable-next-line no-console
          console.error(error);
          ngZone.run(() => {
            router.navigateByUrl('error/permission-denied', { skipLocationChange: true });
          });
          return;
        }
        if (code && typeof code == 'string' && code.startsWith('auth/')) {
          console.log('No global handling for auth errors');
          console.error(error);
          return;
        }
      }

      // reload when loading chunks failed

      const chunkFailedMessage = /Loading chunk/;
      if (chunkFailedMessage.test(error.message)) {
        confirm('A new version of Apollo has been published. \nPlease Reload');
        window.location.reload();
        return;
      }

      // Client Error
      message = error.message;
      stackTrace = error.stack;
      // errorService.showErrorOverlay(error);
      // log errors to server
      logger.logError(message, stackTrace);

      super.handleError(error);
    }
  }
}
