import { Injectable } from '@angular/core';
import { ErrorMessageTypes } from '@wedecide/models';
import { FunctionsError } from 'firebase/functions';

const errorMessageTranslationsMap: Record<ErrorMessageTypes | 'UNKNOWN_ERROR', string> = {
  NO_MEETING_FOUND: $localize`:error|@@NO_MEETING_FOUND:No meeting found`,
  INVALID_ARGUMENT: $localize`:error|@@INVALID_ARGUMENT:Invalid data was submitted`,
  PERMISSION_DENIED: $localize`:error|@@PERMISSION_DENIED:You do not have permission for this operation`,
  MAX_BOARDS_REACHED: $localize`:error|@@MAX_BOARDS_REACHED:You have reached the maximum amount of boards`,
  ORGANIZATION_ALREADY_EXISTS: $localize`:error|@@ORGANIZATION_ALREADY_EXISTS:This organization already exists`,
  USER_ALREADY_EXISTS: $localize`:error|@@USER_ALREADY_EXISTS:This user already exists`,
  UNEXPECTED_SERVER_ERROR: $localize`:error|@@UNEXPECTED_SERVER_ERROR:An unexpected server error occured`,
  UNKNOWN_ERROR: $localize`:error|@@UNKNOWN_ERROR:unknown error occured`,
};

function getAlias(error: FunctionsError): string {
  const details = error.details;
  let alias;
  if (typeof details === 'object') {
    alias = details['alias'];
  }
  return alias ?? 'UNKNOWN_ERROR';
}

@Injectable({ providedIn: 'root' })
export class ErrorMessageService {
  getErrorMessage(error: FunctionsError): string {
    return errorMessageTranslationsMap[getAlias(error)];
  }
}
