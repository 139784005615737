import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserAvatarService {
  getAvatar(user: { name; avatar? }, background = '35b9c6') {
    return user.avatar || this.getDefaultAvatar(user.name, background);
  }

  getDefaultAvatar(username: string, background = '35b9c6') {
    return `https://eu.ui-avatars.com/api/?name=${encodeURI(username)}&background=${background}&color=fff`;
  }
}
