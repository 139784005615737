import { Component, Input } from '@angular/core';

export enum ApolloSpinnerColor {
  DEFAULT = 'default',
  LIGHT = 'light',
  DARK = 'dark',
}
@Component({
  selector: 'wd-apollo-spinner',
  templateUrl: './apollo-spinner.component.html',
  styleUrls: ['./apollo-spinner.component.scss'],
})
export class ApolloSpinnerComponent {
  @Input() size = 50;
  @Input() color: ApolloSpinnerColor = ApolloSpinnerColor.DEFAULT;

  get colors() {
    if (this.color === ApolloSpinnerColor.LIGHT) {
      return { top: '#ffffff', middle: '#ffffff', bottom: '#ffffff' };
    }
    if (this.color === ApolloSpinnerColor.DARK) {
      return { top: '#333333', middle: '#333333', bottom: '#333333' };
    }
    return { top: '#3DDBCA', middle: '#36B9C6', bottom: '#2E7D9F' };
  }

  get width() {
    return `${this.size}px`;
  }

  get height() {
    return `${this.size}px`;
  }
}
