

import { NotificationConfiguration } from '../notification.templates';

export const MEETING_UPLOADED_REQUESTED_DOCUMENTS_TEMPLATE: NotificationConfiguration = {
  inapp: `<b>{triggeredByName}</b> has uploaded document(s) for the agenda`,
  email: {
    ctaKey: 'go_to_meeting',
  },
};
