export type ErrorMessage<ErrorMessageContext> = {
  /**
   * Error message alias, used to identify an error message.
   * primarily used for picking the right translation in the frontend
   */
  alias: string;

  /**
   * message creator function, used to generate debugging messages
   * for logging purposes
   */
  message: (context: ErrorMessageContext) => string;
};

const NO_MEETING_FOUND: ErrorMessage<string> = {
  alias: 'NO_MEETING_FOUND',
  message: (meetingId) => `No meeting with ID ${meetingId} found`,
};

const INVALID_ARGUMENT: ErrorMessage<Record<any, any>> = {
  alias: 'INVALID_ARGUMENT',
  message: (argument) => `Invalid argument: ${JSON.stringify(argument)}`,
};

const PERMISSION_DENIED: ErrorMessage<void> = {
  alias: 'PERMISSION_DENIED',
  message: () => `You do not have permission for this operation`,
};

const UNEXPECTED_SERVER_ERROR: ErrorMessage<void> = {
  alias: 'UNEXPECTED_SERVER_ERROR',
  message: () => 'An unexpected error occured',
};

const MAX_BOARDS_REACHED: ErrorMessage<number> = {
  alias: 'MAX_BOARDS_REACHED',
  message: (numberOfBoards) => `Max number of boards reached: ${numberOfBoards} `,
};

const ORGANIZATION_ALREADY_EXISTS: ErrorMessage<string> = {
  alias: 'ORGANIZATION_ALREADY_EXISTS',
  message: (organizationId) => `An organization with ID ${organizationId} already exists.`,
};

const USER_ALREADY_EXISTS: ErrorMessage<string> = {
  alias: 'USER_ALREADY_EXISTS',
  message: (userId) => `A user with ID ${userId} already exists.`,
};

export const ErrorMessages = {
  NO_MEETING_FOUND,
  INVALID_ARGUMENT: INVALID_ARGUMENT,
  PERMISSION_DENIED,
  UNEXPECTED_SERVER_ERROR,
  MAX_BOARDS_REACHED,
  ORGANIZATION_ALREADY_EXISTS,
  USER_ALREADY_EXISTS,
};

export type ErrorMessageTypes = keyof typeof ErrorMessages;
