import { GroupModelEssentials, UserModelEssentials } from '..';

import { BaseModel, BaseModelData, FirestoreTimestamp } from './base.model';
import { UserReference } from './user.model';

export enum NotificationType {
  PUBLISH_MEETING = 'publish_meeting',
  PUBLISH_MEETING_MINUTES = 'publish_meeting_minutes',
  PUBLISH_MEETING_FINAL = 'publish_final_meeting',
  SHARE_MEETING_AGENDA = 'share_meeting_agenda',
  SHARE_MEETING_MINUTES = 'share_meeting_minutes',
  RACI_RESPONSIBLE_FINALIZE = 'raci_responsible_finalize',
  RACI_REPORT_SHARED = 'raci_report_shared',
  RACI_WEEK_BEFORE_REMINDER = 'raci_week_before_reminder',
  RACI_DAY_BEFORE_REMINDER = 'raci_day_before_reminder',
  RACI_REPORT_OVERDUE_REMINDER = 'raci_report_overdue',
  RACI_REPORT_COMMENT = 'raci_report_comment',
  RACI_DECISION_IMPLEMENTED = 'raci_decision_implemented',
  GUEST_PUBLISH_AGENDA = 'guest_publish_agenda',
  GUEST_FINALIZE_MEETING = 'guest_finalize_meeting',
  MEETING_MINUTES_REVIEW_REQUEST = 'meeting_minutes_review_request',
  MEETING_MINUTES_REVIEW_COMPLETE = 'meeting_minutes_review_complete',
  MEETING_MINUTES_REVIEW_DUE_SOON = 'meeting_minutes_review_due_soon',
  MEETING_MINUTES_SUBMIT_REVIEW = 'meeting_minutes_submit_review',
  MEETING_MINUTES_ACCEPT_REVIEW = 'meeting_minutes_accept_review',
  MEETING_AGENDA_FEEDBACK = 'meeting_agenda_feedback',
  MEETING_MINUTES_SIGNING_REQUEST = 'meeting_minutes_signing_request',
  MEETING_MINUTES_SIGNING_PENDING = 'meeting_minutes_signing_pending',
  MEETING_MINUTES_SIGNING_COMPLETE = 'meeting_minutes_signing_complete',
  MEETING_REQUEST_DOCUMENTS = 'meeting_request_documents',
  MEETING_UPLOADED_REQUESTED_DOCUMENTS = 'meeting_uploaded_requested_documents',
  CR_SHARE_CIRCULAR_RESOLUTION = 'cr_share_circular_resolution',
  CR_PUBLISH_CIRCULAR_RESOLUTION = 'cr_publish_circular_resolution',
  CR_CANCEL_CIRCULAR_RESOLUTION = 'cr_cancel_circular_resolution',
  CR_APPROVAL_RATE_REACHED = 'cr_approval_rate_reached',
  CR_FINALIZE_CIRCULAR_RESOLUTION = 'cr_finalize_circular_resolution',
  CR_VOTING_DUE_SOON = 'cr_voting_due_soon',
  CR_VOTING_FINISHED = 'cr_voting_finished',
  TODO_DUE_SOON = 'todo_due_soon',
  CONNECT_USER = 'connect_user',
  ADMIN_CONNECTED_USERS = 'admin_connect_user',
}

export interface NotificationData<DateType = Date> {
  type: NotificationType;
  title?: string;
  itemId?: string;
  read?: boolean;
  dueDate?: DateType;
  urlPath?: string; // to override the automatic meeting/decision url if needed
  message?: string;
  triggeredBy?: UserReference;
  board?: GroupModelEssentials;
  mentionedUsers?: string[];
}

export interface NotificationModel<DateType = Date>
  extends NotificationData<DateType>,
    BaseModel<FirestoreNotificationModel, DateType> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FirestoreNotificationModel
  extends NotificationData<FirestoreTimestamp>,
    BaseModelData<FirestoreTimestamp> {}
