<div class="widget" fxLayout="column" fxFlex="0 0 100%">
  <div class="p-b-10" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div><mat-icon>notifications_none</mat-icon></div>
    <h3>
      <span [matBadge]="numberOfUnreadNotifications"
        matBadgeOverlap="false" matBadgeSize="small" matBadgeColor="warn"
        [matBadgeHidden]="numberOfUnreadNotifications < 1 || !displayUnreadBadge">
        <ng-container i18n="general|notifications@@general_notifications">Notifications</ng-container>
      </span>
    </h3>
  </div>
  <div>
    <mat-divider></mat-divider>
  </div>
  <div class="scroll-only-y">
    <div class="p-t-12 p-b-12" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="pointer underline" align="left" (click)="toggleFilter($event)">{{filterText}}</div>
      <div class="pointer underline" align="right" (click)="markAllRead($event)" *ngIf="numberOfUnreadNotifications > 0" i18n="notifications|Button; mark all as read@@notifications_button_mark-all-read">Mark all as read</div>
    </div>
    <div *ngFor="let notification of notificationItems; index as i; trackBy: trackById">
      <wd-notification-item [notification]="notification"></wd-notification-item>
    </div>
    <div *ngIf="showMoreNotificationsLink && notificationItems.length > 0 && !loading" class="m-t-10 m-b-20">
      <a class="pointer" (click)="loadAllNotifications()" i18n="notifications|Button; load all notifications@@notifications_button_load-all">Load all notifications</a>
    </div>
    <div *ngIf="notificationItems.length < 1 && !loading">
      <span *ngIf="!displayAllNotifications" i18n="notifications|Info; no open notifications@@notifications_info_no-open-notifications">No open notifications!</span>
      <span *ngIf="displayAllNotifications" i18n="notifications|Info; notifications empty@@notifications_info_notifications-empty">No notifications available!</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></div>
  </div>
</div>
