<div class="auth-layout login mat-app-background">
  <div class="login-wrapper" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.lt-lg="start center">
    <div fxLayout="row" class="login-container" fxLayoutAlign="center center">
      <div class="left" fxFlex="50%" fxFlex.lt-sm="0" fxLayoutAlign="center center" fxFill fxHide.lt-sm>
        <img src="assets/images/pw-reset.svg" width="100%"/>
      </div>
      <div fxLayout="column" fxFlex="50%" fxFlex.lt-sm="100%" class="right" fxFill>
        <div class="logo-wrapper" fxLayoutAlign="space-between center" fxFlex="64px">
          <div>
            <button mat-button color="primary" [routerLink]="'/login'"><mat-icon class="sm">arrow_back</mat-icon> <ng-container i18n="general|login@@general_login">Login</ng-container></button>
          </div>
          <img [src]="logo" width="120"/>
        </div>
        <div *ngIf="isLoading" class="spinner-container">
          <wd-apollo-spinner size="80"></wd-apollo-spinner>
        </div>
        <div fxLayoutAlign="center center" *ngIf="!isLoading">
          <form class="login-form" *ngIf="!mailRequested" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div fxHide.gt-xs>
              <img src="assets/images/pw-reset.svg" width="100%"/>
            </div>
            <h2 i18n="pw-reset|Headline; set password@@login_headline_set-password">Send password link</h2>
            <div fxLayout="column" fxFlexAlign="center center" fxLayoutGap="5px">
              <mat-form-field appearance="standard">
                <mat-label i18n="forgot-password|email label@@forgot-password_label_email">Email</mat-label>
                <input matInput type="text" formControlName="email" autocomplete="email" required>
                <mat-error *ngIf="(form.get('email').touched || form.get('email').dirty) && form.get('email').invalid" i18n="forgot-password|Error; invalid email@@forgot-password_error_invalid-email">
                  Please enter a valid email
                </mat-error>
              </mat-form-field>
              <button type="submit" class="p-t-5 p-b-5" mat-flat-button color="primary"
                [disabled]="form.invalid" i18n="forgot-password|Send reset link button@@forgot-password_button_reset-link">
                Send
              </button>
            </div>
          </form>
          <div class="mail-requested-info" *ngIf="mailRequested" i18n="forgot-password|Reset link sent message@@forgot-password_text_reset-link-sent">
            A password reset link has been sent to you. Please check your mailbox.
          </div>
        </div>
      </div>
    </div>
    <div class="text-center bottom-links" fxHide.lt-sm>
      <small>
        <a href="/imprint" i18n="login|imprint link@@login_button_imprint">Imprint</a> |
        <a href="/privacy-policy" i18n="login|privacy policy button@@login_button_privacy-policy">Privacy Policy</a>
      </small>
    </div>
  </div>
</div>
