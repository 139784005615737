<div class="auth-layout mat-app-background">
  <div class="imprint-wrapper" fxLayout="column" fxLayoutAlign="space-around center">
    <div fxFlex="20px" fxFlex.gt-md="100px" fxFlex.gt-xs="100px" fxFill></div>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="header-row">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayoutGap.lt-sm="5px">
        <button type="button" mat-icon-button (click)="backClicked()" color="primary">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <img [src]="logo" width="130px" />
      </div>
    </div>
    <div class="content-wrapper">
      <h1 i18n="imprint|headline@@imprint_headline">Imprint</h1>
      <p>
        APOLLO.AI GmbH <br>
        Poschacherstrasse 23, 4020 Linz, <span i18n="imprint|austria@@imprint_austria">Austria</span><br>
        <span i18n="imprint|commercial-register-number@@imprint_commercial-register-nummer">Commercial Register Number</span>: FN 420485 k
      </p>
      <p>
        Updateme Inc. <br>
        USA, 2100 Geng Rd, Palo Alto, Ca 94303, Suite 210
      </p>
      <p><b i18n="imprint|founders@@imprint_founders">Founders:</b> Michael Hirschbrich, Andreas Schietz</p>
      <p><a href="mailto: info@apollo.ai">info@apollo.ai</a></p>
    </div>
    <div fxFlex="20px" fxFill></div>
  </div>
</div>