import { merge, omit, pickBy, isUndefined } from 'lodash';

import {
  MeetingDecisionReportCommentModel,
  FirestoreMeetingDecisionReportCommentModel,
} from '../interfaces/meetingDecisionReportComment.model';
import { UserModelEssentials } from '../interfaces/user.model';

import { Base } from './base.model';

export class MeetingDecisionReportComment
  extends Base<MeetingDecisionReportCommentModel, FirestoreMeetingDecisionReportCommentModel>
  implements MeetingDecisionReportCommentModel {
  constructor(data: Partial<MeetingDecisionReportCommentModel>) {
    super();
    merge(this, data);
  }

  organization: string;
  decisionId: string;
  agendaItemId: string;
  meetingId: string;
  reportId: string;
  message: string;
  createdBy: UserModelEssentials;
  impersonatedBy?: UserModelEssentials;

  validate() {
    // TODO: implement proper validation
    return true;
  }

  static fromFirestoreData(data: Partial<FirestoreMeetingDecisionReportCommentModel>) {
    return new MeetingDecisionReportComment({
      ...omit(data, ['createdAt', 'updatedAt']),
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
    });
  }

  toFirestore() {
    // use pickBy to actually delete unset private properties from for firestore encoded object
    return (pickBy(
      {
        ...this,
      },
      (v) => !isUndefined(v),
    ) as unknown) as FirestoreMeetingDecisionReportCommentModel;
  }
}
