import { Component, Input } from '@angular/core';
import { UserModelEssentials } from '@wedecide/models';
import { UserAvatarService } from '@wedecide/shared/services/userAvatar.service';

@Component({
  selector: 'wd-user-avatar-and-name',
  templateUrl: './user-avatar-and-name.component.html',
  styleUrls: ['./user-avatar-and-name.component.scss'],
})
export class UserAvatarAndNameComponent {
  @Input() user: UserModelEssentials;
  @Input() background: string = null;
  @Input() lightText = true;

  get image() {
    if (this.background) {
      return this.userAvatarService.getAvatar(this.user, this.background.replace('#', ''));
    }
    return this.userAvatarService.getAvatar(this.user);
  }

  constructor(private userAvatarService: UserAvatarService) {}
}
