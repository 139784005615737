
<div class="p-20">
  <div class="error-container">
    <h1 i18n="error_handling|Error; application error@@error_handling_application-error">Application Error <mat-icon class="wd-red">report_problem</mat-icon></h1>

    <div class="error-description" *ngIf="isProduction">
      <ng-container i18n="error_handling|Message: error detected@@error_handling_message_error-detected">Sorry, but we detected an error in the running application. </ng-container><br>
      <ng-container i18n="error_handling|Message: error information collected@@error_handling_message_information-collected">Corresponding Information has been collected, and our team will investigate.</ng-container><br>
      <small class="light-text m-t-10" i18n="error_handling|Message; reloading recommended@@error_handling_message_reloading-recommended">We recommend reloading the page to continue.</small>
    </div>

    <div class="error" *ngIf="!isProduction">
      <p *ngIf="!data.error" i18n="error_handling|Message; unkown error occured check logs@@error_handling_message_unkown-error-occured-check-logs">An unknown error occured. Check the logs</p>
      <p *ngIf="data.error"i18n="error_handling|Message; unkown error occured@@error_handling_message_unkown-error-occured" >
        An unhandled Error occurred.
        <br>
        <code class="wd-red">
          {{ shortenedErrorMessage }}
        </code>
        <br>
        <code>
          {{ shortenedStack }}
        </code>
      </p>
    </div>
  </div>

  <div class="m-t-20">
    <button mat-flat-button color="primary" class="m-10" (click)="reload()" i18n="general|Label; reload page@@general_label_reload-page">Reload Page</button>
    <button mat-button color="primary" class="m-10" (click)="continue()" i18n="general|Label; continue@@general_label_continue">Continue</button>
  </div>
</div>


