<div fxLayout="row">
  <div class="p-20" fxFlex="66" fxFlexOffset="15" fxFlexOffset.lt-sm="0" fxFlex.lt-sm="100">
    <div class="error-container">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="error-code m-b-20">
        <mat-icon class="wd-red">error_outline</mat-icon>
        <div class="wd-red">404</div>
      </div>
      <h1 i18n="error_handling|Error; page not found@@error_handling_error_page-not-found">Page Not Found</h1>

      <div class="error-description" i18n="error_handling|Message; content not available@@error_handling_message_content-not-available">
        Sorry, but the content you requested is not available under this URL.
      </div>
    </div>
    <div class="further-information m-t-20">
      <h2><ng-container i18n="error_handling|Headline; Common issues for broken links@@error_handling_headline_common-issues-for-broken-links">Common issues that result in broken links</ng-container></h2>
      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxLayoutGap="30px">
        <div fxFlex="25" fxFlex.lt-md="100" class="p-b-30">
          <h3 i18n="error_handling|the content has been removed@@error_handling_content-removed">The content has been removed</h3>
          <p class="light-text"i18n="error_handling|Message; content has been deleted@@error_handling_message_content-has-been-deleted">Another user might have deleted the content or file you are trying to access. The URL is no longer available anymore.</p>
        </div>
        <div fxFlex="25" fxFlex.lt-md="100" class="p-b-30">
          <h3 i18n="error_handling|typos or truncated url@@error_handling_typos-or-truncated-url">Typos or truncated URLs</h3>
          <p class="light-text"
            i18n="error_handling|Message; typos or truncated url@@error_handling_message_typos-or-truncated-url">There might be a typo or wrong characters in your URL. Please verify that the URL is entered correctly, also consider case-sensitivity.</p>
        </div>
        <div fxFlex="25" fxFlex.lt-md="100" class="p-b-30">
          <h3 i18n="error_handling|outdated content@@error_handling_outdated-content">Outdated content</h3>
          <p class="light-text" i18n="error_handling|Message; outdated content@@error_handling_message_outdated-content">The page or file you were trying to access may have been moved to another location or removed because the information is no longer valid.</p>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
</div>
