<h1>Privacy Policy</h1>
<p>In the course of our activities and in order to provide our service, we (APOLLO.AI) collect and process personal data
  (hereinafter referred to as "Data") of our users of Apollo (hereinafter referred to as "Users").</p>

<p>This Privacy Policy is intended to provide users with a brief general overview of APOLLO.AI's processing of their
  data within Apollo (henceforth referred to as the "Application").</p>

<p>
  APOLLO.AI attaches great importance to the protection and privacy of its users and to the protection of the
  confidentiality, availability and integrity of their personal data and undertakes to process the data in particular
  compliance with the applicable laws and regulations, in particular the European Data Protection Regulation (Regulation
  (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016, on the protection of individuals with
  regard to the processing of personal data and on the free movement of such data - hereinafter referred to as "GDPR").
</p>

<h2 id="contact-information">Contact Information</h2>
<p>If you have any questions regarding the security of your data or data protection, or if you would like to make use of
  the <a (click)="scrollToId('information-about-rights')">data subject rights</a> under the GDPR, we are at your
  disposal at the following contact:</p>
<p>
  Apollo.ai GmbH<br />
  Poschacherstraße 23<br />
  4020 Linz<br />
  E-mail: <a href="mailto:privacy@apollo.ai">privacy@apollo.ai</a>
</p>

<h2 id="legal-basis">Legal basis for data processing</h2>
<p>Data is processed exclusively with the consent of the users (according to DSGVO Art. 6 para. 1 let. a). Acceptance of
  this Privacy Policy constitutes consent for us to process your personal data for the <a
    (click)="scrollToId('purposes-of-processing')">purposes indicated.</a></p>
<p>Furthermore, APOLLO.AI also acts as a data processor, provided that you have concluded a data processing agreement
  (according to GDPR Art. 28 (3)) with us. If, for example, your organization has concluded such a contract with us,
  this serves as the legal basis for the data processing. In this case, your organization is responsible for your
  personal data and APOLLO.AI is a processor of your organization. If you would like more information about this, please
  contact us at the address in the <a (click)="scrollToId('contact-information')">contact information</a> section or
  contact the data protection officer of your organization.
</p>


<h2 id="purposes-of-processing">Purposes of data processing</h2>
<p>The application is a software for mapping decisions at top management level. For this purpose, data from meetings and
  projects (agenda, meeting documents, enclosures, motions, minutes, etc.) are transmitted by the users. The subject of
  the processing of personal data are the following types/categories of data, hereinafter also referred to as "data":
</p>
<ul>
  <li>Name (first name, last name, display name)</li>
  <li>Master data (e.g. function in the company)</li>
  <li>Contact data (e.g. telephone, e-mail)</li>
  <li>Photos (e.g. of the person's face)</li>
  <li>Voting data for circular resolutions</li>
  <li>Personal comments (e.g. notes on documents)</li>
  <li>Data about presence and absence at meetings</li>
  <li>Security-related data (IP address, login timestamp)</li>
  <li>Usage data (e.g. click behavior to improve the application)</li>
</ul>
<p>In general, users have the possibility to upload various documents in the application or enter text in free text
  fields. This data is stored in order to be able to use it in the context of the application. All data provided to us
  by the users is thus processed.</p>
<p>The data is processed for the following purposes:</p>
<ul>
  <li>To fulfill our service as meeting and decision management software.</li>
  <li>To be able to detect and retroactively track security incidents.</li>
  <li>To be able to recover data in the event of a security incident.</li>
  <li>To use usage data to improve and enhance the functionality of the application.</li>
  <li>To provide the best possible customer service and support.</li>
  <li>To send e-mails to inform users about security-related events, changes in our service, or changes in this Privacy
    Policy.</li>
  <li>In addition, we may use their e-mail address and their data for marketing purposes, but for this purpose, explicit
    consent is obtained from users.</li>
</ul>
<p>The collection of your personal data is essential for the fulfillment of our services. Therefore, if you do not give
  your consent to provide the requested information, our services cannot be implemented.</p>
<p>If you have any questions about this, please feel free to contact us at the address in the <a
    (click)="scrollToId('contact-information')">contact information</a> section.</p>

<h2 id="recipients-of-data">Recipients of personal data</h2>
<p>Data may be transferred to subcontractors within the scope of processing. Subcontractors process data only for the
  purpose of providing the Service and on a contractual basis.</p>
<p>Personal Data may be transferred to a country inside or outside the European Union as part of the processing.
  APOLLO.AI establishes safeguards to ensure the protection and security of this transferred data, in accordance with
  the GDPR.</p>

<h2 id="duration-of-data-storage">Duration of data storage</h2>
<p>Data will be stored only as long as they are needed for the <a (click)="scrollToId('purposes-of-processing')">stated
    purposes</a>. Data can be archived, but only under the conditions provided for by the GDPR. In principle, data is
  only stored and processed with the consent of the user and also deleted on instruction of the user.</p>

<h2 id="information-about-rights">Information about the data subject rights</h2>
<p>As a user, you have the right to make use of the data subject rights that are an integral part of the GDPR. In terms
  of the GDPR, you count as a data subject if personal data concerning you is processed by us. Your rights include the
  right of access (Article 15 GDPR), the right to rectification (Article 16 GDPR), the right to be forgotten (Article 17
  GDPR), the right to restriction of processing (Article 18 GDPR), the right to object (Article 21 GDPR), the right to
  lodge a complaint with a supervisory authority (Article 77 GDPR) and the right to data portability (Article 20 GDPR).
</p>
<p>If you wish to make use of these rights, please contact the data protection officer of your organization or the
  address in the <a (click)="scrollToId('contact-information')">contact information</a> section.</p>

<h2 id="cookies">Cookies</h2>
<p>The Apollo application uses cookies and other techniques such as "local storage" to provide the service (described <a
    (click)="scrollToId('purposes-of-processing')">here</a>). Cookies and the local storage are techniques that can
  store user data in the user's browser (i.e., on the device from which the application was accessed) in order to
  provide certain functionalities of the application. For example, it is stored in the browser whether you have already
  accepted the use of cookies, so that the application does not have to ask again at each browser restart.</p>
<p>Cookies are divided into "Strictly Necessary Cookies", without which the application would not work at all, and
  "Performance Cookies", which are used to measure user behavior anonymously, so that we can continuously improve the
  application. There are also "Marketing Cookies", which are used to track specific user behaviour on the site to provide
  the best personalized experience we can.</p>
<p>The following data is stored in cookies or local storage:</p>

<table style="width:100%">
  <tr>
    <th>Cookie Name</th>
    <th>Type</th>
  </tr>
  <tr>
    <td>cookies</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>firebase:authUser:*</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>groupFilter-dashboard</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>ndaCheck</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>performanceCookies</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>marketingCookies</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>__cf_bm</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>__hs_cookie_cat_pref</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>__hs_do_not_track</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>__hs_initial_opt_in</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>__hs_opt_out</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>_ga</td>
    <td>Performance Cookies</td>
  </tr>
  <tr>
    <td>_ga_*</td>
    <td>Performance Cookies</td>
  </tr>
  <tr>
    <td>__hssc</td>
    <td>Marketing Cookies</td>
  </tr>
  <tr>
    <td>__hssrc</td>
    <td>Marketing Cookies</td>
  </tr>
  <tr>
    <td>__hstc</td>
    <td>Marketing Cookies</td>
  </tr>
  <tr>
    <td>hubspotutk</td>
    <td>Marketing Cookies</td>
  </tr>
  <tr>
    <td>messagesUtk</td>
    <td>Marketing Cookies</td>
  </tr>
  <tr>
    <td>messagesUtk</td>
    <td>Marketing Cookies</td>
  </tr>
</table>
<p class="note">(* means that any string can follow)</p>
<p>If you want to change your privacy settings click <a (click)="openSettingsDialog()">here</a></p>

<h2 id="security">Security</h2>
<p>APOLLO.AI has taken measures to ensure the achievement of the protection goals according to the GDPR Art. 23 para. 1
  (confidentiality, integrity, availability and resilience). On the one hand, these are technical and organizational
  measures that are implemented within the own company (APOLLO.AI) and its own employees, and on the other hand,
  measures that are implemented by recognized subcontractors. There is a contractual basis for the processing of data
  between all subcontractors and APOLLO.AI. Subcontractors have been selected based on evidence of an appropriate level
  of security, such as certificates.</p>

<p>The measures include, but are not limited to:</p>
<ul>
  <li>Physical access control</li>
  <li>Logical Access control (RBAC)</li>
  <li>Encryption (HTTPS for the transmission of data and AES for the storage of data at rest).</li>
  <li>All technologies used are state of the art</li>
  <li>The data centers used have security certifications (e.g. ISO/IEC 27001)</li>
  <li>Backups</li>
  <li>Security relevant data is being logged to be able to react in case of an emergency</li>
  <li>Software tests</li>
</ul>