import { MeetingMinutesState, MeetingAgendaState, ID } from '@wedecide/models';

import { AlgoliaACL, BaseModel, BaseModelData, FirestoreTimestamp } from './base.model';
import { ACL } from './base.model';
import { GroupModelEssentials } from './group.model';
import { AttendanceDict, MeetingAttachmentModel } from './meeting.model';
import { UserModelEssentials, UserReference } from './user.model';

/**
 * updates agenda item in minutes editor
 */
export type AgendaItemUpdate = {
  id: string;
  minutes?: string;
  sharedWith?: UserModelEssentials[];
  todos?: AgendaTodoItem[];
};

/**
 * currently defined states of decisions
 */
export enum AgendaItemType {
  INFO = 'info',
  DECISION = 'decision',
  ORGA = 'orga',
  GROUP = 'group',
}

export interface AccessFromDecision {
  id: ID;
  type: 'group' | 'user';
  decision: ID;
}

export interface AgendaItemModelEssentials {
  /**
   * agenda item id
   */
  id: ID;

  /**
   * agenda item title
   */
  title: string;

  /**
   * agenda item description
   */
  description?: string;

  /**
   * agenda item duration
   */
  duration: number;

  /**
   * agenda item group
   */
  group?: GroupModelEssentials;
}

export interface AgendaItemData<DateType = Date> extends BaseModelData<DateType> {
  /**
   * title of this agenda item
   */
  title: string;

  /**
   * description of this agenda item
   */
  description: string;

  /**
   * agenda item type
   */
  type: AgendaItemType;

  /**
   * who submitted that agenda item
   */
  submittedBy: string;

  /**
   * order of this agenda item on the agenda
   */
  order: number;

  /**
   * duration of this agenda item in milliseconds
   */
  duration: number;

  /**
   * guests on this agenda item
   */
  guests?: (string | UserReference)[];

  /**
   * meeting minutes for this agenda item
   */
  minutes: string;

  /**
   * a list of linked attachments (`documents` in customer-facing views)
   * (embbeded subcollection)
   */
  attachments?: MeetingAttachmentModel<DateType>[];

  /**
   * meeting checklist todos
   */
  todos?: AgendaTodoItem<DateType>[];

  /**
   * holds the attendance for agenda item guests
   * guests get a pseudo-id of the form `${agendaItemId}/${guestName}`
   */
  attendance?: AttendanceDict;

  /**
   * child of agenda item group
   */
  parent?: string;

  /**
   * children (group agenda item)
   */
  children?: string[];

  accessFromDecision?: AccessFromDecision[];

  organizationId?: string;

  /**
   * request documents from meeting participants
   * todo: check if this field ist needed
   */
  requestedDocumentsFrom?: RequestedDocumentsFromDict;

  /*
   * agenda state from meeting
   */
  sharedWith?: UserModelEssentials[];
}


export type RequestedDocumentsFromDict = {
  [uid: string]: UserReference;
};

export interface AgendaTodoItem<DateType = Date> {
  id: string;
  /**
   * todo item done
   */
  done: boolean;

  deleted: boolean;

  /**
   * todo item description as markdown string
   */
  description: string;

  /**
   * todo item assign to users
   */
  assignedTo?: UserReference[];

  /**
   * todo - add dueDate
   * todo item due on
   */
  dueDate?: DateType;
}

export interface AgendaItemModel extends AgendaItemData, BaseModel<FirestoreAgendaItemModel> {}

export interface FirestoreAgendaItemModel
  extends AgendaItemData<FirestoreTimestamp>,
    BaseModelData<FirestoreTimestamp> {
  /**
   * defines the ACL for this decision and which users do have access to the decision
   */
  acl: ACL;
}

export interface AlgoliaAgendaItemModel extends AgendaItemData<number>, BaseModelData<number> {
  /**
   * defines the ACL for this decision and which users do have access to the decision
   * we need this in order to support client-specific Algolia tokens
   */
  acl: AlgoliaACL;

  /**
   * id of the meeting this agenda item is part of
   */
  meetingId: string;

  /**
   * title of the meeting this agenda item is part of
   */
  meetingTitle: string;

  /**
   * start date of the meeting in unix time
   */
  meetingStartDate: number;

  /**
   * whether or not the meeting is in draft status
   */
  meetingIsDraft: boolean;

  /**
   *  whether or not the meeting is final
   */
  meetingIsFinal: boolean;

  /**
   * keep a reference to the group this agenda item is part of
   */
  group: GroupModelEssentials;

  /**
   * meeting todos
   */
  todos: AgendaTodoItem<number>[];

  /**
   * agenda start date in unix time
   */
  start?: number;

  /**
   * agenda end date in unix time
   */
  end?: number;

  /**
   * displayed agenda index as string
   */
  displayedIndex?: string;

  /**
   * agenda item attachments
   */
  attachments?: MeetingAttachmentModel<number>[];

  /**
   * minutes state from agenda item
   */
  minutesState?: MeetingMinutesState;

  /**
   * agenda state from meeting
   */
  agendaState?: MeetingAgendaState;
}

export enum TodoType {
  GLOBAL = 'global',
  MEETING = 'meeting',
  DECISION = 'decision',
}

export interface AlgoliaTodoModel {
  id: string;
  type: TodoType;
  description: string;
  dueDate: number;
  hasDueDate?: boolean; // for querying objects without due date
  assignedTo: UserModelEssentials[];

  done: boolean;
  deleted: boolean;

  /**
   * defines the ACL for this decision and which users do have access to the decision
   * we need this in order to support client-specific Algolia tokens
   */
  acl: ACL;

  organizationId: string;

  /**
   * id of the meeting this agenda item is part of
   */
  meetingId?: string;

  /**
   * agenda item information
   */
  agendaItem?: {
    id: string;
    title: string;
  };

  /**
   * title of the meeting this agenda item is part of
   */
  meetingTitle?: string;

  /**
   * keep a reference to the group this agenda item is part of
   */
  group: GroupModelEssentials;
}
