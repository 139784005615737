import { Injectable } from '@angular/core';
import { filter, first, firstValueFrom, map, Observable, pairwise, startWith } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { User, UserModelEssentials } from '@wedecide/models';
import { Router } from '@angular/router';

export interface ImpersonationInfo {
  id: string;
  readonly: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ImpersonationService {
  constructor(
    private auth: AngularFireAuth,
    private authService: AuthService,
    private userService: UserService,
    private functions: AngularFireFunctions,
    private router: Router,
  ) {
    this.setupImpersonationReloadListener();
  }

  /**
   * reload the page even if impersonation happens in another tab
   */
  private setupImpersonationReloadListener() {
    this.authService.user$.pipe(pairwise()).subscribe((values) => {
      const [current, previous] = values;

      // user is logged in
      if (current && previous) {
        // impersonation changes
        if ((current.claims.imp && !previous.claims.imp) || (!current.claims.imp && previous.claims.imp)) {
          window.location.href = '/dashboard';
        }
      }
    });
  }

  /**
   * Switches to another user account by exchanging the auth token
   * if the user has permission.
   * @param id
   * @returns
   */
  impersonate(id: string): Promise<any> {
    return this.router.navigateByUrl('/switch-user', { skipLocationChange: true }).then(() => {
      return firstValueFrom(this.functions.httpsCallable('impersonateUser')({ impersonationId: id }))
        .then((res) => {
          if (res.token) {
            return this.auth.signInWithCustomToken(res.token).then(() => {
              // impersonationReloadListener will take care of the reload
            });
          } else {
            return Promise.reject();
          }
        })
        .catch((err) => {
          this.router.navigateByUrl('/dashboard');
          return Promise.reject(err);
        });
    });
  }

  get impersonatedBy$(): Observable<ImpersonationInfo> {
    return this.authService.user$.pipe(
      map((user) => {
        return user?.claims?.imp || null;
      }),
    );
  }

  getImpersonatingUserRef(): Promise<UserModelEssentials> {
    return firstValueFrom(this.impersonatedBy$.pipe(first())).then((info) => {
      if (!info || !info.id) {
        return null;
      } else {
        return this.userService.getUserReference(info.id, this.authService.currentOrganizationId);
      }
    });
  }
}
