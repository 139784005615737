import { KeyValuePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
  DEBUG_MODE,
} from '@angular/fire/compat/analytics';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { USE_EMULATOR as USE_STORAGE_EMULATOR } from '@angular/fire/compat/storage';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/compat/performance';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MdePopoverModule } from '@material-extended/mde';
import { ApolloSpinnerModule } from '@wedecide/shared/modules/apollo-spinner/apollo-spinner.module';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthActionComponent } from './auth/action/auth-action.component';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { LoginComponent } from './auth/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { GlobalErrorHandler } from './error-handler';
import { ErrorHandlingModule } from './error-handling/error-handling.module';
import { ImprintModule } from './imprint/imprint.module';
import { PrivacyModule } from './privacy/privacy.module';
import { TrialEndedComponent } from './shared/dialogs/trial-ended.component';
import { FullLayoutComponent } from './shared/layout/full-layout.component';
import { NavbarComponent } from './shared/layout/navbar/navbar.component';
import { SidenavComponent } from './shared/layout/sidenav/sidenav.component';
import { DialogModule } from './shared/modules/dialog/dialog.module';
import { NetworkStateComponent } from './shared/modules/network-state/network-state.component';
import { NotificationWidgetModule } from './shared/modules/widgets/notification-widget/notification-widget.module';
import { SwitchUserComponent } from './auth/switch-user/switch-user.component';
import { UserAvatarAndNameModule } from './shared/modules/user-avatar-and-name/user-avatar-and-name.module';

import { PipeModule } from './shared/pipes/pipe.module';

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();
  const linkRenderer = renderer.link;

  renderer.link = (href, title, text) => {
    const html = linkRenderer.call(renderer, href, title, text);
    // add http if not available
    const url = !href.startsWith('http') ? 'http://' + href : href;
    return html.replace(/^<a /, '<a href="' + url + '" target="_blank"');
  };
  return {
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false,
  };
}

@NgModule({
  declarations: [
    // eslint-disable-next-line prettier/prettier
    AppComponent,
    NavbarComponent,
    SidenavComponent,
    LoginComponent,
    FullLayoutComponent,
    NetworkStateComponent,
    LogoutComponent,
    TrialEndedComponent,
    AuthActionComponent,
    ForgetPasswordComponent,
    SwitchUserComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ApolloSpinnerModule,
    UserAvatarAndNameModule,

    NotificationWidgetModule,
    HttpClientModule,

    // Firebase modules
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireFunctionsModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFirePerformanceModule,

    // material design modules
    MatFormFieldModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatButtonModule,
    MatInputModule,
    MatButtonToggleModule,
    MatIconModule,
    MatDividerModule,
    MatGridListModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatTooltipModule,
    MatCheckboxModule,
    MdePopoverModule,
    MatBadgeModule,

    FlexLayoutModule,
    ErrorHandlingModule,

    PrivacyModule,
    ImprintModule,

    DialogModule,
    PipeModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      },
    }),
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    PerformanceMonitoringService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: DEBUG_MODE, useValue: false },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    { provide: REGION, useValue: 'europe-west1' },
    {
      provide: SETTINGS,
      useValue: environment.emulators?.firestore
        ? {
            host: 'localhost:8080',
            ssl: false,
          }
        : undefined,
    },
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.emulators?.auth ? ['http://localhost:9099'] : undefined,
    },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.emulators?.firestore ? ['localhost', 8080] : undefined,
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.emulators?.functions ? ['localhost', 5001] : undefined,
    },
    {
      provide: USE_STORAGE_EMULATOR,
      useValue: environment.emulators?.storage ? ['localhost', 9199] : undefined,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
