<h1>
  Datenschutz-Richtlinie
</h1>
<p>
  Im Rahmen unserer Tätigkeit und zur Erbringung unserer Dienstleistung erheben und verarbeiten wir (APOLLO.AI)
  personenbezogene Daten (fortan “Daten” genannt) unserer Nutzer von Apollo (fortan “Nutzer” genannt).
</p>
<p>
  Diese Datenschutzrichtlinie soll den Nutzern eine kurze allgemeine Übersicht der Verarbeitung ihrer Daten durch
  APOLLO.AI im Rahmen von Apollo (fortan “Applikation” genannt) geben.
</p>
<p>
  APOLLO.AI legt großen Wert auf den Schutz und die Privatsphäre seiner Nutzer und den Schutz der Vertraulichkeit,
  Verfügbarkeit und Integrität ihrer persönlichen Daten und verpflichtet sich, die Daten unter besonderer Beachtung der
  geltenden Gesetze und Bestimmungen, insbesondere der Europäischen Datenschutz-Grundverordnung (Verordnung (EU)
  2016/679 des Europäischen Parlaments und des Rates vom 27. April 2016, zum Schutz natürlicher Personen bei der
  Verarbeitung personenbezogener Daten und zum freien Datenverkehr - fortan “DSGVO” genannt), zu verarbeiten.
</p>



<h2 id="contact-information">
  Kontaktdaten
</h2>
<p>
  Wenn Sie Fragen zur Sicherheit ihrer Daten oder zum Datenschutz haben, oder die <a
    (click)="scrollToId('information-about-rights')">
    Betroffenenrechte nach der DSGVO
  </a>
  in Anspruch nehmen möchten, stehen wir ihnen unter folgendem Kontakt zur Verfügung:
</p>

<p>
  Apollo.ai GmbH<br />
  Poschacherstraße 23<br />
  4020 Linz<br />
  E-mail: <a href="mailto:privacy@apollo.ai">
    privacy@apollo.ai
  </a>
</p>


<h2 id="legal-basis">
  Rechtsgrundlage für die Datenverarbeitung
</h2>
<p>
  Daten werden ausschließlich mit einer Einwilligung der Nutzer verarbeitet (gemäß DSGVO Art. 6 Abs. 1 Bst. a). Das
  Akzeptieren dieser Datenschutzrichtlinie gilt als Einwilligung, damit wir Ihre personenbezogenen Daten für die
  angeführten <a (click)="scrollToId('purposes-of-processing')">Zwecke</a> verarbeiten dürfen.
</p>
<h2 id="purposes-of-processing">
  Zwecke der Datenverarbeitung
</h2>

<p>
  Apollo ist eine Software zur Abbildung von Entscheidungen auf Top-Management-Ebene. Dafür werden von den Nutzern Daten
  von Meetings und Projekten (Agenda, Sitzungsunterlagen, Beilagen, Anträge, Protokolle, etc.) übermittelt. Gegenstand
  der Verarbeitung personenbezogener Daten sind folgende Datenarten/-kategorien, im Folgenden auch „Daten“:</p>
<ul>
  <li>
    Name (Vorname, Nachname, Anzeigename)
  </li>
  <li>
    Personenstammdaten (z.B. Funktion im Unternehmen)
  </li>
  <li>
    Kontaktdaten (z.B. Telefon, E-Mail)
  </li>
  <li>
    Fotos (zB. des Gesichts der Person)
  </li>
  <li>
    Daten über An- und Abwesenheit bei Meetings
  </li>
  <li>
    Stimmabgabe bei Umlaufbeschlüssen
  </li>
  <li>
    Persönliche Kommentare (zB. zu Dokumenten)
  </li>
  <li>
    Sicherheitsrelevante Daten (IP-Adresse, Login-Zeitstempel)
  </li>
  <li>
    Nutzungsdaten (z.B. Klickverhalten zur Verbesserung der Applikation)
  </li>
</ul>


<p>
  Generell haben die Nutzer die Möglichkeit, verschiedene Dokumente in der Applikation hochzuladen oder Text in
  Freitextfelder einzugeben. Diese Daten werden gespeichert um sie im Kontext der Applikation verwenden zu können. Alle
  Daten die uns von den Nutzern zur Verfügung gestellt werden, werden also verarbeitet.
</p>
<p>
  Die Daten werden für folgende Zwecke verarbeitet:
</p>
<ul>
  <li>
    Um unseren Service als Meeting- und Entscheidungsmanagement Software erfüllen zu können.
  </li>
  <li>
    Um Sicherheitsrelevante Vorfälle erkennen und rückwirkend nachvollziehen zu können.
  </li>
  <li>
    Um bei einem Sicherheitsvorfall Daten wiederherstellen zu können.
  </li>
  <li>
    Um mit Hilfe von Nutzungsdaten die Applikation in ihrer Funktionalität verbessern und erweitern zu können.
  </li>
  <li>
    Um bestmöglichen Kundenservice und Support zu ermöglichen.
  </li>
  <li>
    Um E-Mails zu versenden mit denen die Nutzer über sicherheitsrelevante Ereignisse, Änderung in unserem Service oder
    Änderungen in dieser Datenschutzrichtlinie informiert werden können. Zusätzlich können wir ihre E-mail Adresse für
    Marketingzwecke verwenden, für diesen Zweck wird jedoch explizit eine Einwilligung der Nutzer eingeholt.
  </li>
</ul>

<p>Die Erhebung Ihrer personenbezogenen Daten ist für die Erfüllung unserer Serviceleistungen unabdingbar. Geben Sie
  also keine Einwilligung die angeforderten Informationen zur Verfügung zu stellen, sind unsere Servicedienstleistungen
  nicht umsetzbar.</p>
<p>Wenn Sie hierzu Fragen haben, wenden Sie sich gerne an die Adresse im Abschnitt <a
    (click)="scrollToId('contact-information')">Kontaktdaten</a>.</p>

<h2 id="recipients-of-data">
  Empfänger personenbezogener Daten
</h2>
<p>
  Daten können im Rahmen der Verarbeitung Unterauftragnehmern übermittelt werden. Unterauftragnehmer verarbeiten Daten
  nur zum Zwecke der Erbringung des Dienstes und auf Vertraglicher Basis.</p>
<p>
  Die personenbezogenen Daten können im Rahmen der Verarbeitung in ein Land innerhalb oder außerhalb der europäischen
  Union weitergeleitet werden. APOLLO.AI richtet Garantien ein, um den Schutz und die Sicherheit dieser übermittelten
  Daten, in Übereinstimmung mit der DSGVO, zu gewährleisten.</p>


<h2 id="duration-of-data-storage">
  Dauer der Datenspeicherung
</h2>
<p>
  Daten werden nur solange gespeichert, wie sie für die angeführten <a
    (click)="scrollToId('purposes-of-processing')">Zwecke</a> benötigt werden. Daten können archiviert werden, jedoch
  nur unter den Bedingungen die laut der DSGVO vorgesehen sind. Grundsätzlich werden Daten nur mit Einwilligung der
  Nutzer gespeichert und verarbeitet und auch auf anweisung der Nutzer gelöscht.</p>


<h2 id="information-about-rights">
  Hinweis auf die Betroffenenrechte
</h2>

<p>
  Als Nutzer haben Sie das Recht, gebrauch von den Betroffenenrechten zu machen, die in der DSGVO verankert sind. Im
  Sinne der DSGVO zählen Sie als Betroffener, wenn personenbezogene Daten, die Sie betreffen, von uns verarbeitet
  werden. Hierbei handelt es sich um das Auskunftsrecht (Artikel 15 DSGVO), das Recht auf Berichtigung (Artikel 16
  DSGVO), das Recht auf Löschung (Artikel 17 DSGVO), das Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO),
  das Widerspruchsrecht (Artikel 21 DSGVO), das Recht auf Beschwerde bei einer Aufsichtsbehörde (Artikel 77 DSGVO) sowie
  das Recht auf Datenübertragbarkeit (Artikel 20 DSGVO). </p>
<p>
  Wenn sie Gebrauch von diesen Rechten machen wollen, so wenden sie sich bitte an die Adresse im Abschnitt <a
    (click)="scrollToId('contact-information')">Kontaktdaten</a>.
</p>


<h2 id="cookies">
  Cookies
</h2>
<p>
  Die Applikation Apollo verwendet Cookies und andere Techniken wie den “Local Storage” zur um <a
    (click)="scrollToId('legal-basis')">
    Erbringung des Dienstes
  </a>
  .
  Cookies und der Lokale Speicher sind Techniken, welche Nutzerdaten im Browser des Nutzers (also auf dem Endgerät von
  welchem aus die Applikation aufgerufen wurde), speichern können, um gewisse Funktionalitäten der Applikation zu
  gewährleisten. Zum Beispiel wird im Browser gespeichert, ob Sie die Verwendung von Cookies bereits akzeptiert haben,
  damit die Applikation nicht bei jedem Browser Neustart erneut fragen muss.
</p>
<p>
  Cookies werden unterteilt in "Strictly Necessary Cookies", ohne die die Anwendung überhaupt nicht funktionieren würde,
  und "Performance Cookies", die dazu dienen, das Nutzerverhalten anonym zu messen, damit wir die Anwendung
  kontinuierlich verbessern können. Es gibt auch "Marketing Cookies", die verwendet werden, um ein bestimmtes
  Nutzerverhalten auf der Website zu verfolgen, damit wir eine bestmögliche und personalisierte Erfahrung beim Benutzen
  der Applikation bieten können.</p>
<p>
  Folgende Daten werden In Cookies oder im Lokalen Speicher gespeichert:
</p>

<table style="width:100%">
  <tr>
    <th>Cookie Name</th>
    <th>Type</th>
  </tr>
  <tr>
    <td>cookies</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>firebase:authUser:*</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>groupFilter-dashboard</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>ndaCheck</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>performanceCookies</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>marketingCookies</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>__cf_bm</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>__hs_cookie_cat_pref</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>__hs_do_not_track</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>__hs_initial_opt_in</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>__hs_opt_out</td>
    <td>Strictly Necessary Cookies</td>
  </tr>
  <tr>
    <td>_ga</td>
    <td>Performance Cookies</td>
  </tr>
  <tr>
    <td>_ga_*</td>
    <td>Performance Cookies</td>
  </tr>
  <tr>
    <td>__hssc</td>
    <td>Marketing Cookies</td>
  </tr>
  <tr>
    <td>__hssrc</td>
    <td>Marketing Cookies</td>
  </tr>
  <tr>
    <td>__hstc</td>
    <td>Marketing Cookies</td>
  </tr>
  <tr>
    <td>hubspotutk</td>
    <td>Marketing Cookies</td>
  </tr>
  <tr>
    <td>messagesUtk</td>
    <td>Marketing Cookies</td>
  </tr>
  <tr>
    <td>messagesUtk</td>
    <td>Marketing Cookies</td>
  </tr>
</table>
<p class="note">
  (ein * bedeutet, dass eine beliebige Zeichenfolge folgen kann)
</p>
<p>Wenn sie zu den Cookie einstellungen möchten, klicken sie bitte <a (click)="openSettingsDialog()">hier</a> </p>

<h2 id="security">
  Sicherheit
</h2>

<p>
  APOLLO.AI hat Maßnahmen getroffen um die Erreichung der Schutzziele gemäß der DSGVO Art. 23 Abs. 1 (Vertraulichkeit,
  Integrität, Verfügbarkeit und Belastbarkeit) sicherzustellen. Dabei handelt es sich einerseits um technische und
  organisatorische Maßnahmen, die innerhalb des eigenen Unternehmens (APOLLO.AI) und der eigenen Mitarbeiter umgesetzt
  werden und andererseits um Maßnahmen, die von anerkannten Unterauftragnehmern umgesetzt werden. Zwischen allen
  Unterauftragnehmern und APOLLO.AI besteht eine vertragliche Basis zur Verarbeitung von Daten. Die Unterauftraggeber
  wurden anhand von Nachweisen über ein geeignetes Sicherheitsniveau, wie etwa Zertifikaten, ausgewählt.
</p>

<p>
  Die Maßnahmen enthalten unter Anderem:
</p>


<ul>
  <li>
    Physische Zutrittskontrolle
  </li>
  <li>
    Zugriffskontrolle
  </li>
  <li>
    Verschlüsselung (HTTPS für die Übertragung von Daten und AES zur Speicherung der Daten in Ruhe)
  </li>
  <li>
    Alle verwendeten Technologien sind auf dem Stand der Technik
  </li>
  <li>
    Die genutzten Datencenter haben Security Zertifizierungen (zB. ISO/IEC 27001)
  </li>
  <li>
    Backups
  </li>
  <li>
    Sicherheitsrelevante Daten werden aufgezeichnet (Logging) um bei einem Notfall reagieren zu können
  </li>
  <li>
    Software Tests
  </li>
</ul>