import { AttachmentModel } from './attachment.model';
import { BaseModel, BaseModelData, FirestoreTimestamp, ACL } from './base.model';
import { OrganizationReference } from './organization.model';
import { UserReference } from './user.model';

export enum MeetingDecisionReportState {
  ON_TRACK = 'on_track',
  OFF_TRACK = 'off_track',
  NEEDS_ATTENTION = 'needs_attention',
  IMPLEMENTED = 'implemented',
}

export interface MeetingDecisionReportProgress {
  value: number;
  previousValue?: number;
}

export interface EssentialMeetingDecisionReport<DateType = Date> extends BaseModelData<DateType> {
  /**
   * decision report state (on track, off track, needs attention)
   */
  state: MeetingDecisionReportState;
  /**
   * progress value
   */
  progress: MeetingDecisionReportProgress;
  /**
   * (optional) state description
   */
  stateDescription?: string;
  /**
   * report description
   */
  description: string;
  /**
   * number of comments on the report
   */
  commentCount: number;
  /**
   * report created by
   */
  createdBy: UserReference;
  /**
   * user that signed on behalf of real user
   */
  impersonatedBy?: UserReference;
}

export interface SharedMeetingDecisionReportModel<DateType = Date> extends EssentialMeetingDecisionReport<DateType> {
  /**
   * report acl
   */
  acl: ACL;

  /**
   * organization the group belongs to
   * will be implicit in the data structure due to the used firestore collection, e.g.: `orf_groups`
   */
  organization: OrganizationReference;

  /**
   * meeting decision id
   */
  decisionId: string;

  /**
   * agenda item id
   */
  agendaItemId: string;

  /**
   * meeting id
   */
  meetingId: string;

  /**
   * report attachments
   */
  attachments: AttachmentModel<DateType>[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MeetingDecisionReportModel
  extends SharedMeetingDecisionReportModel,
    BaseModel<FirestoreMeetingDecisionReportModel> {}

export type FirestoreMeetingDecisionReportModel = SharedMeetingDecisionReportModel<FirestoreTimestamp>;
