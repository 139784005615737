import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { GroupRole, UserModel, UserWithClaims, User, UserRegistrationState, DocumentStatus } from '@wedecide/models';
import firebase from 'firebase/compat';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subject } from 'rxjs';
import { filter, map, first, skipWhile, distinctUntilChanged, debounceTime, skip, tap } from 'rxjs/operators';

import { AuthClaimsService } from './auth-claims.service';
import { TrackingService } from './tracking.service';

export interface PasswordResetParams {
  email?: string;
  userId?: string;
}

export class AuthUser implements firebase.UserInfo, UserWithClaims {
  claims: { [key: string]: any };
  /**
   * The real firebase User property
   */
  private _firebaseUser: firebase.User;

  get uid() {
    return this._firebaseUser.uid;
  }

  get displayName() {
    return this._firebaseUser.displayName;
  }

  get email() {
    return this._firebaseUser.email;
  }

  get emailVerified() {
    return this._firebaseUser.emailVerified;
  }

  get phoneNumber() {
    return this._firebaseUser.phoneNumber;
  }

  get photoURL() {
    return this._firebaseUser.photoURL;
  }

  get providerId() {
    return this._firebaseUser.providerId;
  }

  get firebaseUser() {
    return this._firebaseUser;
  }

  constructor(firebaseUser: firebase.User, idTokenResult: firebase.auth.IdTokenResult) {
    this.claims = idTokenResult ? idTokenResult.claims : {};
    this._firebaseUser = firebaseUser;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUser: BehaviorSubject<AuthUser> = new BehaviorSubject(null);
  public userLoggedOut$: Subject<boolean> = new Subject();
  public disablePermissionChangeHandling = false;
  private _lastActivityTrackedAt: Date;

  /**
   * Notifies on serverside permission updates
   */
  private onPermissionChangeSub$: Subject<void> = new Subject();
  public onPermissionChange$: Observable<void> = this.onPermissionChangeSub$.asObservable();

  constructor(
    private auth: AngularFireAuth,
    private functions: AngularFireFunctions,
    private snackBar: MatSnackBar,
    private firestore: AngularFirestore,
    private trackingService: TrackingService,
    private authClaimsService: AuthClaimsService,
    private router: Router,
  ) {
    // this.listenForSessionTimeout(auth, this.currentUser);
    this.listenForUserChanges(auth);
    this.listenForServersidePermissionUpdates(this.currentUser);
    this.initUserActivityTracking();
  }

  get isAuthenticated() {
    return this.currentUser.value != null;
  }
  get user() {
    return this.currentUser.value;
  }
  /**
   * essential user fragment, including only user id and name
   */
  get essentialUserFragment() {
    return { id: this.user?.uid, name: this.user?.displayName };
  }

  get isAuthenticated$() {
    return this.currentUser.pipe(map((user) => !!user));
  }

  get user$() {
    return this.currentUser.pipe(skipWhile((user) => !user));
  }

  get currentOrganizationId() {
    return this.authClaimsService.currentOrganizationId(this.user);
  }

  get currentOrganizationId$() {
    return this.user$.pipe(
      map((u) => {
        return this.authClaimsService.currentOrganizationId(u);
      }),
    );
  }

  get currentUserHasWriteAccess() {
    return !this.user.claims?.imp || this.user.claims?.imp?.wac;
  }

  get currentUserHasReadAccess() {
    // readonly = !wac
    return !this.user.claims?.imp || !this.user.claims?.imp?.wac;
  }

  async verifyPasswordResetCode(code: string): Promise<any> {
    return await this.auth
      .verifyPasswordResetCode(code)
      .then((email) => {
        return email;
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  async confirmPasswordReset(code: string, newPassword: string): Promise<boolean> {
    return await this.auth
      .confirmPasswordReset(code, newPassword)
      .then(() => {
        return true;
      })
      .catch((error) => {
        console.error(error.message);
        // this.notifier.showError(error.message);
        return false;
      });
  }

  private listenForUserChanges(auth: AngularFireAuth) {
    const userChanges = combineLatest([auth.authState, auth.idTokenResult]);

    userChanges.subscribe({
      next: async (result) => {
        const fbUser = result[0];
        const idTokenResult = result[1];

        if (fbUser) {
          const user = new AuthUser(fbUser, idTokenResult);
          // localStorage.setItem('auth', JSON.stringify(fbUser));
          this.currentUser.next(user);
          this.trackingService.setUserId(user?.uid);
          this.trackingService.setUserProperties([user?.email, user?.displayName]);
          this.trackingService.setHubspotParams(user?.email, user?.uid, user?.displayName);
        } else {
          // localStorage.removeItem('auth');
          this.currentUser.next(null);
        }
      },
      error: (err) => this.currentUser.error(err),
      complete: () => this.currentUser.complete(),
    });
  }

  // todo move to own or other service ???
  private async initUserActivityTracking() {
    this.user$.subscribe((user) => {
      if (user) {
        this.trackLastActivity(user);
        this.router.events.subscribe((event: RouterEvent) => {
          if (event instanceof NavigationEnd) {
            this.trackLastActivity(user);
          }
        });
      }
    });
  }

  private async trackLastActivity(user: AuthUser) {
    const now = new Date();
    // only update last activity every 30s
    if (
      !this._lastActivityTrackedAt ||
      DateTime.fromJSDate(now).minus({ seconds: 30 }) > DateTime.fromJSDate(this._lastActivityTrackedAt)
    ) {
      const organizationId = this.authClaimsService.currentOrganizationId(user);
      if (organizationId) {
        this.firestore
          .doc<UserModel>(`/organizations/${organizationId}/users/${user.uid}`)
          .update({ lastActive: now, registrationState: UserRegistrationState.ACTIVE })
          .then(() => {
            this._lastActivityTrackedAt = now;
          })
          .catch((err) => {
            console.error('could not update last activity', err);
          });
      }
    }
  }

  private listenForServersidePermissionUpdates(currentUser: Observable<AuthUser>) {
    let currentClaimsTimestamp = new Date();
    currentUser
      .pipe(
        filter((user) => !!user && user.claims['organizations']),
        first(),
      )
      .toPromise()
      .then((user) => {
        const organizationId = this.authClaimsService.currentOrganizationId(user);
        this.firestore
          .doc<UserModel>(`/organizations/${organizationId}/users/${user.uid}`)
          .valueChanges({ idField: 'id' })
          .pipe(
            // to skip initial user after loading the page
            skip(1),
            distinctUntilChanged(isEqual),
            debounceTime(3000),
            map((data) => User.fromFirestoreData(data)),
          )
          .subscribe({
            next: (user) => {
              if ([DocumentStatus.DELETED, DocumentStatus.DISABLED].includes(user?.status)) {
                this.snackBar.open(
                  $localize`:auth|Snackbar; your user has been removed@@auth_snackbar_your-user-removed:Your user has been removed, please contact your admin for more information`,
                );
                this.logout();
              }
              // ignore changes if currentClaimsTimestamp is greater then claimsLastUpdatedAt
              if (currentClaimsTimestamp < user.claimsLastUpdatedAt) {
                this.auth.currentUser
                  .then((currentUser) => {
                    // refreshing token because of serverside user changes
                    return currentUser.getIdTokenResult(true).then((r) => {
                      // update current claims timestamp
                      currentClaimsTimestamp = user.claimsLastUpdatedAt;
                      this.onPermissionChangeSub$.next();
                    });
                  })
                  .finally(() => {
                    if (!this.disablePermissionChangeHandling) {
                      const snackBarRef = this.snackBar.open(
                        $localize`:auth|Snackbar; permissions updated reload page@@auth_permissions-updated-reload:Your permissons were updated - please reload the page`,
                        $localize`:general|reload@@general_reload:Reload`,
                        { duration: 0 },
                      );
                      snackBarRef.onAction().subscribe(() => {
                        window.location.reload();
                        snackBarRef.dismiss();
                      });
                    }
                  });
              }
            },
            error: (e) => {
              console.error('User permissions subscription failed:', e);
            },
          });
      });
  }

  sendPasswordResetLink(params: PasswordResetParams) {
    const callable = this.functions.httpsCallable('resetPassword');
    return callable(params).toPromise();
  }

  hasRoles(roles: string[]) {
    return this.authClaimsService.hasRoles(this.user, roles);
  }

  hasRolesInGroup(groupId: string, roles: GroupRole[]) {
    return this.authClaimsService.hasRolesInGroup(this.user, groupId, roles);
  }

  hasRolesInAnyGroup(roles: GroupRole[]) {
    return this.authClaimsService.hasRolesInAnyGroup(this.user, roles);
  }

  isAdmin() {
    return this.authClaimsService.isAdmin(this.user);
  }

  isSubAdmin() {
    return this.authClaimsService.isSubAdmin(this.user);
  }

  isMoreThanUser() {
    return this.authClaimsService.isMoreThanUser(this.user);
  }

  isBasicUser() {
    return this.authClaimsService.isBasicUser(this.user);
  }

  hasAccessToImportActions() {
    return this.authClaimsService.hasAccessToImportActions(this.user);
  }

  getGroups() {
    return this.authClaimsService.getGroups(this.user);
  }

  getGroupIds() {
    return this.authClaimsService.getGroupIds(this.user);
  }

  isGroupAdmin() {
    return this.authClaimsService.isGroupAdmin(this.user);
  }

  getGroupIdsWhereUserHasAnyGivenRoles(requiredRoles: GroupRole[]) {
    return this.authClaimsService.getGroupIdsWhereUserHasAnyGivenRoles(this.user, requiredRoles);
  }

  getManagedGroupIds() {
    return this.authClaimsService.getManagedGroupIds(this.user);
  }

  getAssistedGroupIds() {
    return this.authClaimsService.getAssistedGroupIds(this.user);
  }

  getDeciderGroupIds() {
    return this.authClaimsService.getDeciderGroupIds(this.user);
  }

  canManageGroups() {
    return this.authClaimsService.canManageGroups(this.user);
  }

  canManageUsers() {
    return this.authClaimsService.canManageUsers(this.user);
  }

  /* istanbul ignore next */
  login(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  /* istanbul ignore next */
  async logout() {
    localStorage.removeItem('redirectUrl');
    this.userLoggedOut$.next(true);
    return this.auth.signOut().then(() => {
      window.location.href =
        window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/login';
    });
  }
}
