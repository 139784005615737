<div class="auth-layout mat-app-background">
  <div class="privacy-policy-wrapper" fxLayout="column" fxLayoutAlign="space-around center">
    <div fxFlex="20px" fxFlex.gt-md="100px" fxFlex.gt-xs="100px" fxFill></div>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="header-row">
      <div class="back-icon-wrapper" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayoutGap.lt-sm="5px">
        <button type="button" mat-icon-button (click)="backClicked()" color="primary">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <img [src]="logo" width="130px" />
      </div>

      <div class="language-icon-wrapper" role="button" (click)="toggleLanguage()">
        <img class="png-icon" [src]="iconPath">
      </div>
    </div>
    <div class="policy-wrapper">
      <wd-privacy-policy-en *ngIf="isEnglish"></wd-privacy-policy-en>
      <wd-privacy-policy-de *ngIf="!isEnglish"></wd-privacy-policy-de>
    </div>
    <div fxFlex="20px" fxFill></div>
  </div>
</div>