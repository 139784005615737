import { UserReference } from './user.model';
import { FirestoreTimestamp } from './base.model';

export interface AttachmentModel<DateType = Date> {
  /**
   * label/name for/of the attachment
   */
  label: string;

  /**
   * the type of attachment
   */
  type: AttachmentType;

  /**
   * date the attachment was created
   */
  createdAt: DateType;

  /**
   * the size of attachment in bytes (only for files)
   */
  size?: number;

  /**
   * the URL pointing to the attachment
   * (either the storage location of a document or a linked webpage)
   */
  url?: string;

  /**
   * attachment file path (firebase storage)
   */
  filePath?: string;

  /**
   * uploaded by property
   */
  uploadedBy?: UserReference;
}

/**
 * right now only web-link attachments are supported
 */
export enum AttachmentType {
  LINK = 'link',
  FILE = 'file',
}

export type FirestoreAttachmentModel = AttachmentModel<FirestoreTimestamp>;
