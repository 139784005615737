import { Injectable } from '@angular/core';

enum themes {
  DARK = 'dark',
  LIGHT = 'light',
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private darkTheme = false;

  get isDarkThemeEnabled() {
    return this.darkTheme;
  }

  get logo() {
    return this.darkTheme ? 'assets/images/Apollo-dark.png' : 'assets/images/Apollo.png';
  }

  get svgLogo() {
    return this.darkTheme ? 'assets/images/apollo_dark_theme_logo.svg' : 'assets/images/apollo_light_theme_logo.svg';
  }

  checkTheme() {
    this.darkTheme = localStorage.getItem('theme') === themes.DARK;
    this.addThemeClasses();
  }

  changeTheme() {
    this.darkTheme = !this.darkTheme;
    localStorage.setItem('theme', this.darkTheme ? themes.DARK : themes.LIGHT);
    this.addThemeClasses();
  }

  private addThemeClasses() {
    const body = document.getElementsByTagName('body')[0];
    if (this.darkTheme) {
      body.classList.add('wedecide-dark-theme');
      body.classList.remove('wedecide-light-theme');
    } else {
      body.classList.add('wedecide-light-theme');
      body.classList.remove('wedecide-dark-theme');
    }
  }
}
