
<div class="set-password-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.lt-lg="start center">
  <div *ngIf="isLoading" fxLayoutAlign="center center" fxLayoutGap="10px">
    <wd-apollo-spinner size="40"></wd-apollo-spinner>
    <div>{{loadingText}}</div>
  </div>
  <div *ngIf="!isLoading">
    <div class="logo-wrapper" fxLayoutAlign="center center">
      <img [src]="logo" width="120px" />
    </div>
    <mat-card class="card">
      <form [formGroup]="resetPasswordForm" novalidate *ngIf="!codeExpired && !isLoading && !isPasswordReset && !newCodeRequested">
        <div *ngIf="initPassword">
          <p i18n="auth-action|welcome title@@auth-action_welcome_title">Welcome to Apollo!</p>
          <p i18n="auth-action|welcome message@@auth-action_welcome_message">First, you have to create a password for <b>{{email}}</b>:</p>
        </div>
        <p *ngIf="!initPassword" i18n="auth-action|label to update password@@auth-action_label_update-password">
          Update password for <b>{{email}}</b>:
        </p>
        <div fxLayout="column" fxFlexAlign="space-around center">
          <mat-form-field appearance="fill">
            <mat-label i18n="auth-action|password label@@auth-action_label_password">Password</mat-label>
            <input matInput type="password" formControlName="password" minlength="6" autocomplete="current-password" required>
            <mat-error *ngFor="let validation of resetPasswordValidation.password">
              <mat-error *ngIf="resetPasswordForm.get('password').hasError(validation.type) &&
                                (resetPasswordForm.get('password').dirty ||
                                resetPasswordForm.get('password').touched)">
                {{validation.message}}</mat-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label i18n="auth-action|confirm password label@@auth-action_label_confirm-password">Confirm password</mat-label>
            <input matInput type="password" formControlName="confirmpassword" minlength="6" autocomplete="current-password" required>
            <mat-error *ngFor="let validation of resetPasswordValidation.confirmpassword">
              <mat-error *ngIf="resetPasswordForm.get('confirmpassword').hasError(validation.type) &&
                                (resetPasswordForm.get('confirmpassword').dirty ||
                                resetPasswordForm.get('confirmpassword').touched)">
                {{validation.message}}</mat-error>
            </mat-error>
          </mat-form-field>
          <div class="wd-red p-b-10" *ngIf="displayPasswordsNotMatchError">
            <small i18n="auth-action|Error; passwords dont match@@auth-action_error_passwords-dont-match">Please confirm with the correct password. </small>
          </div>
          <button class="p-t-10 p-b-10" mat-flat-button color="primary" class="submit-button" aria-label="RESET"
            [disabled]="resetPasswordForm.invalid" (click)="confirmPasswordReset()">
            <ng-container *ngIf="initPassword" i18n="auth-action|Button; set password@@auth-action_button_set-password">Set password</ng-container>
            <ng-container *ngIf="!initPassword" i18n="auth-action|Button; reset password@@auth-action_button_reset-password">Reset password</ng-container>
          </button>
        </div>
      </form>
      <div *ngIf="codeExpired && !isLoading && !isPasswordReset && !newCodeRequested">
        <div>
          <b class="highlight" i18n="auth-action|Error; header@@auth-action_error_header">Sorry!</b><br>
          <span *ngIf="!initPassword" i18n="auth-action|Error; reset code invalid@@auth-action_error_reset-code-invalid">
            Looks like the reset code has already been used or has expired.
          </span>
          <span *ngIf="initPassword" i18n="auth-action|Error; invitation code invalid@@auth-action_error_invitation-code-invalid">
            Looks like your invitation code has already been used or has expired.
          </span>
        </div>
        <div class="m-t-20 light-text" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start center">
          <div i18n="auth-action|Error; request new code@@auth-action_error_request-new-code">No problem, request a new one:</div>
          <div>
            <button mat-raised-button color="primary" (click)="resendPasswordResetCode()">
              <ng-container *ngIf="initPassword" i18n="auth-action|Button; resend invitation code@@auth-action_button_resend-invitation-code">Send me an invitation code</ng-container>
              <ng-container *ngIf="!initPassword" i18n="auth-action|Button; resend password reset code@@auth-action_button_resend-reset-password-code">Send me a password reset code</ng-container>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="!isLoading && isPasswordReset && !newCodeRequested">
        <p i18n="auth-action|password changed message@@auth-action_text_password-changed">Your password has been successfully changed.</p>
      </div>
      <div *ngIf="!isLoading && !isPasswordReset && newCodeRequested">
        <p i18n="auth-action|auth link has been sent@@auth-action_text_auth-link-sent">
          A new {{ initPassword ? 'invitation' : 'password reset'}} link has been sent to you and is valid for 1 hour. <br>
          Please check your mailbox.
        </p>
      </div>
    </mat-card>
    <div *ngIf="!isLoading" class="p-15" fxLayoutAlign="center center" fxLayoutGap="5px">
      <small class="light-text" i18n="auth-action|login label@@auth-action_label_login">Already an account?</small>
      <small><a [routerLink]="'/login'" i18n="auth-action|login button@@auth-action_button_login">Login here</a></small>
    </div>
  </div>
</div>


