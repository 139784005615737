import { Component, OnInit, Output, Input, EventEmitter, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NavigationStart, Router } from '@angular/router';
import { MdePopover } from '@material-extended/mde';
import { OrganizationModel, SUPPORTED_NOTIFICATIONS, UserModel, UserReference } from '@wedecide/models';
import { BaseComponent } from '@wedecide/shared/base.component';
import { WdColors } from '@wedecide/shared/services/color.service';
import { ErrorService } from '@wedecide/shared/services/error.service';
import { ImpersonationService } from '@wedecide/shared/services/impersonation.service';
import { OrganizationService } from '@wedecide/shared/services/organization.service';
import { UserService } from '@wedecide/shared/services/user.service';
import firebase from 'firebase/compat';

import { takeUntil, map } from 'rxjs/operators';

import { AuthService } from './../../services/auth.service';
import { LayoutService } from './../../services/layout.service';
import { NotificationService } from './../../services/notification.service';
import { ThemeService } from './../../services/theme.service';

@Component({
  selector: 'wd-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() toggleSidenav = new EventEmitter();
  @Output() logoutEvent = new EventEmitter();
  @Input() user: firebase.User;
  @Input() userProfileImage: string;
  @Input() hasManageAccess: boolean;
  @Input() isAdmin: boolean;

  orange = WdColors.ORANGE;
  public popoverOpened = false;
  private _impersonatableUsers: UserModel[];
  impersonatedBy: UserReference;

  @ViewChild('notificationsPopover') notificationsPopover: MdePopover;

  get organization() {
    return this.organizationService.organization;
  }

  get logo() {
    return this.themeService.svgLogo;
  }

  get unreadNotifications() {
    return this.notificationService.unreadNotifications;
  }

  get isNotBasicUser() {
    return !this.authService.isBasicUser();
  }

  get lt_md() {
    return this.layoutService.lt_md;
  }

  get lt_sm() {
    return this.layoutService.lt_sm;
  }

  get notificationPopoverOffset() {
    if (this.lt_sm) {
      return -5;
    } else if (this.lt_md) {
      return -116;
    }
    return -228;
  }

  get impersonatableUsers() {
    return this._impersonatableUsers;
  }

  get impersonatedBy$() {
    return this.impersonationService.impersonatedBy$;
  }

  constructor(
    private themeService: ThemeService,
    private organizationService: OrganizationService,
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    private layoutService: LayoutService,
    private userService: UserService,
    private errorService: ErrorService,
    private impersonationService: ImpersonationService,
  ) {
    super();
  }

  impersonate(id: string) {
    const msg = $localize`:navigation|Error Message displayed when switching the user failed@@navigation_user-switch-failed:Switching the user failed.`;
    this.impersonationService.impersonate(id).catch((e) => {
      this.errorService.showServerErrorSnackbar(e, msg);
    });
  }

  toggleSideNav() {
    this.toggleSidenav.emit();
  }

  logout() {
    this.logoutEvent.emit();
  }

  ngOnInit() {
    this.authService.isAuthenticated$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.userService
        .getImpersonatableUsers(this.authService.user.uid, this.authService.currentOrganizationId)
        .subscribe((users) => {
          this._impersonatableUsers = users;
        });
    });

    this.impersonatedBy$.subscribe(async (info) => {
      if (info) {
        this.userService.getUserReference(info.id, this.authService.currentOrganizationId).then((userRef) => {
          this.impersonatedBy = userRef;
        });
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && this.notificationsPopover) {
        // TODO: find a better way to close this popopver ...
        this.notificationsPopover.closeOnClick = true;
        this.notificationsPopover.onClick();
      }
    });
  }

  navigateToSearch() {
    this.router.navigate(['/search']);
  }
}
