import { merge } from 'lodash';

import { AttachmentModel } from '../interfaces/attachment.model';
import {
  OrganizationModel,
  FirestoreOrganizationModel,
  OrganizationAttachmentModel,
  OrganizationSettings,
  OrganizationAccount,
} from '../interfaces/organization.model';
import { UserReference } from '../interfaces/user.model';

import { Base } from './base.model';

export class Organization extends Base<OrganizationModel, FirestoreOrganizationModel> implements OrganizationModel {
  /**
   * name of the organization
   */
  name: string;
  /**
   * domain of the organization
   */
  domain?: string;

  /**
   * logo of the organization
   */
  logo?: OrganizationAttachmentModel;

  /**
   * user that created and owns the organization
   */
  owner: UserReference;

  /**
   * various settings for the organization
   */
  settings?: OrganizationSettings;

  /**
   * all information of the account for that organization
   * mostly related to billing
   */
  account: OrganizationAccount;

  constructor(data: Partial<OrganizationModel>) {
    super();
    merge(this, data);
  }

  toFirestore() {
    return (this as any) as FirestoreOrganizationModel;
  }

  validate() {
    return true;
  }

  addAttachment(attachment: AttachmentModel) {
    this.logo = attachment;
  }

  removeAttachment(attachment: AttachmentModel) {
    // TODO: one big issue with this is that the file must also be removed from storage,
    //       so the whole thing should probably be in a service
    delete this.logo;
  }

  updateAttachmentLabel(attachment: AttachmentModel) {
    if (this.logo) {
      this.removeAttachment(this.logo);
    }
    this.addAttachment(attachment);
  }

  static fromFirestoreData(data: Partial<FirestoreOrganizationModel>) {
    return new Organization({
      ...data,
      createdAt: data.createdAt ? data.createdAt.toDate() : new Date(),
      updatedAt: data.updatedAt ? data.updatedAt.toDate() : new Date(),
      logo: data.logo
        ? {
            ...data.logo,
            createdAt: data.logo.createdAt?.toDate(),
          }
        : undefined,
      account: data.account
        ? {
            ...data.account,
            validUntil: data.account.validUntil?.toDate(),
          }
        : undefined,
    });
  }
}
