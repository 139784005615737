import { merge } from 'lodash';

import { OrganizationReference } from '../interfaces/organization.model';
import { FirestoreUnitModel, UnitModel } from '../interfaces/unit.model';

import { Base } from './base.model';

export class Unit extends Base<UnitModel, FirestoreUnitModel> implements UnitModel {
  name: string;
  organization: OrganizationReference;
  abbreviation?: string;

  constructor(data: Partial<Unit>) {
    super();
    merge(this, data);
  }

  validate() {
    return true;
  }

  toFirestore() {
    return {
      ...(this as any),
    } as FirestoreUnitModel;
  }

  static fromFirestoreData(data: Partial<FirestoreUnitModel>) {
    return new Unit({
      ...data,
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
    });
  }
}
