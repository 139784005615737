import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Organization } from '@wedecide/models';

@Component({
  selector: 'wd-trial-ended',
  templateUrl: './trial-ended.component.html',
  styleUrls: ['./trial-ended.component.scss'],
})
export class TrialEndedComponent {
  organization: Organization;

  constructor(@Inject(MAT_DIALOG_DATA) data: { organization: Organization }) {
    this.organization = data.organization;
  }
}
