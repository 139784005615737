import { Component } from '@angular/core';

@Component({
  selector: 'wd-permission-denied',
  templateUrl: './permission-denied.component.html',
  styleUrls: ['./../error-handling-base.component.scss', './permission-denied.component.scss'],
})
export class PermissionDeniedComponent {
  constructor() {}
}
