import { merge, omit, pick } from 'lodash';

import { Signature } from '../interfaces/signature.model';
import {
  FirestoreUserModel,
  OrganizationList,
  UserModel,
  UserReference,
  UserSettings,
  UserRegistrationState,
  ImpersonatorsDict,
} from '../interfaces/user.model';

import { Base } from './base.model';

export class User extends Base<UserModel, FirestoreUserModel> implements UserModel {
  email: string;

  name: string;

  firstname: string;

  lastname: string;

  registrationState: UserRegistrationState;

  lastActive?: Date;

  /**
   * TODO: move this into an organization specific data model => e.g. the `OrganizationRoleAssignment` model
   */
  abbreviation: string;

  /**
   * TODO: move this into an organization specific data model => e.g. the `OrganizationRoleAssignment` model
   */
  jobDescription: string;

  organizations: OrganizationList;

  settings: UserSettings;

  claimsLastUpdatedAt?: Date;

  onlyMicrosoftLogin?: boolean;

  avatar?: string;

  signature?: Signature;

  allowImpersonationBy: ImpersonatorsDict;

  language?: 'en' | 'de';

  constructor(data: Partial<UserModel>) {
    super();
    // Object.assign(this, data);
    merge(this, data);
  }

  validate() {
    return true;
  }

  getReference(): UserReference {
    return pick(this, ['id', 'name', 'avatar']);
  }

  static fromFirestoreData(data: Partial<FirestoreUserModel>) {
    return new User({
      ...data,
      createdAt: data.createdAt?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
      lastActive: data?.lastActive?.toDate() || null,
      // FIXME: claimsLastUpdatedAt sometimes contains no date in firestore but object with the properties
      claimsLastUpdatedAt: data.claimsLastUpdatedAt?.toDate?.(),
    });
  }

  toFirestore() {
    return omit(
      {
        ...(this as any),
      },
      'displayName',
      'avatar',
    ) as FirestoreUserModel;
  }
}
