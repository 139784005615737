import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum ConfirmButtonColor {
  ACCENT = 'accent',
  PRIMARY = 'primary',
  WARN = 'warn',
}

export interface ConfirmDialogData {
  title: string;
  message: string;
  labelConfirm?: string;
  labelCancel?: string;
  buttonColor?: ConfirmButtonColor;
}

@Component({
  selector: 'wd-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  title: string;
  message: string;
  labelConfirm = $localize`:general|yes@@general_yes:Yes`;
  labelCancel = $localize`:general|no@@general_no:No`;
  buttonColor = 'warn';

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
  ) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.labelConfirm = data?.labelConfirm || this.labelConfirm;
    this.labelCancel = data?.labelCancel || this.labelCancel;
    this.buttonColor = data?.buttonColor || this.buttonColor;
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}
