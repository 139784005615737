import { DateTime, Duration } from 'luxon';

import { FirestoreTimestamp } from './interfaces/base.model';

export const TZ_DEFAULT = 'Europe/Berlin';
export const LOCALE_DEFAULT = 'en-GB';

/** Pad numbers. Taken from https://gist.github.com/endel/321925f6cafa25bbfbde#gistcomment-3143940
@param n <Number> what to pad left with
@param z <Number> (zeros :) ) width which pad to
@param s <String> (sign :) ) char to pad with, of course may be some like that 'oO'
@return <String>
@example
	pz(-1005007, 20)
	// ==> "-00000000000001005007"
*/
const pz = (n: number, z = 2, s = '0') =>
  (n + '').length <= z ? ['', '-'][+(n < 0)] + (s.repeat(z) + Math.abs(n)).slice(-1 * z) : n + '';

export const humanizeDuration = (
  duration: Duration | number,
  timer = false,
  hrsUnit = 'h',
  minsUnit = 'm',
  secsUnit = 's',
): string => {
  if (!(duration instanceof Duration)) {
    duration = Duration.fromMillis(duration);
  }

  const hrs = Math.trunc(duration.as('hours'));
  const mins = Math.trunc(duration.as('minutes') % 60);
  const sec = Math.trunc(duration.as('seconds') % 60);

  if (timer) {
    if (Math.abs(hrs) >= 1) {
      return `${Math.abs(hrs) >= 1 ? hrs + hrsUnit : ''} ${Math.abs(mins) >= 1 ? mins + minsUnit : ''}`;
    } else if (sec == 0 && mins == 0 && hrs == 0) {
      return sec + secsUnit;
    } else {
      return `${Math.abs(mins) >= 1 ? mins + minsUnit : ''} ${Math.abs(sec) >= 1 ? sec + secsUnit : ''}`;
    }
  }

  if (Math.abs(duration.as('seconds')) < 60) {
    return sec + secsUnit;
  }

  return `${Math.abs(hrs) >= 1 ? hrs + hrsUnit + ' ' : ''}${Math.abs(mins) >= 1 ? mins + minsUnit : ''}`;
};

export const humanizeDurationLong = (duration: Duration | number, timer = false): string => {
  return humanizeDuration(duration, timer, 'h', 'min', 'sec');
};

export const formatDuration = (duration: Duration | number): string => {
  if (!(duration instanceof Duration)) {
    duration = Duration.fromMillis(duration);
  }
  const h = Math.trunc(duration.as('hours'));
  const m = Math.abs(Math.trunc(duration.as('minutes')) % 60);
  return pz(h) + ':' + pz(m);
};

export const formatTime = (dt: DateTime | Date, zone = TZ_DEFAULT): string => {
  if (dt instanceof Date) {
    dt = dateTimefromDate(dt, zone);
  }
  return dt?.setZone(zone).toFormat('HH:mm');
};

export const formatDateWithoutYear = (dt: DateTime | Date): string => {
  if (dt instanceof Date) {
    dt = dateTimefromDate(dt);
  }
  return dt?.toFormat('d MMM');
};

export const formatDateShort = (dt: DateTime | Date): string => {
  if (dt instanceof Date) {
    dt = dateTimefromDate(dt);
  }
  return dt?.toFormat('dd.MM.yyyy');
};

export const formatDateLong = (dt: DateTime | Date): string => {
  if (dt instanceof Date) {
    dt = dateTimefromDate(dt);
  }
  return dt?.toFormat('d MMM yyyy');
};

export const formatDateTime = (dt: DateTime | Date, zone = TZ_DEFAULT) => {
  if (dt instanceof Date) {
    dt = dateTimefromDate(dt);
  }
  return formatDateShort(dt) + ', ' + formatTime(dt, zone);
};

export const formatDateTimeLong = (dt: DateTime | Date, zone = TZ_DEFAULT) => {
  if (dt instanceof Date) {
    dt = dateTimefromDate(dt);
  }
  return formatDateLong(dt) + ', ' + formatTime(dt, zone);
};

export const formatDateTimeAgo = (dt: DateTime | Date): string => {
  if (dt instanceof Date) {
    dt = dateTimefromDate(dt);
  }
  const relativeString = dt.toRelative({ locale: LOCALE_DEFAULT });
  return relativeString.indexOf('second') !== -1 ? 'a few seconds ago' : relativeString;
};

export const dateTimefromTimestamp = (fts: FirestoreTimestamp) => {
  return DateTime.fromJSDate(fts.toDate(), { zone: TZ_DEFAULT });
};

export const dateTimefromDate = (date: Date, zone = TZ_DEFAULT) => {
  return DateTime.fromJSDate(date, { zone: zone });
};
