import { ENTER, COMMA, M } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { PrivacyPolicyDeComponent } from './privacy-policy/privacy-policy-de/privacy-policy-de.component';
import { PrivacyPolicyEnComponent } from './privacy-policy/privacy-policy-en/privacy-policy-en.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PrivacySettingsDialog } from './privacy-settings/privacy-settings-dialog/privacy-settings-dialog.component';
import { PrivacySettings } from './privacy-settings/privacy-settings.component';

@NgModule({
  declarations: [
    PrivacySettings,
    PrivacySettingsDialog,
    PrivacyPolicyComponent,
    PrivacyPolicyEnComponent,
    PrivacyPolicyDeComponent,
  ],
  imports: [
    // Material
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatSlideToggleModule,
    MatCardModule,
    MatButtonModule,
  ],
  exports: [PrivacyPolicyComponent],
  providers: [],
})
export class PrivacyModule {}
