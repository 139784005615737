import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@wedecide/shared/services/auth.service';
import { ErrorService } from '@wedecide/shared/services/error.service';
import { ThemeService } from '@wedecide/shared/services/theme.service';

@Component({
  selector: 'wd-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['../login.component.scss', './forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  isLoading = false;
  form: FormGroup;
  private _mailRequested = false;

  forgotPasswordValidation = {
    email: [
      {
        type: 'required',
        message: $localize`:forgot-password|validation; email required@@forgot-password_validation_email-required:Email is required`,
      },
      {
        type: 'email',
        message: $localize`:forgot-password|validation; invalid email@@forgot-password_validation_invalid-email:Please enter a valid email`,
      },
    ],
  };

  get logo() {
    return this.themeService.svgLogo;
  }

  get mailRequested() {
    return this._mailRequested;
  }

  constructor(
    private themeService: ThemeService,
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    });
  }

  onSubmit() {
    this.isLoading = true;
    this.authService
      .sendPasswordResetLink({ email: this.form.get('email').value })
      .then(() => {
        this._mailRequested = true;
      })
      .catch((err) => {
        this.errorService.showServerErrorSnackbar(
          err,
          $localize`:forgot-password|Error snackbar; failed to send password reset mail@@forgot-password_error-snackbar_failed-to-send-pw-reset:Could not send password reset mail`,
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
